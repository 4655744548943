import { Typography, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWhiteTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.white,
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.white,
  marginBottom: 4,
}));
