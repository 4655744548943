import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled as MuiStyled } from '@mui/material/styles';
import { Input } from '@mui/material';
import getTheme from '../../../../theme';

const theme = getTheme();

export const StyledNameInput = MuiStyled(Input)(() => ({
  color: theme.palette.white,
  '&::before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.42)',
  },
  '&:hover:not(.Mui-disabled, .Mui-error)::before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.87)',
  },
}));

export const Wrapper = styled.div`
  .card {
    border-radius: 16px;
    border: 0;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.5),
      0px -3px 3px 0px rgba(0, 0, 0, 0.5);

    &-header {
      background: ${theme.palette.primary.main};
      color: ${theme.palette.white};
      padding: 6px;
      column-gap: 8px;

      &:first-child {
        border-radius: 16px 16px 0 0;
      }

      h5 {
        word-break: break-all;
        color: ${theme.palette.white};
      }
    }

    &-body {
      background: ${theme.palette.lightGrey6};
      border-radius: 0 0 16px 16px;
      padding: 8px 16px 30px 16px;

      &.secondView {
        padding: 16px;
        border-radius: 16px;
        border: 5px solid ${theme.palette.primary.main};
      }
    }
  }

  table {
    color: ${theme.palette.primary.main};

    td,
    th {
      font-size: 14px;

      @media (min-width: 1920px) {
        font-size: 16px;
      }
    }
  }

  svg:not(:root) {
    overflow: visible;
  }

  .slick {
    &-prev {
      left: 0;
    }
    &-next {
      right: 0;
    }

    &-prev,
    &-next {
      &:before {
        color: ${theme.palette.primary.main};
      }
    }
  }

  .wrappPie {
    height: 300px;
  }

  .wrappArea {
    position: relative;
  }

  .buttons__wrapp {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 15px;
  }

  @media (min-width: 992px) {
  }
`;

export const RightActions = styled.div`
  position: absolute;
  top: 36px;
  right: 20px;
  z-index: 1;

  .rightAction {
    cursor: pointer;

    // img {
    //   filter: invert(7%) sepia(67%) saturate(4725%) hue-rotate(249deg)
    //     brightness(97%) contrast(93%);
    // }

    &:hover {
      img {
        filter: invert(57%) sepia(93%) saturate(1269%) hue-rotate(117deg)
          brightness(108%) contrast(102%);
      }
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(to left, #df4857, #2bb160);
`;

export const CoinElementWrapper = styled.div`
  img {
    max-width: 40px;
    max-height: 40px;
    min-height: 40px;
  }
`;
