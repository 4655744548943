import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem,
  Avatar,
  Button,
  ListItemIcon,
  Divider,
  Box,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Person as PersonIcon, Logout } from '@mui/icons-material';
import Wallet from '../Wallet';
import CMenu from '../../../components/Base/CMenu';
import CButton from '../../../components/Base/CButton';
import UserImg from '../../../assets/img/Common/user.svg';
import { removeStorageToken, getAuthToken } from '../../../lib/auth';
import { getUserInfo } from '../../../lib/userApi';
import { logout, setUser, setUserToken } from '../../../reducers/userReducer';
import {
  setNotificationShow,
  setNotification,
} from '../../../reducers/globalReducer';

const AuthElement = () => {
  const dispatch = useDispatch();
  const token = getAuthToken();
  const user = useSelector((state) => state.user.userInfo);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState(null);
  const open = Boolean(anchorEl);

  const fetchUserData = useCallback(async () => {
    const userInfo = await getUserInfo(token);
    if (userInfo.status === 200 && !userInfo.error) {
      dispatch(setUser(userInfo.data));
      dispatch(setUserToken(token));
      setName(userInfo.data.displayName);
    } else {
      dispatch(setNotificationShow(true));
      dispatch(setNotification(userInfo.error));
    }
    return userInfo;
  }, []);

  useEffect(() => {
    switch (token) {
      case 'EXPIRED':
        setLoggedIn(false);
        removeStorageToken();
        break;
      case null:
        setLoggedIn(false);
        break;
      default:
        setLoggedIn(true);
        if (user) {
          setName(user.displayName);
        } else {
          fetchUserData();
        }
    }
  }, [token]);

  useEffect(() => {
    if (name) {
      setAvatar(name.split(' ')[0].substring(0, 1));
    }
  }, [name]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    removeStorageToken();
    setLoggedIn(false);
    dispatch(logout());
    navigate('/login');
  };

  return loggedIn ? (
    <Stack direction="row" gap={isMobile ? 1 : 4}>
      <Wallet />
      <Box>
        <Button
          id="user-button"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Avatar sx={{ textTransform: 'uppercase' }}>
            {avatar ? avatar : <PersonIcon />}
          </Avatar>
        </Button>
        <CMenu anchorEl={anchorEl} open={open} handleClose={handleClose}>
          <Box sx={{ padding: '20px 30px', textTransform: 'capitalize' }}>
            Hi
            {name ? ` ${name}` : ''}!
          </Box>
          <Divider />
          <MenuItem
            onClick={() => {
              navigate('/my-account');
              handleClose();
            }}
          >
            <Avatar /> My account
          </MenuItem>
          <MenuItem onClick={handleLogOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </CMenu>
      </Box>
    </Stack>
  ) : (
    <CButton
      img={UserImg}
      iconBefore="true"
      border="true"
      size="md"
      onClick={() => navigate('/login')}
    >
      Login
    </CButton>
  );
};

export default AuthElement;
