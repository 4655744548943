import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableHead, TableRow, Paper } from '@mui/material';
import { RestartAlt as RestartAltIcon } from '@mui/icons-material';
import PortfolioTableItem from './components/PortfolioTableItem';
import {
  StyledTableCell,
  StyledTableContainer,
  StyledResetButton,
} from './styles';

const PortfolioTable = ({
  assetsData,
  totalPercentage,
  totalValue,
  capitalAmount,
  cashAmount,
  redistributeAssets,
  isExisting,
  isLive,
  isControl,
  isDisable,
  onReset,
}) => {
  const assetsDataBackup = useSelector((state) => state.portfolio.listBackup);

  return (
    <StyledTableContainer component={Paper}>
      <Table stickyHeader aria-label="assets table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Assets</StyledTableCell>
            <StyledTableCell>Weight</StyledTableCell>
            <StyledTableCell>Quantity</StyledTableCell>
            <StyledTableCell>Estimated Value</StyledTableCell>
            <StyledTableCell>
              <StyledResetButton onClick={onReset}>
                <RestartAltIcon />
              </StyledResetButton>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assetsData.map((data, idx) => (
            <PortfolioTableItem
              key={data.uuid}
              data={data}
              dataBackup={assetsDataBackup[idx]}
              totalPercentage={totalPercentage}
              totalValue={totalValue}
              capitalAmount={capitalAmount}
              cashAmount={cashAmount}
              redistributeAssets={redistributeAssets}
              isExisting={isExisting}
              isLive={isLive}
              isControl={isControl}
              isDisable={isDisable}
            />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default PortfolioTable;
