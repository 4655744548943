import React from 'react';
import { createRoot } from 'react-dom/client';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import ReactGA from 'react-ga4';
import { clarity } from 'react-microsoft-clarity';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('G-LTVRJPRK1P');
  clarity.init('j5cbdo7p88');
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ModalProvider rootComponent={TransitionGroup}>
    <App />
  </ModalProvider>
);
unregister();
