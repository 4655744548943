import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
} from '@mui/material';
import TablePagination from '../../../../components/Base/Table/TablePagination';
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTablePagination,
} from '../../../../components/Base/Table/styles';
import PortfolioAssetsTableItem from './TableItem';

const PortfolioAssetsTable = ({ isLoading, tableData }) => {
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const rowsPerPage = 10;

  return (
    <StyledTableContainer component={Paper}>
      <Table aria-label="assets table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Asset</StyledTableCell>
            {/* <StyledTableCell align="right">Tags</StyledTableCell> */}
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Price(24H)</StyledTableCell>
            <StyledTableCell align="right">Weight</StyledTableCell>
            <StyledTableCell align="right">Quantity</StyledTableCell>
            <StyledTableCell align="right">Value</StyledTableCell>
            <StyledTableCell align="right">Return</StyledTableCell>
          </TableRow>
        </TableHead>
        {tableData.length > 0 ? (
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((data) => (
              <PortfolioAssetsTableItem
                key={data.ticker}
                data={data}
                isLoading={isLoading}
              />
            ))}
          </TableBody>
        ) : (
          <caption style={{ textAlign: 'center', fontWeight: 500 }}>
            No data found
          </caption>
        )}
        <TableFooter>
          <TableRow>
            {tableData.length > rowsPerPage && (
              <StyledTablePagination
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                colSpan={12}
                count={tableData.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                ActionsComponent={() => (
                  <TablePagination
                    count={tableData.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    expandable={false}
                  />
                )}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </StyledTableContainer>
  );
};

export default PortfolioAssetsTable;
