import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setNotificationShow } from '../../../reducers/globalReducer';

const Notification = () => {
  const dispatch = useDispatch();
  const errorType = useSelector((state) => state.global.errorType);
  const errorShow = useSelector((state) => state.global.errorShow);
  const errorMessage = useSelector((state) => state.global.errorMessage);

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setNotificationShow(false));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={errorShow}
      autoHideDuration={5000}
      onClose={handleCloseError}
    >
      <Alert onClose={handleCloseError} severity={errorType}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
