import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './styles';

const CAccordion = ({ id, title, expand, handleAccordion, children }) => {
  return (
    <StyledAccordion expanded={expand} onChange={handleAccordion}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={id}
      >
        {title}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default CAccordion;
