/* eslint-disable camelcase */
import React from 'react';
import { Tooltip } from '@mui/material';
import { MonetizationOn, Euro } from '@mui/icons-material';
import { CoinElementWrapper } from './styles';
import Paragraph from '../../../../components/Common/Paragraph';
import { StyledLogo } from '../../../../components/Base/Table/styles';
import { formatNumber } from '../../../../utils/parseNumber';

const CoinElement = ({ data, key }) => {
  const {
    id,
    displayName,
    ticker,
    new_percentage,
    percentage,
    logo,
    quantity,
  } = data;

  return (
    <CoinElementWrapper key={key}>
      <Tooltip title={displayName}>
        <div className="text-center">
          <div className="d-flex justify-content-center mb-1">
            {id === 'USD' ? (
              <MonetizationOn sx={{ width: 40, height: 40 }} />
            ) : id === 'EUR' ? (
              <Euro sx={{ width: 40, height: 40 }} />
            ) : (
              <StyledLogo src={logo} alt="coin" />
            )}
          </div>
          <Paragraph fontsize="12px">
            <b>{ticker}</b>
            <br />
            {(new_percentage || percentage).toFixed(1)}%
            <br />
            {formatNumber(quantity)}
          </Paragraph>
        </div>
      </Tooltip>
    </CoinElementWrapper>
  );
};

export default CoinElement;
