import { getWallets } from '../lib/api';

const initialState = {
  isFetching: true,
  fetched: false,
  list: [],
  wallet: 'cede',
  provider: null,
  connected: undefined,
  modalOpen: false,
  activeAccount: '',
  activeChain: '',
};

export const WALLETS_LOAD = 'WALLETS_LOAD';
export const TOGGLE_FETCHING_WALLETS = 'TOGGLE_FETCHING_WALLETS';
export const TOGGLE_FETCHED_WALLETS = 'TOGGLE_FETCHED_WALLETS';
export const TOGGLE_WALLET = 'TOGGLE_WALLET';
export const SET_WALLET = 'SET_WALLET';
export const SET_WALLET_CONNECTED = 'SET_WALLET_CONNECTED';
export const SET_PROVIDER = 'SET_PROVIDER';
export const TOGGLE_WALLET_MODAL = 'TOGGLE_WALLET_MODAL';
export const SET_ACTIVE_ACCOUNT = 'SET_ACTIVE_ACCOUNT';
export const SET_ACTIVE_CHAIN = 'SET_ACTIVE_CHAIN';

export const loadWallets = (data) => ({ type: WALLETS_LOAD, payload: data });
export const toggleFetching = (value) => ({
  type: TOGGLE_FETCHING_WALLETS,
  value,
});
export const toggleFetched = (value) => ({
  type: TOGGLE_FETCHED_WALLETS,
  value,
});
export const toggleWallet = (id) => ({ type: TOGGLE_WALLET, id });
export const setWalletType = (wallet) => ({
  type: SET_WALLET,
  wallet,
});
export const setWalletConnected = (status) => ({
  type: SET_WALLET_CONNECTED,
  status,
});
export const setProvider = (provider) => ({ type: SET_PROVIDER, provider });
export const toggleWalletModal = (opened) => ({
  type: TOGGLE_WALLET_MODAL,
  opened,
});
export const fetchWallets = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleFetching(true));
      const wallets = await getWallets();
      dispatch(loadWallets(wallets));
      dispatch(toggleFetching(false));
      dispatch(toggleFetched(true));
      return wallets;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};
export const setActiveAccount = (account) => ({
  type: SET_ACTIVE_ACCOUNT,
  account,
});
export const setActiveChain = (chain) => ({
  type: SET_ACTIVE_CHAIN,
  chain,
});

const walletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case WALLETS_LOAD:
      return { ...state, list: action.payload };
    case TOGGLE_FETCHING_WALLETS:
      return { ...state, isFetching: action.value };
    case TOGGLE_FETCHED_WALLETS:
      return { ...state, fetched: action.value };
    case TOGGLE_WALLET:
      return {
        ...state,
        list: state.list.map((value) =>
          (value.id === action.id
            ? { ...value, selected: !value.selected }
            : value)
        ),
      };
    case SET_WALLET_CONNECTED:
      return {
        ...state,
        connected: action.status,
      };
    case SET_WALLET:
      return {
        ...state,
        wallet: action.wallet,
      };
    case SET_PROVIDER:
      return {
        ...state,
        provider: action.provider,
      };
    case TOGGLE_WALLET_MODAL:
      return {
        ...state,
        modalOpen: action.opened,
      };
    case SET_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: action.account,
      };
    case SET_ACTIVE_CHAIN:
      return {
        ...state,
        activeChain: action.chain,
      };
    default:
      return state;
  }
};

export default walletsReducer;
