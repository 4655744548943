export const handleKeyDownNumber = (event) => {
  if (event.key === ',') event.key = '.';
  const hasDot = event.target.value.includes('.');
  if (
    event.key === 'Enter' ||
    event.key === '-' ||
    event.key === '+' ||
    (hasDot && event.key === '.')
  ) {
    event.preventDefault();
  }
};
