import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link as LinkDom } from 'react-router-dom';
import {
  List,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Box,
  Link,
  Drawer,
  useMediaQuery,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Info,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import CurrencySelect from '../Header/CurrencySelect';
import {
  StyledDrawer,
  DrawerHeader,
  StyledListItem,
  StyledListItemText,
} from './styles';
import {
  setAnchorSidebar,
  setOpenSidebar,
  setExpandTable,
} from '../../reducers/globalReducer';
import OverviewIcon from '../../assets/img/Common/overview.svg';
import AssetsIcon from '../../assets/img/Common/assets.svg';
import WalletIcon from '../../assets/img/Common/wallet-icon.svg';

const navList = [
  {
    id: 'overview',
    icon: OverviewIcon,
    name: 'Overview',
    children: [
      {
        id: 'aggregate',
        name: 'Aggregate Portfolio',
      },
      {
        id: 'connected',
        name: 'Connected Portfolio',
      },
      {
        id: 'tracked',
        name: 'Tracked Portfolio',
      },
      {
        id: 'planned',
        name: 'Planned Portfolio',
      },
      {
        id: 'social',
        name: 'Social Portfolio',
      },
    ],
  },
  {
    id: 'assets',
    icon: AssetsIcon,
    name: 'Assets',
    children: [
      {
        id: 'crypto',
        name: 'Crypto',
      },
      {
        id: 'equity',
        name: 'Equity',
      },
      {
        id: 'etf',
        name: 'ETF / Index',
      },
      {
        id: 'forex',
        name: 'Forex (FX)',
      },
      // {
      //   id: 'nft',
      //   name: 'NFT'
      // },
    ],
  },
  {
    id: 'portfolio-import',
    icon: WalletIcon,
    name: 'Accounts',
  },
  // {
  //   id: 'tracking',
  //   name: 'Tracking',
  // },
];

const userGuide = [
  {
    id: 'step1',
    text: (
      <>
        Go to{' '}
        <LinkDom
          style={{ textDecoration: 'underline', color: '#251A60' }}
          to="/assets"
        >
          Assets
        </LinkDom>{' '}
        page, select assets to be added to new or existing portfolio
      </>
    ),
  },
  {
    id: 'step2',
    text: (
      <>
        In the{' '}
        <LinkDom
          style={{ textDecoration: 'underline', color: '#251A60' }}
          to="/assets?modalopen=true"
        >
          Pop-up
        </LinkDom>
        , either build a new portfolio by % with a starting capital or track an
        existing portfolio you possess by quantity and current price
      </>
    ),
  },
  {
    id: 'step3',
    text: (
      <>
        Once created, go to the{' '}
        <LinkDom
          style={{ textDecoration: 'underline', color: '#251A60' }}
          to="/overview"
        >
          Overview
        </LinkDom>{' '}
        page to visually start tracking your portfolio either individually or
        aggregated with other Portfolios
      </>
    ),
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const openSidebar = useSelector((state) => state.global.openSidebar);
  const anchorSidebar = useSelector((state) => state.global.anchorSidebar);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const theme = useTheme();

  const [pathName, setPathName] = useState('');

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    setPathName(pathParts[1]);
  }, [location.pathname]);

  useEffect(() => {
    if (isTablet) dispatch(setOpenSidebar(false));
    else dispatch(setOpenSidebar(true));
  }, [isTablet]);

  const handleDrawer = () => {
    dispatch(setOpenSidebar(!openSidebar));
  };

  const handleNavigation = (target) => {
    setPathName(target);
    dispatch(setExpandTable(5));
    navigate(`/${target}`);
  };

  const handleNavigationChild = () => {
    dispatch(setExpandTable(5));
  };

  const handleDrawerToggle = () => {
    dispatch(setAnchorSidebar(false));
  };

  const drawer = (toggle) => (
    <>
      <DrawerHeader>
        {isMobile ? (
          <CurrencySelect />
        ) : (
          <IconButton onClick={handleDrawer}>
            {toggle ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
      </DrawerHeader>
      <Divider />
      <List>
        {navList.map((nav) => (
          <Box key={nav.id}>
            <Link
              underline="none"
              onClick={() => {
                handleNavigation(nav.id);
              }}
            >
              <StyledListItem
                disablePadding
                className={pathName === nav.id ? 'active' : ''}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: toggle ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: toggle ? 2.5 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {nav.icon && (
                      <img
                        src={nav.icon}
                        alt="nav icon"
                        width={26}
                        height={26}
                      />
                    )}
                  </ListItemIcon>
                  <StyledListItemText open={toggle} primary={nav.name} />
                </ListItemButton>
              </StyledListItem>
            </Link>
            {toggle &&
              pathName === nav.id &&
              nav.children &&
              nav.children.map((child) => (
                <Link
                  href={`/${nav.id}#${child.id}`}
                  onClick={handleNavigationChild}
                  key={child.id}
                  underline="none"
                >
                  <StyledListItem disablePadding>
                    <ListItemButton
                      sx={{
                        justifyContent: 'center',
                        pl: 8.25,
                        py: 0.5,
                      }}
                    >
                      <StyledListItemText
                        open={toggle}
                        primary={child.name}
                        size="small"
                      />
                    </ListItemButton>
                  </StyledListItem>
                </Link>
              ))}
          </Box>
        ))}
      </List>
      {toggle ? (
        <List dense sx={{ mt: 5 }}>
          <ListItem>
            <ListItemText
              sx={{
                whiteSpace: 'normal',
                span: { fontSize: 14, fontWeight: 700 },
              }}
            >
              Get Started:
            </ListItemText>
          </ListItem>
          {userGuide.map((el, key) => (
            <ListItem key={el.id}>
              <ListItemText
                sx={{ whiteSpace: 'normal', span: { fontSize: 12 } }}
              >
                <span
                  style={{
                    borderRadius: '50%',
                    background: theme.palette.primary.main,
                    color: theme.palette.white,
                    width: 18,
                    height: 18,
                    display: 'inline-Block',
                    textAlign: 'center',
                  }}
                >
                  {key + 1}
                </span>{' '}
                {el.text}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <Tooltip
          title={
            <>
              {userGuide.map((el, key) => (
                <ListItem key={el.id}>
                  <ListItemText
                    sx={{ whiteSpace: 'normal', span: { fontSize: 12 } }}
                  >
                    <span
                      style={{
                        borderRadius: '50%',
                        background: theme.palette.primary.main,
                        color: theme.palette.white,
                        width: 18,
                        height: 18,
                        display: 'inline-Block',
                        textAlign: 'center',
                      }}
                    >
                      {key + 1}
                    </span>{' '}
                    {el.text}
                  </ListItemText>
                </ListItem>
              ))}
            </>
          }
        >
          <Info color="primary" fontSize="medium" sx={{ m: 'auto' }} />
        </Tooltip>
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={anchorSidebar}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 260 },
          }}
        >
          {drawer(anchorSidebar)}
        </Drawer>
      ) : (
        <StyledDrawer variant="permanent" open={openSidebar}>
          {drawer(openSidebar)}
        </StyledDrawer>
      )}
    </>
  );
};

export default Sidebar;
