import React, { useMemo } from 'react';
import { styled, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import CookieConsent from 'react-cookie-consent';
import Routes from './routes';
import createStore from './state/createStore';
import getTheme from './theme';
import Notification from './components/Base/Notification';

const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  minHeight: '100vh',
}));

const store = createStore();

function App() {
  const theme = useMemo(() => {
    return getTheme();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Routes />
          <Notification />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="divercefiCookiePolicy"
            style={{
              color: '#251a5f',
              background: '#fff',
              borderTop: '1px solid #fff',
              padding: '0 70px',
            }}
            buttonStyle={{
              color: '#fff',
              background: '#251a5f',
              fontSize: '14px',
              borderRadius: '25px',
              padding: '10px 20px',
            }}
            expires={999}
          >
            This website uses cookies to enhance the user experience.{' '}
            <a
              href="https://divercefi.com/security"
              target="_blank"
              rel="noreferrer"
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'underline',
              }}
            >
              Divercefi Privacy Policy
            </a>
          </CookieConsent>
        </Wrapper>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
