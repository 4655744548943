import {
  Typography,
  TextField,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.primary.main,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiInputBase-root': {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.primary.main,
    '& .MuiInputBase-input': {
      padding: '11.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const StyledMessage = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const CenterBox = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  width: '100%',
  padding: 16,
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
      [theme.breakpoints.down('sm')]: {
        borderRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        borderLeft: '1px solid #0000001F',
        borderTop: '1px solid transparent',
        borderRadius: 4,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  })
);

export const StyledToggleButton = styled(ToggleButton)(() => ({
  display: 'block',
  width: '100%',
  textTransform: 'none',
  textAlign: 'left',
}));
