/* eslint-disable camelcase */
import React from 'react';
import { Skeleton, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  StyledTableRow,
  // StyledChip,
  StyledTableCell,
  StyledTableText,
  StyledPriceChip,
} from '../../../../../components/Base/Table/styles';
import {
  formatNumber,
  getSuperscriptNumber,
  getExponentNumber,
} from '../../../../../utils/parseNumber';

const PortfolioAssetsTableItem = ({ data, isLoading }) => {
  const {
    displayName,
    // tags,
    price,
    price24H = 0,
    percentage,
    new_percentage,
    quantity,
    amount,
    new_amount,
    cumulation,
    ticker,
  } = data;
  const theme = useTheme();
  return (
    <StyledTableRow type="second">
      <StyledTableCell component="th" scope="row">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Stack direction="row" alignItems="center" gap={2}>
            <StyledTableText sx={{ textTransform: 'capitalize' }}>
              {`${displayName} `}{' '}
              <b className="ticker">{`(${ticker?.toUpperCase()})`}</b>
            </StyledTableText>
          </Stack>
        )}
      </StyledTableCell>
      {/* <StyledTableCell align="right">
        {tags.map((tag) => (
          <StyledChip key={tag.id} label={tag.tagName} />
        ))}
      </StyledTableCell> */}
      <StyledTableCell align="right">
        {isLoading ? (
          <Skeleton />
        ) : price && price !== 'N/A' ? (
          <Tooltip
            title={price.toFixed(getExponentNumber(price))}
            disableHoverListener={price >= 0.01}
          >
            <span>
              ${price < 0.01 ? (
                <>
                  {getSuperscriptNumber(price, 0)}
                  {' e'}
                  <sup>{getSuperscriptNumber(price, 1)}</sup>
                </>
              ) : (
                <>{formatNumber(price)}</>
              )}
            </span>
          </Tooltip>
        ) : (
          'N/A'
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        {isLoading ? (
          <Skeleton />
        ) : (
          <StyledPriceChip
            label={`${
              price24H !== 'N/A'
                ? `${price24H > 0 ? '+' : ''}${price24H.toFixed(2)}%`
                : 'N/A'
            }`}
            color={
              price24H === 'N/A'
                ? 'primary'
                : price24H >= 0
                ? 'success'
                : 'warning'
            }
            size="small"
            sx={{
              color:
                price24H >= 0
                  ? theme.palette.primary.main
                  : theme.palette.white,
            }}
          />
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        {isLoading ? (
          <Skeleton />
        ) : (
          `${(new_percentage || percentage).toFixed(2)}%`
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Tooltip
            title={quantity.toFixed(getExponentNumber(quantity))}
            disableHoverListener={quantity >= 0.01}
          >
            <span>
              {quantity < 0.01 ? (
                <>
                  {getSuperscriptNumber(quantity, 0)}
                  {' e'}
                  <sup>{getSuperscriptNumber(quantity, 1)}</sup>
                </>
              ) : (
                <>{formatNumber(quantity)}</>
              )}
            </span>
          </Tooltip>
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Tooltip
            title={(new_amount || amount).toFixed(
              getExponentNumber(new_amount || amount)
            )}
            disableHoverListener={(new_amount || amount) >= 0.01}
          >
            <span>
              ${(new_amount || amount) < 0.01 ? (
                <>
                  {getSuperscriptNumber(new_amount || amount, 0)}
                  {' e'}
                  <sup>{getSuperscriptNumber(new_amount || amount, 1)}</sup>
                </>
              ) : (
                <>{formatNumber(new_amount || amount)}</>
              )}
            </span>
          </Tooltip>
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        {isLoading ? (
          <Skeleton />
        ) : (
          <StyledPriceChip
            label={`${`${cumulation > 0 ? '+' : ''}${cumulation.toFixed(2)}%`}`}
            color={cumulation >= 0 ? 'success' : 'warning'}
            size="small"
            sx={{
              color:
                cumulation >= 0
                  ? theme.palette.primary.main
                  : theme.palette.white,
            }}
          />
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default PortfolioAssetsTableItem;
