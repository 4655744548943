import React from 'react';
import styled from 'styled-components';
import getTheme from '../../../theme';

const theme = getTheme();

const Wrapper = styled.div`
  display: flex;
  
  .badge {
    border-radius: 15px;
    background: ${theme.palette.white};
    color: ${theme.palette.primary.main};
    padding: 4px;
    font-size: 10px;
    font-weight: 400;
    text-transform: capitalize;
  }

  @media (min-width: 992px) {

  }
`;

const FiltersWrapper = ({ children, title, ...props }) => {
  return (
    <Wrapper {...props}>
      <span className="badge">{children}</span>
    </Wrapper>
  );
};

export default FiltersWrapper;
