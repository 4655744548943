import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { StyedTooltip, StyedTooltipLabel } from './styles';
import { parseDateXAxis } from '../../utils/parseDate';
import { formatNumberByCheckList } from '../../utils/parseNumber';

const gradientOffset = (data) => {
  const dataMax = Math.max(...data.map((i) => i.change));
  const dataMin = Math.min(...data.map((i) => i.change));
  if (dataMax <= 0) {
    return 0;
  }
  if (dataMin >= 0) {
    return 1;
  }
  return dataMax / (dataMax - dataMin);
};

const CustomTooltip = ({ active, payload, handleActiveData }) => {
  if (active && payload && payload.length) {
    const dataItem = payload[0].payload;
    handleActiveData(dataItem);

    return (
      <StyedTooltip>
        <StyedTooltipLabel>
          {`Value: $${dataItem.price.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
        </StyedTooltipLabel>
      </StyedTooltip>
    );
  }
  return null;
};

const AreaChartEl = ({
  id,
  data,
  duration,
  // createdAt,
  periodStartPrice,
  portfolioEntryPrice,
  height = 150,
  color,
  timezone,
  handleActiveData,
  handleMouseLeave,
}) => {
  const [graphData, setGraphData] = useState(data);
  const [maxDomainPrice, setMaxDomainPrice] = useState(0);
  const [minDomainPrice, setMinDomainPrice] = useState(0);
  const offset = gradientOffset(data);
  const tickCount = height > 150 ? 6 : 5;
  const [ticksList, setTicksList] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const prices = data.map((obj) => obj.price);
      const formattedData = data.map((entry) => {
        return {
          ...entry,
          formattedDate: parseDateXAxis(entry.time, duration, timezone),
        };
      });
      setGraphData(formattedData);
      const minPoints = Math.min(...prices);
      const maxPoints = Math.max(...prices);
      const minDomain = Math.max(0, minPoints - (maxPoints - minPoints) * 0.2);
      const maxDomain = maxPoints + (maxPoints - minPoints) * 0.2;

      let interval = 0;
      let startValue = minDomain;
      let unit = 5;
      const range = maxDomain - minDomain;
      const thresholds = [5, 50, 500, 5000, 500000, 5000000, 500000000];

      unit = thresholds.reduceRight((acc, threshold) => {
        if (range > (tickCount - 1) * threshold && acc === 1) {
          return threshold;
        }
        return acc;
      }, 1);
      interval =
        Math.ceil((maxDomain - minDomain) / (tickCount - 1) / unit) * unit;
      startValue = Math.floor(minDomain / unit) * unit;

      const ticks = Array.from(
        { length: tickCount },
        (_, index) => startValue + index * interval
      );
      setMinDomainPrice(startValue);
      setMaxDomainPrice(ticks[ticks.length - 1]);
      setTicksList(ticks);
    }
  }, [data, duration]);

  // const findClosestTime = (targetTime, timestamps) => {
  //   const targetDate = new Date(targetTime);
  //   let minDiff = Infinity;
  //   let closestTime = null; // Initialize to null

  //   if (timestamps && timestamps.length > 0) {
  //     // Check if targetTime is before all timestamps
  //     const timestampsDates = timestamps.map(
  //       (timestamp) => new Date(timestamp.time)
  //     );
  //     const allAfterTarget = timestampsDates.every((date) => date > targetDate);

  //     if (allAfterTarget) return closestTime; // Return null if targetTime is before all timestamps

  //     timestamps.forEach((timestamp) => {
  //       const currentDate = new Date(timestamp.time);
  //       const diff = Math.abs(targetDate - currentDate);
  //       if (diff < minDiff) {
  //         minDiff = diff;
  //         closestTime = timestamp.time;
  //       }
  //     });
  //   }
  //   return closestTime ? parseDateXAxis(closestTime, duration) : null; // Ensure parseDateXAxis is called only if closestTime is not null
  // };

  return (
    <ResponsiveContainer height={height}>
      <AreaChart
        width="100%"
        height={height}
        data={graphData}
        onMouseLeave={handleMouseLeave}
      >
        <XAxis
          dataKey="formattedDate"
          tick={{ fontSize: 10, fill: color === 'white' ? 'white' : '#666' }}
          stroke={color === 'white' ? 'white' : '#666'}
          tickSize={3}
          tickMargin={10}
        />
        <YAxis
          yAxisId={0}
          orientation="right"
          dataKey="change"
          stroke={color === 'white' ? 'white' : '#666'}
          tick={{ fontSize: 10, fill: color === 'white' ? 'white' : '#666' }}
          tickFormatter={(value) => `${value}%`}
          hide
        />
        <YAxis
          yAxisId={1}
          dataKey="price"
          domain={[minDomainPrice, maxDomainPrice]}
          stroke={color === 'white' ? 'white' : '#666'}
          tick={{ fontSize: 10, fill: color === 'white' ? 'white' : '#666' }}
          tickFormatter={
            (value) => `$${formatNumberByCheckList(ticksList, value)}`
            // eslint-disable-next-line react/jsx-curly-newline
          }
          ticks={ticksList}
          width={50}
        />
        <Tooltip
          content={<CustomTooltip handleActiveData={handleActiveData} />}
        />
        <defs>
          <linearGradient id={`${id}splitColor`} x1="0" y1="0" x2="0" y2="1">
            <stop offset={0} stopColor="#67EBAF" stopOpacity={1} />
            <stop
              offset={offset}
              stopColor={color === 'white' ? '#8A81AC' : '#fff'}
              stopOpacity={1}
            />
            <stop offset={1} stopColor="#EB67A3" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area
          type="linear"
          dataKey="change"
          stroke="#251A5F"
          fill={`url(#${id}splitColor)`}
          // stroke={`url(#${id}splitColor)`}
          // strokeWidth={4}
          // fill="none"
        />
        {/* <ReferenceLine x={findClosestTime(createdAt, graphData)} stroke="red" /> */}
        <ReferenceLine
          label={{
            value: `Period Start Price: $${Number(
              periodStartPrice
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            fontSize: 9,
            fill: color === 'white' ? '#FFF' : '#251A60',
            position: 'right',
            offset: -(
              85 +
              Number(periodStartPrice).toLocaleString().length * 5
            ),
            dy: periodStartPrice >= portfolioEntryPrice ? -8 : 8,
          }}
          y={
            periodStartPrice > maxDomainPrice
              ? maxDomainPrice
              : periodStartPrice < minDomainPrice
              ? minDomainPrice
              : periodStartPrice
          }
          yAxisId={1}
          stroke="#251A60"
          strokeWidth={2}
          strokeDasharray="3 3"
        />
        <ReferenceLine
          label={{
            value: `Portfolio Entry Price: $${Number(
              portfolioEntryPrice
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            fontSize: 9,
            fill: color === 'white' ? '#FFF' : '#251A60',
            position: 'right',
            offset: -(
              101 +
              Number(portfolioEntryPrice).toLocaleString().length * 5
            ),
            dy: portfolioEntryPrice >= periodStartPrice ? -8 : 8,
          }}
          y={
            portfolioEntryPrice > maxDomainPrice
              ? maxDomainPrice
              : portfolioEntryPrice < minDomainPrice
              ? minDomainPrice
              : portfolioEntryPrice
          }
          yAxisId={1}
          stroke={color === 'white' ? '#FFF' : '#000'}
          strokeWidth={2}
          strokeDasharray="3 3"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartEl;
