import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-disabled': {
    color: theme.palette.primary.main,
    opacity: 0.3
  }
}));
  
