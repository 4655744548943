import React, { useState } from 'react';
import { TextField } from '@mui/material';
import MButton from '../../Common/MButton';
import { AssetSuggestionWrapper, AssetSuggestionFormControl } from './styles';

const AssetSuggestion = ({ onClick }) => {
  const [value, setValue] = useState('');

  return (
    <AssetSuggestionWrapper>
      <p style={{ marginBottom: 0, marginRight: '10px' }}>
        Can&apos;t find your asset(s), list it/them here:
      </p>
      <AssetSuggestionFormControl variant="standard">
        <TextField
          id="assets-suggestion"
          value={value}
          variant="outlined"
          placeholder="Assets"
          onChange={(e) => setValue(e.target.value)}
        />
      </AssetSuggestionFormControl>
      <MButton green="true" onClick={() => onClick(value)}>
        Send
      </MButton>
    </AssetSuggestionWrapper>
  );
};

export default AssetSuggestion;
