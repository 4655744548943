/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Stack,
  IconButton,
  Collapse,
  Table,
  TableHead,
  TableBody,
  Skeleton,
  Tooltip,
  Link,
} from '@mui/material';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Check as CheckIcon,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAsset } from '../../../../reducers/assetsReducer';
import {
  StyledTableRow,
  StyledLogo,
  StyledChip,
  StyledTableCell,
  StyledCheckbox,
  StyledTableText,
  StyledPriceChip,
} from '../../../../components/Base/Table/styles';
import {
  formatNumber,
  getSuperscriptNumber,
  getExponentNumber,
  formatBigNumber,
} from '../../../../utils/parseNumber';
import Paragraph from '../../../../components/Common/Paragraph';
import ColorIndicator from '../../../../components/ColorIndicator';

const AssetsTableItem = ({ data, assetClass, isLoading }) => {
  const {
    displayName,
    logo,
    id,
    tags,
    price,
    price24H,
    price1M,
    price1Y,
    marketCap,
    ticker,
    description,
    risk_score,
  } = data;
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedAssets = useSelector((state) => state.assets.selectedAssets);
  const tooltipDescription =
    'The risk score is based on asset price volatility. For further details, please refer to our';

  const [open, setOpen] = useState(false);

  const checkSelected = (assetId) => {
    const index = selectedAssets.findIndex((asset) => asset.id === assetId);
    if (index === -1) return false;
    return true;
  };

  const handleCheckAsset = () => {
    dispatch(toggleAsset(id));
  };

  return (
    <>
      <StyledTableRow
        checked={checkSelected(id)}
        onDoubleClick={() => setOpen(!open)}
      >
        <StyledTableCell border={!open}>
          {isLoading ? (
            <Skeleton variant="circular" width={20} sx={{ margin: 'auto' }} />
          ) : (
            <StyledCheckbox
              icon={<></>}
              checkedIcon={<CheckIcon />}
              aria-label="checkbox"
              color="primary"
              checked={checkSelected(id)}
              onChange={handleCheckAsset}
            />
          )}
        </StyledTableCell>
        <StyledTableCell border={!open} colSpan={2}>
          {isLoading ? (
            <Skeleton height={42} />
          ) : (
            <Stack direction="row" alignItems="center" gap={2}>
              <StyledLogo src={logo} alt="coin" />
              <StyledTableText sx={{ textTransform: 'capitalize' }}>
                {`${displayName} `}{' '}
                <b className="ticker">{`(${ticker?.toUpperCase()})`}</b>
              </StyledTableText>
            </Stack>
          )}
        </StyledTableCell>
        <StyledTableCell
          border={!open}
          colSpan={1}
          align="right"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {isLoading ? (
            <Skeleton height={42} />
          ) : (
            <Tooltip
              title={price.toFixed(getExponentNumber(price))}
              disableHoverListener={price >= 0.01}
            >
              <span>
                $
                {price < 0.01 && price !== 0 ? (
                  <>
                    {getSuperscriptNumber(price, 0)}
                    {' e'}
                    <sup>{getSuperscriptNumber(price, 1)}</sup>
                  </>
                ) : (
                  <>{formatNumber(price)}</>
                )}
              </span>
            </Tooltip>
          )}
        </StyledTableCell>
        {assetClass !== 2 ? (
          <>
            <StyledTableCell border={!open} colSpan={2} align="right">
              {isLoading ? (
                <Skeleton height={42} />
              ) : (
                `$${formatBigNumber(marketCap, 2)}`
              )}
            </StyledTableCell>
            {assetClass !== 5 ? (
              <StyledTableCell border={!open} colSpan={1} align="right">
                {isLoading ? (
                  <Skeleton height={42} />
              ) : (
                <StyledPriceChip
                  label={`${price24H > 0 ? '+' : ''}${price24H.toFixed(2)}%`}
                  color={
                    price24H === 0 ? 'info' : price24H > 0 ? 'success' : 'warning'
                  }
                  size="small"
                  sx={{
                    color:
                      price24H >= 0
                        ? theme.palette.primary.main
                        : theme.palette.white,
                  }}
                />
              )}
              </StyledTableCell>
            ) : null }
          </>
        ) : null}
        <StyledTableCell border={!open} colSpan={2} align="right">
          {isLoading ? (
            <Skeleton height={42} />
          ) : (
            <StyledPriceChip
              label={`${price1M > 0 ? '+' : ''}${price1M.toFixed(2)}%`}
              color={
                price1M === 0 ? 'info' : price1M > 0 ? 'success' : 'warning'
              }
              size="small"
              sx={{
                color:
                  price1M >= 0
                    ? theme.palette.primary.main
                    : theme.palette.white,
              }}
            />
          )}
        </StyledTableCell>
        <StyledTableCell border={!open} colSpan={2} align="right">
          {isLoading ? (
            <Skeleton height={42} />
          ) : (
            <StyledPriceChip
              label={`${price1Y > 0 ? '+' : ''}${price1Y.toFixed(2)}%`}
              color={
                price1Y === 0 ? 'info' : price1Y > 0 ? 'success' : 'warning'
              }
              size="small"
              sx={{
                color:
                  price1Y >= 0
                    ? theme.palette.primary.main
                    : theme.palette.white,
              }}
            />
          )}
        </StyledTableCell>
        <StyledTableCell border={!open} align="right">
          {isLoading ? (
            <Skeleton variant="circular" width={20} sx={{ margin: 'auto' }} />
          ) : (
            <IconButton
              aria-label="collapse table"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          border={open}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell type="transparent" sx={{ width: '60%' }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell type="transparent" sx={{ width: '30%' }}>
                    Tags
                  </StyledTableCell>
                  <StyledTableCell
                    type="transparent"
                    sx={{ width: '10%', whiteSpace: 'nowrap' }}
                  >
                    Risk score
                    <sup>
                      <Tooltip
                        title={
                          <>
                            {tooltipDescription}{' '}
                            <Link
                              href="https://divercefi.com/portfolio-risk-score"
                              target="_blank"
                              style={{
                                color: 'inherit',
                                textDecoration: 'underline',
                              }}
                            >
                              methodology
                            </Link>
                            .
                          </>
                        }
                        sx={{ color: theme.palette.lightGrey3 }}
                      >
                        <InfoIcon fontSize="50%" />
                      </Tooltip>
                    </sup>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ width: '60%' }}
                  >
                    <StyledTableText>{description}</StyledTableText>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '30%' }}>
                    {tags.map((tag) => (
                      <StyledChip key={tag.id} label={tag.tagName} />
                    ))}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '10%' }}>
                    <Paragraph fontsize="16px" fontweight={500}>
                      <ColorIndicator number={Math.round(risk_score)} />
                    </Paragraph>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default AssetsTableItem;
