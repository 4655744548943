import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchBarWrapper = styled('div')(({ theme, width }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.white,
  padding: 10,
  height: 43,
  width: width === 'full' ? '100%' : 169,
  minWidth: width === 'full' ? '100%' : 169,
  borderRadius: 14,
}));

export const SearchFormControl = styled(FormControl)(({ theme, width }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: width === 'full' ? '100%' : 'auto',

  '& .MuiInputBase-input': {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
    padding: '8px 14px',

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));
