import React from 'react';
import { ButtonWrapper, ButtonText } from './styles';

const CButton = ({
  iconBefore,
  iconAfter,
  img,
  disabled = false,
  children,
  onClick,
  ...props
}) => {
  return (
    <ButtonWrapper {...props} disabled={disabled} onClick={onClick}>
      {iconBefore && <img src={img} alt="button icon" height="100%" />}
      <ButtonText {...props}>{children}</ButtonText>
    </ButtonWrapper>
  );
};

export default CButton;
