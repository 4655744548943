import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, MenuItem, ListItemIcon } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { detectCedeProvider } from '@cedelabs/providers';
import CMenu from '../../../components/Base/CMenu';
import CButton from '../../../components/Base/CButton';
import { setWalletConnected } from '../../../reducers/walletsReducer';
import CedeLogo from '../../../assets/img/Wallet/cede.svg';
import {
  setNotificationType,
  setNotification,
  setNotificationShow,
} from '../../../reducers/globalReducer';

const Wallet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectedRedux = useSelector((state) => state.wallets.connected);
  const connected = localStorage.getItem('WalletConnected')
    ? JSON.parse(localStorage.getItem('WalletConnected'))
    : connectedRedux;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    return () => {
      handleDisconnect();
    };
  }, []);

  useEffect(() => {
    if (connected) {
      dispatch(setNotificationShow(true));
      dispatch(setNotificationType('success'));
      dispatch(setNotification('Cede.store is connected successfully'));
    } else if (connected !== undefined) {
      dispatch(setNotificationShow(true));
      dispatch(setNotification('Cede.store is disconnected'));
    }
  }, [connected]);

  const handleDisconnect = async () => {
    const globalProvider = await detectCedeProvider();
    if (globalProvider) {
      await globalProvider.request({ method: 'disconnect' }).catch((err) => {
        console.log(err);
      });
      dispatch(setWalletConnected(false));
      localStorage.removeItem('WalletConnected');
    }
  };

  const handleConnect = () => {
    navigate('/portfolio-import');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return connected ? (
    <Box>
      <CButton
        img={CedeLogo}
        iconBefore
        border="true"
        size="md"
        transform="none"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Cede.store
      </CButton>
      <CMenu anchorEl={anchorEl} open={open} handleClose={handleClose}>
        <MenuItem onClick={handleDisconnect}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Disconnect
        </MenuItem>
      </CMenu>
    </Box>
  ) : (
    <Box>
      <CButton
        img={CedeLogo}
        iconBefore={connected}
        border="true"
        size="md"
        onClick={handleConnect}
      >
        Connect
      </CButton>
    </Box>
  );
};

export default Wallet;
