const initialState = {
  assets: [],
  selectedAssets: [],
};

export const TOGGLE_ASSET = 'TOGGLE_ASSET';
export const RESET_TOGGLE = 'RESET_TOGGLE';
export const LOAD_ASSETS = 'LOAD_ASSETS';
export const RESET_ASSETS = 'RESET_ASSETS';

export const toggleAsset = (id) => {
  return {
    type: TOGGLE_ASSET,
    id,
  };
};

export const resetToggle = (assetClass) => {
  return {
    type: RESET_TOGGLE,
    assetClass,
  };
};

export const loadAssets = (assetClass, data) => ({
  type: LOAD_ASSETS,
  assetClass,
  payload: data,
});

export const resetSelectedAssets = () => {
  return {
    type: RESET_ASSETS,
  };
};

const handleToggleAsset = (state, action) => {
  const selectedAssets = [...state.selectedAssets];
  const index = selectedAssets.findIndex((asset) => asset.id === action.id);
  if (index === -1) {
    const assetToAdd = state.assets.find((asset) => asset.id === action.id);
    if (assetToAdd) {
      assetToAdd.selected = true;
      selectedAssets.push(assetToAdd);
    }
  } else {
    selectedAssets.splice(index, 1);
  }

  return {
    ...state,
    selectedAssets,
  };
};

const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ASSETS:
      return {
        ...state,
        assets: action.payload
          .map((asset) => {
            return asset;
          })
          .concat(
            state.assets.filter(
              (asset) => asset.assetClass !== action.assetClass
            )
          ),
      };
    case TOGGLE_ASSET:
      return handleToggleAsset(state, action);
    case RESET_TOGGLE:
      return {
        ...state,
        selectedAssets: state.selectedAssets.filter(
          (asset) => asset.assetClass !== action.assetClass
        ),
      };
    case RESET_ASSETS:
      return {
        ...state,
        selectedAssets: [],
      };
    default:
      return state;
  }
};

export default assetsReducer;
