import React from 'react';
import { InputLabel, MenuItem } from '@mui/material';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { StyledFormControl, StyledSelect } from './styles';

const CDropdown = ({
  data,
  label,
  value,
  handleChange,
  variant,
  error,
  disabled,
}) => {
  return (
    <StyledFormControl type={variant} error={error}>
      <InputLabel id="portfolio-select">{label}</InputLabel>
      <StyledSelect
        labelId="portfolio-select"
        value={value}
        label={label}
        onChange={handleChange}
        IconComponent={PlayArrowIcon}
        type={variant}
        disabled={disabled}
      >
        {data.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default CDropdown;
