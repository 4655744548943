import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const StyedTooltip = styled(Box)(({ theme }) => ({
  background: theme.palette.white,
  borderRadius: 6,
  padding: 6,
}));

export const StyedTooltipLabel = styled(Typography)(() => ({
  fontSize: 12,
}));
