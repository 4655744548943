export const nativeTokens = {
  '0x1': 'ETH',
  '0x5': 'ETH',
  '0xaa36a7': 'ETH',
  '0x4268': 'ETH',
  '0x89': 'MATIC',
  '0x13881': 'MATIC',
  '0x38': 'BNB',
  '0x61': 'BNB',
  '0xa86a': 'AVAX',
  '0xfa': 'FTM',
  '0x19': 'CRO',
  '0x2a15c308d': 'PALM',
  '0xa4b1': 'ARB',
  '0x64': 'GNO',
  '0x27d8': 'GNO',
  '0x2105': 'ETH',
  '0x14a33': 'ETH',
  '0x14a34': 'ETH',
  '0xa': 'OP',
};

export const chainData = [
  {
    id: '0x1',
    name: 'Ethereum Mainnet',
    testnet: false,
  },
  { id: '0xaa36a7', name: 'Ethereum Sepolia', testnet: true },
  {
    id: '0x5',
    name: 'Ethereum Görli',
    testnet: true,
  },
  {
    id: '0x4268',
    name: 'Ethereum Holesky',
    testnet: true,
  },
  {
    id: '0x89',
    name: 'Polygon Mainnet',
    testnet: false,
  },
  {
    id: '0x13881',
    name: 'Polygon Mumbai',
    testnet: true,
  },
  {
    id: '0x38',
    name: 'Binance Smart Chain Mainnet',
    testnet: false,
  },
  {
    id: '0x61',
    name: 'Binance Smart Chain Testnet',
    testnet: true,
  },
  {
    id: '0xa86a',
    name: 'Avalanche C-Chain',
    testnet: false,
  },
  {
    id: '0xfa',
    name: 'Fantom',
    testnet: false,
  },
  {
    id: '0x19',
    name: 'Cronos Mainnet',
    testnet: false,
  },
  {
    id: '0x2a15c308d',
    name: 'Palm',
    testnet: false,
  },
  {
    id: '0xa4b1',
    name: 'Arbitrum',
    testnet: false,
  },
  {
    id: '0x64',
    name: 'Gnosis',
    testnet: false,
  },
  {
    id: '0x27d8',
    name: 'Gnosis Chiado',
    testnet: true,
  },
  {
    id: '0x2105',
    name: 'Base',
    testnet: false,
  },
  {
    id: '0x14a33',
    name: 'Base Goerli',
    testnet: true,
  },
  {
    id: '0x14a34',
    name: 'Base Sepolia',
    testnet: true,
  },
  {
    id: '0xa',
    name: 'Optimism',
    testnet: false,
  },
  {
    id: 'NetXdQprcVkpaWU',
    name: 'Tezos',
    testnet: false,
  },
];
