const initialState = {
  errorType: 'error',
  errorShow: false,
  errorMessage: '',
  expandedTable: 5,
  currentFocus: '',
  currentFocusData: {
    uuid: '',
    oldPercentage: 0,
    newPercentage: 0,
  },
  unfinishedPortfolio: false,
  searchQuery: '',
  anchorSidebar: false,
  openSidebar: true,
};

export const SET_ERROR_TYPE = 'SET_ERROR_TYPE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_ERROR_SHOW = 'SET_ERROR_SHOW';
export const SET_EXPAND_TABLE = 'SET_EXPAND_TABLE';
export const SET_CURRENT_FOCUS = 'SET_CURRENT_FOCUS';
export const SET_CURRENT_FOCUS_DATA = 'SET_CURRENT_FOCUS_DATA';
export const SET_UNFINISHED_PORTFOLIO = 'SET_UNFINISHED_PORTFOLIO';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_ANCHOR_SIDEBAR = 'SET_ANCHOR_SIDEBAR';
export const SET_OPEN_SIDEBAR = 'SET_OPEN_SIDEBAR';

export const setNotificationType = (errorType) => {
  return {
    type: SET_ERROR_TYPE,
    errorType,
  };
};

export const setNotificationShow = (show) => {
  return {
    type: SET_ERROR_SHOW,
    show,
  };
};

export const setNotification = (message) => {
  return {
    type: SET_ERROR_MESSAGE,
    message,
  };
};

export const setExpandTable = (id) => {
  return {
    type: SET_EXPAND_TABLE,
    id,
  };
};

export const setCurrentFocus = (value) => {
  return {
    type: SET_CURRENT_FOCUS,
    value,
  };
};

export const setCurrentFocusData = (uuid, oldPercentage, newPercentage) => {
  return {
    type: SET_CURRENT_FOCUS_DATA,
    uuid,
    oldPercentage,
    newPercentage,
  };
};

export const setUnfinishedPortfolio = (portfolio) => {
  return {
    type: SET_UNFINISHED_PORTFOLIO,
    portfolio,
  };
};

export const setSearchQuery = (query) => {
  return {
    type: SET_SEARCH_QUERY,
    query,
  };
};

export const setAnchorSidebar = (open) => {
  return {
    type: SET_ANCHOR_SIDEBAR,
    open,
  };
};

export const setOpenSidebar = (open) => {
  return {
    type: SET_OPEN_SIDEBAR,
    open,
  };
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_TYPE:
      return {
        ...state,
        errorType: action.errorType,
      };
    case SET_ERROR_SHOW:
      return {
        ...state,
        errorShow: action.show,
        errorType: !action.show ? state.errorType : 'error',
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.message,
      };
    case SET_EXPAND_TABLE:
      return {
        ...state,
        expandedTable: action.id,
      };
    case SET_CURRENT_FOCUS:
      return {
        ...state,
        currentFocus: action.value,
      };
    case SET_CURRENT_FOCUS_DATA:
      return {
        ...state,
        currentFocusData: {
          uuid: action.uuid,
          oldPercentage: action.oldPercentage,
          newPercentage: action.newPercentage,
        },
      };
    case SET_UNFINISHED_PORTFOLIO:
      return {
        ...state,
        unfinishedPortfolio: action.portfolio,
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.query,
      };
    case SET_ANCHOR_SIDEBAR:
      return {
        ...state,
        anchorSidebar: action.open,
      };
    case SET_OPEN_SIDEBAR:
      return {
        ...state,
        openSidebar: action.open,
      };
    default:
      return state;
  }
};

export default globalReducer;
