import { Select, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSelect = styled(Select)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  background: theme.palette.white,
  height: 'fit-content',
  fontSize: 12,
  color: theme.palette.primary.main,
  borderRadius: 14,
  zIndex: 2,
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '11px 24px 11px 9px',
  },
  '& .MuiSvgIcon-root': {
    right: 0,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 40,
  width: 40,
  height: 30,
  padding: 0,
  '&.small': {
    minWidth: 24,
    width: 24,
    height: 24,
    fontSize: 10,
  },
  '&.long_content': {
    minWidth: 64,
    fontSize: 10,
    lineHeight: 1,
  },
  '&.active': {
    background: theme.palette.white,
    color: theme.palette.primary.main,
  },
}));
