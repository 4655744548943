import axios from 'axios';

export const BASEURL = 'https://portfolio-api-nmjee7u4ea-uc.a.run.app';

export const getAssetsList = async (params) => {
  try {
    const response = await axios.get(`${BASEURL}/assets/list?${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
      },
    });

    return response;
  } catch (error) {
    return { error: 'Something went wrong. Please try again later!' };
  }
};

export const getAssetsByTicker = async (params) => {
  try {
    const response = await axios.get(`${BASEURL}/assets/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
      },
    });

    return response;
  } catch (error) {
    return { error: 'Something went wrong. Please try again later!' };
  }
};

export const getPortfolioList = async (token, params) => {
  try {
    const response = await axios.get(`${BASEURL}/portfolios/list?${params}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
        'X-CE-AUTH-TOKEN': token,
      },
    });

    return response;
  } catch (error) {
    return { error: 'Something went wrong. Please try again later!' };
  }
};

export const getPortfolioAggregate = async (token, params) => {
  try {
    const response = await axios.get(
      `${BASEURL}/portfolios/aggregate?${params}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
          'X-CE-AUTH-TOKEN': token,
        },
      }
    );

    return response;
  } catch (error) {
    return { error: 'Something went wrong. Please try again later!' };
  }
};

export const createPortfolio = async (portfolio, token) => {
  const {
    title,
    address,
    chain,
    coins,
    tracked,
    live,
    connected,
    manual,
    category,
    capital,
    currency,
    updated,
    count,
  } = portfolio;

  const payload = {
    name: title,
    address,
    chain,
    description: 'test',
    isPublic: false,
    assets: coins,
    tracked,
    live,
    connected,
    manual,
    category,
    capital,
    currency,
    updated,
    count,
  };

  try {
    const response = await axios.post(
      `${BASEURL}/portfolios/create`,
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
          'X-CE-AUTH-TOKEN': token,
        },
      }
    );

    return response;
  } catch (error) {
    // Handle errors
    return error.response.data;
  }
};

export const archivePortfolio = async (id, token) => {
  const payload = {
    id,
  };

  try {
    const response = await axios.post(
      `${BASEURL}/portfolios/archive`,
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
          'X-CE-AUTH-TOKEN': token,
        },
      }
    );

    return response;
  } catch (error) {
    // Handle errors
    return error.response.data;
  }
};

export const updatePortfolio = async (portfolio, token) => {
  try {
    const response = await axios.put(
      `${BASEURL}/portfolios/update`,
      JSON.stringify(portfolio),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
          'X-CE-AUTH-TOKEN': token,
        },
      }
    );

    return response;
  } catch (error) {
    // Handle errors
    return error.response.data;
  }
};
