import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from './Header';
import Sidebar from './Sidebar';

export const Wrapper = styled(Box)(() => ({
  minHeight: '100vh',
  paddingTop: '70px',
}));

export const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: 'calc(100vh - 70px)',
  maxWidth: '1440px',
  margin: '0px auto',
  paddingLeft: 24,
  paddingRight: 24,
  display: 'flex',
  gap: 24,

  [theme.breakpoints.down('sm')]: {
    padding: 12,
  },
}));

export const BodyWrapper = styled(Box)(({ theme, open }) => ({
  width: `calc(100% - ${open ? 284 : 89}px)`,
  paddingTop: 24,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Layout = () => {
  const openSidebar = useSelector((state) => state.global.openSidebar);
  return (
    <Box>
      <Header />
      <Wrapper>
        <Container>
          <Sidebar />
          <BodyWrapper open={openSidebar}>
            <Outlet />
          </BodyWrapper>
        </Container>
      </Wrapper>
    </Box>
  );
};

export default Layout;
