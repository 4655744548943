const initialState = {
  filters: {
    minPrice: 0,
    maxPrice: 99999,
    minChange: -100,
    maxChange: 9999,
    minMarketCap: 0,
    maxMarketCap: 9999999999999,
    minValue: 0,
    maxValue: 999999999,
    assetClasses: [],
    tags: [],
  },
};

export const SET_PRICE_RANGE = 'SET_PRICE_RANGE';
export const SET_MARKETCAP_RANGE = 'SET_MARKETCAP_RANGE';
export const SET_VALUE_RANGE = 'SET_VALUE_RANGE';
export const SET_CHANGE_RANGE = 'SET_CHANGE_RANGE';
export const SET_ASSET_CLASSES_FILTER = 'SET_ASSET_CLASSES_FILTER';
export const SET_TAGS = 'SET_TAGS';

export const setPriceRange = (min, max) => {
  return {
    type: SET_PRICE_RANGE,
    min,
    max,
  };
};

export const setMarketCapRange = (min, max) => {
  return {
    type: SET_MARKETCAP_RANGE,
    min,
    max,
  };
};

export const setValueRange = (min, max) => {
  return {
    type: SET_VALUE_RANGE,
    min,
    max,
  };
};

export const setChangeRange = (min, max) => {
  return {
    type: SET_CHANGE_RANGE,
    min,
    max,
  };
};

export const setAssetClasses = (item, value) => {
  return {
    type: SET_ASSET_CLASSES_FILTER,
    item,
    value,
  };
};

export const setTags = (data) => {
  return {
    type: SET_TAGS,
    data,
  };
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICE_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          minPrice: action.min,
          maxPrice: action.max,
        },
      };
    case SET_MARKETCAP_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          minMarketCap: action.min,
          maxMarketCap: action.max,
        },
      };
    case SET_VALUE_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          minValue: action.min,
          maxValue: action.max,
        },
      };
    case SET_CHANGE_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          minChange: action.min,
          maxChange: action.max,
        },
      };
    case SET_ASSET_CLASSES_FILTER:
      if (action.value) {
        // Add the item if value is true and it doesn't already exist in the array
        if (!state.filters.assetClasses.includes(action.item)) {
          return {
            ...state,
            filters: {
              ...state.filters,
              assetClasses: [...state.filters.assetClasses, action.item],
            },
          };
        }
      } else {
        // Remove the item if value is false
        const updatedAssetClasses = state.filters.assetClasses.filter(
          (item) => item !== action.item
        );
        return {
          ...state,
          filters: {
            ...state.filters,
            assetClasses: updatedAssetClasses,
          },
        };
      }
      return state;
    case SET_TAGS:
      return {
        ...state,
        filters: {
          ...state.filters,
          tags: action.data,
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
