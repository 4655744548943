import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const Wrapper = styled.div`
  display: inline-flex;
  margin-left: 10px;
  padding: 2px 10px;
  background: #c0dcfd;
  border-radius: 12px;
  p {
    font-size: 10px;
  }

  @media (min-width: 600px) {
    p {
      font-size: 14px;
    }
  }
`;

const BetaBadge = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <Typography style={{ color: '#45549c' }}>Beta</Typography>
    </Wrapper>
  );
};

export default BetaBadge;
