import {
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Chip,
  Checkbox,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableContainer = styled(TableContainer)(() => ({
  borderRadius: 14,
}));

export const StyledTableCell = styled(TableCell)(({ theme, type, border = true }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:
      type === 'transparent' ? 'transparent' : theme.palette.white,
    color:
      type === 'transparent' ? theme.palette.white : theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    padding: '6px 16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
    borderColor: border ? theme.palette.primary.main : 'transparent',
    color: theme.palette.white,
    padding: '6px 16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));

export const StyledTableText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  [theme.breakpoints.down('lg')]: {
    fontSize: 14,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 12,
  },
  '& .ticker': {
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      fontSize: 10,
    },
  },
}));

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
    color: theme.palette.primary.main,
    marginBottom: 0,
  },
  '& .MuiNativeSelect-select, .MuiNativeSelect-icon': {
    color: theme.palette.primary.main,
  },
  '& .MuiTablePagination-spacer': {
    display: 'none',
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme, ...props }) => ({
  borderRadius: 6,
  width: 30,
  height: 30,
  border: props.border ? `1px solid ${theme.palette.primary.main}` : '',
  background: theme.palette.white,
  '&:hover': {
    background: theme.palette.white,
  },
  [theme.breakpoints.down('md')]: {
    width: 20,
    height: 20,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme, type, checked }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd), &:nth-of-type(even)': {
    backgroundColor: checked
      ? theme.palette.secondPurple
      : type === 'second'
      ? theme.palette.primary.opacity
      : theme.palette.darkPurple,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: 6,
  backgroundColor: theme.palette.lightGrey4,
  height: 16,
  fontSize: 10,
  fontWeight: 600,
  textTransform: 'uppercase',
  marginRight: 2,
  ':last-child': {
    marginRight: 0,
  },
  '& .MuiChip-label': {
    padding: 4,
  },
}));

export const StyledPriceChip = styled(Chip)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  [theme.breakpoints.down('lg')]: {
    fontSize: 12,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 10,
  },
}));

export const StyledLogo = styled('img')(({ theme }) => ({
  width: 40,
  height: 40,
  objectFit: 'contain',

  [theme.breakpoints.down('md')]: {
    width: 30,
    height: 30,
  },
}));
