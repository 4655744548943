import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import getTheme from '../../../../theme';

const theme = getTheme();

const Wrapper = styled('div')`
  width: 100%;
  position: relative;

  .MuiInputBase-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(256, 256, 256, 0.87);
      }
    }
  }

  .MuiInputBase-input {
    color: ${() => theme.palette.white};

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s;
    }
  }

  .MuiInputLabel-root {
    color: ${() => theme.palette.white};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${() => theme.palette.white};
  }
`;

const Input = (props) => {
  return (
    <Wrapper>
      <TextField {...props} color="secondary" />
    </Wrapper>
  );
};

export default Input;
