import React from 'react';
import styled from 'styled-components';
import getTheme from '../../../theme';

const theme = getTheme();

const Wrapper = styled.h5`
  color: ${props => (props.color ? props.color : theme.palette.primary.main)};
  font-size: ${props => (props.fontsize ? props.fontsize : '15px')};
  font-weight: ${props => (props.fontweight ? props.fontweight : '700')};

  @media (min-width: 1920px) {
    font-size: ${props => (props.fontsize ? props.fontsize : '16px')};
  }
`;

const H5 = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>
  );
};

export default H5;
