import { Dialog, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)(({ theme, ...props }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  '& .MuiDialog-container': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 1200,
    height: props.height ? props.height : 'auto',
    width: props.width ? props.width : 'auto',
    '& .MuiDialog-paper': {
      maxWidth: '100%',
      width: '100%',
      background:
        props.bgcolor === 'purple'
          ? theme.palette.lightPurple
          : props.bgcolor === 'transparent'
          ? 'transparent'
          : theme.palette.white,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    '& .MuiDialogTitle-root': {
      fontSize: 18,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '& .MuiDialogContent-root': {
      borderRadius: 8,
      padding: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.primary.main,
}));
