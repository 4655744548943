import axios from 'axios';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';
import { auth } from '../config/firebase';

export const BASEURL = 'https://user-api-nmjee7u4ea-uc.a.run.app';

const createUserField = async (payload, token) => {
  try {
    const response = await axios.post(`${BASEURL}/users/create/`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
        'X-CE-AUTH-TOKEN': token,
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    return error.response.data;
  }
};

export const getUserInfo = async (token) => {
  try {
    const response = await axios.get(`${BASEURL}/users/read/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
        'X-CE-AUTH-TOKEN': token,
      },
    });

    return response;
  } catch (error) {
    return { error: 'Something went wrong. Please try again later!' };
  }
};

const updateUserFields = async (payload, token) => {
  try {
    const response = await axios.post(`${BASEURL}/users/update/`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'X-CE-API-KEY': process.env.REACT_APP_BE_API_KEY,
        'X-CE-AUTH-TOKEN': token,
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    return error.response.data;
  }
};

export const createUser = async (values) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      values.email,
      values.password
    );
    const { user } = userCredential;

    await sendEmailVerification(user, {
      url: `${window.location.origin.toString()}/login`,
    });

    const userPayload = {
      displayName: `${values.firstname} ${values.surname}`,
      email: values.email,
      username: values.username,
    };

    const crUser = await createUserField(
      JSON.stringify(userPayload),
      user.accessToken
    );

    return crUser;
  } catch (error) {
    let message = 'Something went wrong! Please try again later!';

    switch (error.code) {
      case 'auth/wrong-password':
        message =
          'Wrong password. Please choose another password and try again!';
        break;
      case 'auth/email-already-in-use':
        message =
          'The email address is already in use by an existing user. Please choose another email address!';
        break;
      case 'auth/invalid-display-name':
        message = 'The provided name is invalid. Please try again!';
        break;
      case 'auth/invalid-email':
        message = 'The provided email is invalid. Please try again!';
        break;
      case 'auth/weak-password':
        message = 'The password is too weak. Please try again!';
        break;
      default:
        message = 'Something went wrong! Please try again later!';
    }

    return { error: message };
  }
};

export const signInUser = async (values) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      values.email,
      values.password
    );

    // Verify if email was changed and update it to db
    const { user } = userCredential;
    const { accessToken } = user;
    const dbUser = await getUserInfo(accessToken);

    if (dbUser.data.email !== user.email) {
      const payload = {
        displayName: dbUser.data.displayName,
        username: dbUser.data.username,
        email: user.email,
        id: user.uid,
      };

      const updatedUser = await updateUserFields(payload, accessToken);

      if (updatedUser.status !== 201) {
        return { error: 'Something went wrong! Please try again later!' };
      }
    }

    if (user && user.emailVerified) {
      return user;
    }

    return {
      error:
        'E-Mail is not verified. Please verify your E-Mail address first and then sign in again!',
    };
  } catch (error) {
    let message = 'Something went wrong! Please try again later!';

    switch (error.code) {
      case 'auth/wrong-password':
        message =
          'Wrong password. Please choose another password and try again!';
        break;
      case 'auth/email-already-in-use':
        message =
          'The email address is already in use by an existing user. Please choose another email address!';
        break;
      case 'auth/invalid-display-name':
        message = 'The provided name is invalid. Please try again!';
        break;
      case 'auth/invalid-email':
        message = 'The provided email is invalid. Please try again!';
        break;
      case 'auth/weak-password':
        message = 'The password is too weak. Please try again!';
        break;
      case 'auth/user-not-found':
        message = 'You do not have yet an account. Please Sign Up first!';
        break;
      default:
        message = 'Something went wrong! Please try again later!';
    }

    return { error: message };
  }
};

export const addNewEmailHubspot = async (email) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${'26596402'}/${'4fd63854-76ae-4e21-8b69-ed1a0c5ce69c'}`;

  try {
    const dataObj = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
    };
    const result = await axios({
      method: 'post',
      url,
      data: dataObj,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return result;
  } catch (e) {
    return { error: e.message };
  }
};

export const submitFormHubspot = async (email, data) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${'26596402'}/${'6486622b-6174-4a2b-9f4b-d8826cacb762'}`;
  const dataToBeSent = [
    {
      objectTypeId: '0-1',
      name: 'email',
      value: email,
    },
  ];

  Object.keys(data).forEach((key) => {
    dataToBeSent.push({
      objectTypeId: '0-1',
      name: key,
      value: typeof data[key] === 'string' ? data[key] : data[key].join(';'),
      fieldType: typeof data[key] === 'string' ? 'radio' : 'checkbox',
    });
  });

  try {
    const dataObj = {
      fields: dataToBeSent,
    };
    const result = await axios({
      method: 'post',
      url,
      data: JSON.stringify(dataObj),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result;
  } catch (e) {
    return { error: e.message };
  }
};

export const submitPortfolioInterestHubspot = async (email, data) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${'26596402'}/${'a2d311ad-d928-485c-b6d3-d6b22dca76d5'}`;
  const dataToBeSent = [
    {
      objectTypeId: '0-1',
      name: 'email',
      value: email,
    },
  ];

  Object.keys(data).forEach((key) => {
    dataToBeSent.push({
      objectTypeId: '0-1',
      name: key,
      value: data[key],
      fieldType: 'radio',
    });
  });

  try {
    const dataObj = {
      fields: dataToBeSent,
    };
    const result = await axios({
      method: 'post',
      url,
      data: JSON.stringify(dataObj),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result;
  } catch (e) {
    return { error: e.message };
  }
};

export const updateUserInfo = async (values, token, oldEmail) => {
  try {
    if (oldEmail !== values.email) {
      await verifyBeforeUpdateEmail(auth.currentUser, values.email);

      return {
        verifyEmail:
          'You have successfully updated your informations! Please confirm your new E-Mail address and Log In!',
      };
    }

    const updatedUser = await updateUserFields(values, token);

    return updatedUser;
  } catch (error) {
    let message = 'Something went wrong! Please try again later!';

    switch (error.code) {
      case 'auth/wrong-password':
        message =
          'Wrong password. Please choose another password and try again!';
        break;
      case 'auth/email-already-in-use':
        message =
          'The email address is already in use by an existing user. Please choose another email address!';
        break;
      case 'auth/invalid-display-name':
        message = 'The provided name is invalid. Please try again!';
        break;
      case 'auth/invalid-email':
        message = 'The provided email is invalid. Please try again!';
        break;
      case 'auth/weak-password':
        message = 'The password is too weak. Please try again!';
        break;
      case 'auth/requires-recent-login':
        message = 'Please login!';
        break;
      default:
        message = 'Something went wrong! Please try again later!';
    }

    return { error: message, errorCode: error.code };
  }
};

export const forgotPassword = async (values) => {
  try {
    await sendPasswordResetEmail(auth, values.email);

    return { success: true };
  } catch (error) {
    let message = 'Something went wrong! Please try again later!';

    switch (error.code) {
      case 'auth/wrong-password':
        message =
          'Wrong password. Please choose another password and try again!';
        break;
      case 'auth/email-already-in-use':
        message =
          'The email address is already in use by an existing user. Please choose another email address!';
        break;
      case 'auth/invalid-display-name':
        message = 'The provided name is invalid. Please try again!';
        break;
      case 'auth/invalid-email':
        message = 'The provided email is invalid. Please try again!';
        break;
      case 'auth/weak-password':
        message = 'The password is too weak. Please try again!';
        break;
      default:
        message = 'Something went wrong! Please try again later!';
    }

    return { error: message };
  }
};

export const submitAssetSuggestionHubspot = async (email, data) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${'26596402'}/${'42803d5c-05a6-4e3f-876b-3798961fbb6e'}`;
  const dataToBeSent = [
    {
      objectTypeId: '0-1',
      name: 'email',
      value: email,
    },
  ];

  dataToBeSent.push({
    objectTypeId: '0-1',
    name: 'asset_suggestion',
    value: data,
  });

  try {
    const dataObj = {
      fields: dataToBeSent,
    };
    const result = await axios({
      method: 'post',
      url,
      data: JSON.stringify(dataObj),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result;
  } catch (e) {
    return { error: e.message };
  }
};
