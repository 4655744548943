const initialState = {
  userInfo: null,
  userToken: null,
  expirationTime: null,
};

export const SET_USER = 'SET_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT = 'LOGOUT';
export const EXPIRATION_TIME = 'EXPIRATION_TIME';

export const setUser = (value) => ({ type: SET_USER, value });
export const setUserToken = (value) => ({ type: SET_USER_TOKEN, value });
export const logout = () => ({ type: LOGOUT });
export const setExpirationTime = (time) => ({ type: EXPIRATION_TIME, time });

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, userInfo: action.value };
    case SET_USER_TOKEN:
      return { ...state, userToken: action.value };
    case EXPIRATION_TIME:
      return { ...state, expirationTime: action.time };
    case LOGOUT:
      return {
        userToken: null,
        userInfo: null,
        expirationTime: null,
      };
    default:
      return state;
  }
};

export default userReducer;
