import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Moralis from 'moralis';
import { TezosToolkit } from '@taquito/taquito';
import Loading from '../../../components/Loading';
import MButton from '../../../components/Common/MButton';
import {
  setNotification,
  setNotificationShow,
} from '../../../reducers/globalReducer';
import { updatePortfolio as updatePortfolioStore } from '../../../reducers/portfolioExplorerReducer';
import { updatePortfolio } from '../../../lib/assetsApi';
import {
  startMoralis,
  getAssets,
  getNativeTokenData,
} from '../../../utils/blockchain';
import { getAuthToken } from '../../../lib/auth';
import { checkArraysEqual } from '../../../utils';
import { checkTimeAllowance } from '../../../utils/time';
import { nativeTokens } from '../../../data/network';

const PortfolioUpdate = ({ portfolioId, publicAddress, chainId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = getAuthToken();
  const [showLoading, setShowLoading] = useState(false);

  const myPortfolios = useSelector(
    (state) => state.portfolioExplorer.myPortfolio
  );

  const getMetamaskBalances = async (address, chain) => {
    setShowLoading(true);
    let assetsData = [];
    let allCoins = [];

    await Moralis.EvmApi.token
      .getWalletTokenBalances({
        address,
        chain,
      })
      .then(async (response) => {
        allCoins = response.toJSON().filter((coin) => {
          return !coin.possible_spam;
        });
        if (allCoins.length === 0) {
          setShowLoading(false);
          dispatch(setNotificationShow(true));
          dispatch(
            setNotification('There is no available tokens on this blockchain')
          );
        }
        const sumTotalTickers = allCoins.map((coin) => coin.symbol).join(',');
        assetsData = await getAssets(sumTotalTickers);
        // assetsData = await Promise.all(
        //   allCoins.map(async (coin) =>
        //     getEvmPrice(coin.symbol, coin.token_address)
        //   )
        // );
        await Moralis.EvmApi.balance
          .getNativeBalance({
            address,
            chain,
          })
          .then(async (nativeResponse) => {
            const nativeBalance = nativeResponse.toJSON().balance;
            if (nativeBalance > 0) {
              await getNativeTokenData(chain)
                .then((res) => {
                  if (res.length > 0) {
                    const nativeTokenDetails = {
                      balance: nativeBalance,
                      decimals: 18,
                      symbol: nativeTokens[chain],
                      name: res[0].displayName,
                    };
                    assetsData.push(res[0]);
                    allCoins.push(nativeTokenDetails);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
        const sumTotalBalance = allCoins
          .map((coin) => {
            const matchingObject = assetsData.find(
              (item) => item.ticker === coin.symbol
            );
            const quantity = Number(coin.balance) / 10 ** coin.decimals;
            return quantity * (matchingObject ? matchingObject.price : 0);
          })
          .reduce((total, balanceUSD) => total + balanceUSD, 0);
        const resultArray = allCoins.map((coin) => {
          const matchingObject = assetsData.find(
            (item) => item.ticker === coin.symbol
          );
          const quantity = Number(coin.balance) / 10 ** coin.decimals;
          const amount =
            (Number(coin.balance) / 10 ** coin.decimals) *
            (matchingObject ? matchingObject.price : 0);
          return {
            ticker: coin.symbol,
            displayName: coin.name,
            price: matchingObject ? matchingObject.price : 0,
            price24H: matchingObject ? matchingObject.price24H : 0,
            quantity,
            amount,
            percentage: (amount / sumTotalBalance) * 100,
            tags: matchingObject ? matchingObject.tags : [],
            assetClass: matchingObject ? matchingObject.assetClass : 0,
            logo: matchingObject ? matchingObject.logo : coin.logo,
          };
        });
        saveConnectedAssets(portfolioId, resultArray, myPortfolios);
      })
      .catch((err) => {
        console.log(err);
        setShowLoading(false);
      });
  };

  const getTezosBalances = async () => {
    setShowLoading(true);
    let assetsData = [];
    const allCoins = [];
    const Tezos = new TezosToolkit('https://mainnet.ecadinfra.com');

    Tezos.tz
      .getBalance(publicAddress)
      .then(async (balance) => {
        const tezosDetails = {
          balance: balance.toNumber(),
          decimals: 6,
          symbol: 'XTZ',
          name: 'Tezos',
        };
        allCoins.push(tezosDetails);

        assetsData = await getAssets('XTZ');

        const sumTotalBalance = allCoins
          .map((coin) => {
            const matchingObject = assetsData.find(
              (item) => item.ticker === coin.symbol
            );
            const quantity = Number(coin.balance) / 10 ** coin.decimals;
            return quantity * (matchingObject ? matchingObject.price : 0);
          })
          .reduce((total, balanceUSD) => total + balanceUSD, 0);

        const resultArray = allCoins.map((coin) => {
          const matchingObject = assetsData.find(
            (item) => item.ticker === coin.symbol
          );
          const quantity = Number(coin.balance) / 10 ** coin.decimals;
          const amount =
            (Number(coin.balance) / 10 ** coin.decimals) *
            (matchingObject ? matchingObject.price : 0);
          return {
            ticker: coin.symbol,
            displayName: coin.name,
            price: matchingObject ? matchingObject.price : 0,
            price24H: matchingObject ? matchingObject.price24H : 0,
            quantity,
            amount,
            percentage: (amount / sumTotalBalance) * 100,
            tags: matchingObject ? matchingObject.tags : [],
            assetClass: matchingObject ? matchingObject.assetClass : 0,
            logo: matchingObject ? matchingObject.logo : coin.logo,
          };
        });

        saveConnectedAssets(portfolioId, resultArray, myPortfolios);
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setShowLoading(false);
      });
  };

  const saveConnectedAssets = useCallback(async (id, coins, portfolios) => {
    if (coins.length > 0) {
      const findConnected = portfolios.find((el) => el.id === id);

      if (findConnected) {
        if (
          findConnected.count >= 5 &&
          !checkTimeAllowance(findConnected.updated, 1)
        ) {
          dispatch(setNotificationShow(true));
          dispatch(
            setNotification(
              // eslint-disable-next-line quotes
              "You've reached the hourly limit of portfolio updates, which is five times."
            )
          );
          setShowLoading(false);
        } else {
          await updateConnected(coins, findConnected);
        }
      }
    }
  }, []);

  const updateConnected = async (coins, portfolioToUpdate) => {
    if (token) {
      setShowLoading(true);
      if (!checkArraysEqual(portfolioToUpdate.assets, coins)) {
        portfolioToUpdate = {
          ...portfolioToUpdate,
          assets: coins,
          count: checkTimeAllowance(portfolioToUpdate.updated, 1)
            ? 1
            : portfolioToUpdate.count + 1,
          updated: checkTimeAllowance(portfolioToUpdate.updated, 1)
            ? new Date()
            : portfolioToUpdate.updated,
        };

        const portfolioUpdated = await updatePortfolio(
          portfolioToUpdate,
          token
        );

        if (
          portfolioUpdated.status === 200 ||
          portfolioUpdated.status === 201
        ) {
          dispatch(updatePortfolioStore(portfolioUpdated.data));
          dispatch(setNotificationShow(false));
          setShowLoading(false);
        } else {
          dispatch(setNotificationShow(true));
          dispatch(setNotification(portfolioUpdated.error));
          setShowLoading(false);
        }
      }
    } else {
      navigate('/login');
    }
  };

  const handleUpdate = () => {
    if (chainId === 'NetXdQprcVkpaWU') {
      getTezosBalances();
    } else {
      startMoralis();
      getMetamaskBalances(publicAddress, chainId);
    }
  };

  return (
    <Box>
      <MButton sm="true" purple="true" className="me-1" onClick={handleUpdate}>
        Sync
      </MButton>
      <Loading open={showLoading} />
    </Box>
  );
};

export default PortfolioUpdate;
