import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Grid, Box, Container } from '@mui/material';

import H1 from '../../../components/Common/Titles/h1';
import Input from '../../../components/Common/Form/Input';
import MButton from '../../../components/Common/MButton';
import Paragraph from '../../../components/Common/Paragraph';
import Success from '../components/Success';
import Loading from '../../../components/Loading';

import {
  setNotification,
  setNotificationShow,
  setNotificationType,
} from '../../../reducers/globalReducer';
import { forgotPassword } from '../../../lib/userApi';
import validationSchema from './validationSchema';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const forgotPass = await forgotPassword(values);

      if (forgotPass.error) {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(forgotPass.error));
        setSuccess(false);
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotificationType('success'));
        dispatch(
          setNotification('An E-Mail with reset Password instructions sent')
        );
        setSuccess(true);
      }
      setSubmitting(false);
    },
  });

  return (
    <Container maxWidth="lg">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
        columnSpacing={2}
      >
        <Grid item sm={6}>
          <Box justifyContent="center" display="flex" sx={{ mb: 4 }}>
            <H1 color="#fff" noborder>
              Reset Password
            </H1>
          </Box>
          {success ? (
            <Success message="An E-Mail with reset Password instructions sent" />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 2 }}>
                <Input
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>

              <Box sx={{ mb: 2 }} display="flex" justifyContent="center">
                <MButton
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting
                    ? 'Signing in...'
                    : 'Request New Password'}
                </MButton>
              </Box>
            </form>
          )}

          <Paragraph color="#fff" className="text-center">
            <Link to="/login">Go to Login</Link>
          </Paragraph>
        </Grid>
      </Grid>
      <Loading open={formik.isSubmitting} />
    </Container>
  );
};

export default ForgotPassword;
