import { Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: theme.palette.white,
  zIndex: theme.zIndex.drawer + 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: 4
}));
