import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Toolbar from '../../components/Base/Toolbar';
import AssetsTable from './AssetsTable';
import PortfolioModal from '../../components/Base/PortfolioManagement/PortfolioModal';
import { addPortfolioCoins } from '../../reducers/portfolioReducer';
import {
  setNotification,
  setNotificationShow,
  setExpandTable,
  setSearchQuery,
  setNotificationType,
} from '../../reducers/globalReducer';
import { resetSelectedAssets, toggleAsset } from '../../reducers/assetsReducer';
import { submitAssetSuggestionHubspot } from '../../lib/userApi';

const Assets = () => {
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const modalopen = searchParams.get('modalopen');
  const dispatch = useDispatch();
  const selectedAssetsData = useSelector(
    (state) => state.assets.selectedAssets
  );
  const unfinishedPortfolio = useSelector(
    (state) => state.global.unfinishedPortfolio
  );
  const expandedTable = useSelector((state) => state.global.expandedTable);
  const filter = useSelector((state) => state.filter.filters);
  const user = useSelector((state) => state.user.userInfo);
  const searchQuery = useSelector((state) => state.global.searchQuery);

  const [showAddModal, setShowAddModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showLoading, setShowLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [search, setSearch] = useState(searchQuery);

  const allData = [
    {
      id: 'crypto',
      assetClass: 0,
    },
    {
      id: 'equity',
      assetClass: 1,
    },
    {
      id: 'etf',
      assetClass: 4,
    },
    {
      id: 'forex',
      assetClass: 5,
      // disabled: true,
    },
    // {
    //   id: 'nft',
    //   assetClass: 3,
    // },
  ];

  const handleChangeSearch = (event) => {
    const query = event.target.value;
    setSearch(query);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      dispatch(setSearchQuery(query));
      dispatch(setExpandTable(5));
    }, 800);
    setTimer(newTimer);
  };

  const handleSendSuggestion = async (event) => {
    setShowLoading(true);
    await submitAssetSuggestionHubspot(user.email, event);
    setShowSuggestion(false);
    setShowLoading(false);
    dispatch(setNotificationShow(true));
    dispatch(setNotificationType('success'));
    dispatch(setNotification('New assets suggestion submitted successfully!'));
  };

  const handleShowAdd = () => {
    const timestamp = new Date().getTime();

    if (selectedAssetsData.length > 0) {
      dispatch(
        addPortfolioCoins(
          selectedAssetsData.map((el) => {
            return {
              ...el,
              uuid: el.id + timestamp,
              percentage: 0,
              quantity: 0,
              amount: 0,
            };
          })
        )
      );
      setShowAddModal(true);
    } else {
      dispatch(setNotificationShow(true));
      dispatch(
        setNotification('You should pick at least one coin to add portfolio.')
      );
    }
  };

  const handleCloseAdd = () => {
    setShowAddModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      dispatch(resetSelectedAssets());
      dispatch(setSearchQuery(''));
    };
  }, []);

  useEffect(() => {
    if (searchQuery !== '') {
      setShowSuggestion(true);
    } else {
      setShowSuggestion(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (unfinishedPortfolio) {
      setShowLoading(true);
      unfinishedPortfolio.coins.map((el) => {
        return dispatch(toggleAsset(el.id));
      });
      setShowAddModal(true);
      setShowLoading(false);
    }
  }, [unfinishedPortfolio]);

  useEffect(() => {
    if (modalopen) {
      handleShowAdd();
      setSearchParams('');
    }
  }, [modalopen]);

  return (
    <Box sx={{ paddingBottom: 10 }}>
      <Toolbar
        onSearch={handleChangeSearch}
        onAdd={handleShowAdd}
        query={search}
        badgeCount={selectedAssetsData.length}
        page="assets"
        showSuggestion={showSuggestion}
        sendSuggestion={handleSendSuggestion}
      />
      <Stack gap={2.5}>
        {allData
          .filter(
            (item) =>
              filter.assetClasses.length === 0 ||
              filter.assetClasses.includes(item.id)
          )
          .map(
            (item) =>
              (expandedTable === 5 || item.assetClass === expandedTable) && (
                <AssetsTable
                  id={item.id}
                  key={item.assetClass}
                  assetClass={item.assetClass}
                  disabled={item.disabled}
                  selectedCount={
                    selectedAssetsData.filter(
                      (el) => el.assetClass === item.assetClass
                    ).length
                  }
                />
              )
          )}
      </Stack>
      <PortfolioModal
        open={showAddModal}
        onClose={handleCloseAdd}
        isControl={false}
        unfinishedPortfolio={unfinishedPortfolio}
        handleShowLoading={(param) => setShowLoading(param)}
      />
    </Box>
  );
};

export default Assets;
