export const formatBalance = (rawBalance) => {
  const balance = (parseInt(rawBalance, 10) / 1000000000000000000).toFixed(2);
  return balance;
};

export const formatChainAsNum = (chainIdHex) => {
  const chainIdNum = parseInt(chainIdHex, 10);
  return chainIdNum;
};

export const checkArraysEqual = (arr1, arr2) => {
  return arr1.every((obj1, i) => {
    const obj2 = arr2[i];
    return Object.keys(obj1).every((key) => {
      return obj1[key] === obj2[key];
    });
  });
};

export const checkBlockchainAddress = (address, chain) => {
  return `${address.slice(0, 5)}...${address.slice(-5)}(${chain})`;
};
