import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Moralis from 'moralis';
import { Box, Typography } from '@mui/material';
import AggregatePortfolio from './components/AggregatePortfolio';
import BasicPortfolio from './components/BasicPortfolio';
import Toolbar from '../../components/Base/Toolbar';
import MButton from '../../components/Common/MButton';
import { loadPortfolios } from '../../reducers/portfolioExplorerReducer';
import {
  setNotificationShow,
  setNotification,
} from '../../reducers/globalReducer';
import {
  getAssets,
  getNativeTokenData,
  startMoralis,
} from '../../utils/blockchain';
import { parseNumber } from '../../utils/parseNumber';
import { checkTimeAllowance } from '../../utils/time';
import { checkArraysEqual } from '../../utils';
import { getPortfolioList, updatePortfolio } from '../../lib/assetsApi';
import { getAuthToken } from '../../lib/auth';
import { assetClasses } from '../../data/assetClassesData';
import { nativeTokens } from '../../data/network';

const Overview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const token = getAuthToken();
  const showPublicPortfolios = localStorage.getItem('showPublicPortfolios');
  // const [modalShow, setModalShow] = useState(false);
  // const [modalValue, setModalValue] = useState({});
  const connected = useSelector((state) => state.wallets.connected);
  const filter = useSelector((state) => state.filter.filters);
  const myPortfolios = useSelector(
    (state) => state.portfolioExplorer.myPortfolio
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPortfolioData, setFilteredPortfolioData] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const getTopTags = (data) => {
    // Get all tags from the coins array
    const { assets } = data;
    const allTags = assets.flatMap((coin) => coin.tags);

    // Count the frequency of each tag
    const tagFrequency = allTags.reduce((map, tag) => {
      map[tag.tagName] = (map[tag.tagName] || 0) + 1;
      return map;
    }, {});

    // Sort the tags by frequency in descending order
    const sortedTags = Object.entries(tagFrequency)
      .sort((a, b) => b[1] - a[1])
      .map(([tag]) => tag);

    // Get the top 5 frequent tags
    const topTags = sortedTags.slice(0, 2);
    return topTags;
  };

  const getTopAssetClasses = (data) => {
    // Get all tags from the coins array
    const { assets } = data;
    const allClasses = assets.flatMap((coin) => coin.assetClass);

    // Count the frequency of each tag
    const classFrequency = allClasses.reduce((map, tag) => {
      map[tag] = (map[tag.assetClass] || 0) + 1;
      return map;
    }, {});

    // Sort the tags by frequency in descending order
    const sortedClasses = Object.entries(classFrequency)
      .sort((a, b) => b[1] - a[1])
      .map(([tag]) => tag);

    // Get the top 5 frequent tags
    const topClasses = sortedClasses.slice(0, 2);
    return topClasses;
  };

  const handleModalShow = () => {
    // setModalShow(true);
    // setModalValue(value);
  };

  const handleModalClose = () => {
    // setModalShow(false);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const queryLower = query.toLowerCase();
    setSearchQuery(query);
    const filtered = myPortfolios.filter(
      (data) =>
        data.name.toLowerCase().includes(queryLower) ||
        data.assets.some(
          (asset) =>
            asset.displayName.toLowerCase().includes(queryLower) ||
            asset.ticker.toLowerCase().includes(queryLower)
        )
    );
    setFilteredPortfolioData(filtered);
  };

  const handleAdd = () => {
    navigate('/assets');
  };

  const getMetamaskBalances = async (portfolioToUpdate) => {
    setShowLoading(true);
    let assetsData = [];
    let allCoins = [];

    await Moralis.EvmApi.balance
      .getNativeBalance({
        address: portfolioToUpdate.address,
        chain: portfolioToUpdate.chain,
      })
      .then(async (nativeResponse) => {
        const nativeBalance = nativeResponse.toJSON().balance;
        if (nativeBalance > 0) {
          await getNativeTokenData(portfolioToUpdate.chain)
            .then((res) => {
              if (res.length > 0) {
                const nativeTokenDetails = {
                  balance: nativeBalance,
                  decimals: 18,
                  symbol: nativeTokens[portfolioToUpdate.chain],
                  name: res[0].displayName,
                };
                assetsData.push(res[0]);
                allCoins.push(nativeTokenDetails);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          await Moralis.EvmApi.token
            .getWalletTokenBalances({
              address: portfolioToUpdate.address,
              chain: portfolioToUpdate.chain,
            })
            .then(async (response) => {
              allCoins = allCoins.concat(
                response.toJSON().filter((coin) => {
                  return !coin.possible_spam;
                })
              );
              if (allCoins.length === 0) {
                setShowLoading(false);
                dispatch(setNotificationShow(true));
                dispatch(
                  setNotification(
                    'There is no available tokens on this blockchain'
                  )
                );
              }
              const sumTotalTickers = allCoins
                .map((coin) => coin.symbol)
                .join(',');
              assetsData = await getAssets(sumTotalTickers);
              const sumTotalBalance = allCoins
                .map((coin) => {
                  const matchingObject = assetsData.find(
                    (item) => item.ticker === coin.symbol
                  );
                  const quantity = Number(coin.balance) / 10 ** coin.decimals;
                  return quantity * (matchingObject ? matchingObject.price : 0);
                })
                .reduce((total, balanceUSD) => total + balanceUSD, 0);
              const resultArray = allCoins.map((coin) => {
                const matchingObject = assetsData.find(
                  (item) => item.ticker === coin.symbol
                );
                const quantity = Number(coin.balance) / 10 ** coin.decimals;
                const amount =
                  (Number(coin.balance) / 10 ** coin.decimals) *
                  (matchingObject ? matchingObject.price : 0);
                return {
                  ticker: coin.symbol,
                  displayName: coin.name,
                  price: matchingObject ? matchingObject.price : 0,
                  price24H: matchingObject ? matchingObject.price24H : 0,
                  quantity,
                  amount,
                  percentage: (amount / sumTotalBalance) * 100,
                  tags: matchingObject ? matchingObject.tags : [],
                  assetClass: matchingObject ? matchingObject.assetClass : 0,
                  logo: matchingObject ? matchingObject.logo : coin.logo,
                };
              });
              updateConnected(resultArray, portfolioToUpdate);
            })
            .catch((err) => {
              console.log(err);
              setShowLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateConnected = async (coins, portfolioToUpdate) => {
    if (token) {
      setShowLoading(true);
      if (!checkArraysEqual(portfolioToUpdate.assets, coins)) {
        portfolioToUpdate = {
          ...portfolioToUpdate,
          assets: coins,
          count: 1,
          updated: new Date(),
        };

        const portfolioUpdated = await updatePortfolio(
          portfolioToUpdate,
          token
        );

        if (
          portfolioUpdated.status === 200 ||
          portfolioUpdated.status === 201
        ) {
          dispatch(setNotificationShow(false));
          setShowLoading(false);
        } else {
          dispatch(setNotificationShow(true));
          dispatch(setNotification(portfolioUpdated.error));
          setShowLoading(false);
        }
      }
    } else {
      navigate('/login');
    }
  };

  const fetchData = useCallback(async () => {
    const params = `limit=10&${searchQuery}`;

    if (token && token !== 'EXPIRED') {
      setShowLoading(true);
      setLoggedIn(true);
      const data = await getPortfolioList(token, params);
      if (data.status === 200) {
        data.data
          .filter((item) => item.connected && item.manual)
          .forEach((item) => {
            if (item.chain !== 'NetXdQprcVkpaWU') {
              if (checkTimeAllowance(item.updated, 24)) {
                startMoralis();
                getMetamaskBalances(item);
              } 
            }
          });
        dispatch(loadPortfolios(data.data));
        setShowLoading(false);
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(data.error));
        setShowLoading(false);
      }
    } else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    setFilteredPortfolioData(myPortfolios);
  }, [myPortfolios]);

  useEffect(() => {
    const filtered = myPortfolios.filter(
      (data) =>
        data.capital >= parseNumber(filter.minValue) &&
        data.capital <= parseNumber(filter.maxValue) &&
        data.change24H >= parseNumber(filter.minChange) &&
        data.change24H <= parseNumber(filter.maxChange) &&
        // data.price >= parseNumber(filter.minPrice) &&
        // data.price <= parseNumber(filter.maxPrice) &&
        (getTopTags(data).some((tag) =>
          filter.tags.some(
            (filterTag) => tag.toLowerCase() === filterTag.toLowerCase()
          )
        ) ||
          filter.tags.length === 0) &&
        (getTopAssetClasses(data).some((assetClass) =>
          filter.assetClasses.includes(assetClasses[assetClass])
        ) ||
          filter.assetClasses.length === 0)
    );
    setFilteredPortfolioData(filtered);
  }, [filter]);

  useEffect(() => {
    const slug = hash.replace('#', '');

    // Function to scroll to the specified section
    const scrollToSection = () => {
      const element = document.getElementById(slug);
      if (element) {
        const yOffset = -140; // adjust as per your need
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    // If hash exists, wait for the component to load and then scroll to the section
    if (slug && !showLoading) {
      const waitToScroll = setTimeout(scrollToSection, 100); // Adjust the delay as per your requirement
      return () => clearTimeout(waitToScroll); // Clear the timeout if the component unmounts before the timeout
    }
  }, [hash, showLoading]);

  useEffect(() => {
    fetchData();
  }, []);

  const tabs = [
    {
      id: 'connected',
      title: 'Connected Portfolios',
      description: '',
    },
    {
      id: 'tracked',
      title: 'Tracked Portfolios',
      description: '',
    },
    {
      id: 'planned',
      title: 'Planned Portfolios',
      description: '',
    },
    {
      id: 'social',
      title: 'Social Public Portfolios',
      description: '',
      disabled: showPublicPortfolios === null ? false : true,
    },
  ];

  return (
    <Box sx={{ paddingBottom: 10 }}>
      {loggedIn ? (
        <>
          <Toolbar
            query={searchQuery}
            onSearch={handleSearch}
            onAdd={handleAdd}
            page="overview"
          />
          {myPortfolios.length || connected ? (
            <AggregatePortfolio
              id="aggregate"
              title="Aggregate Portfolio"
              showLoading={showLoading}
              handleShowLoading={(param) => setShowLoading(param)}
              handleFetchPortfolio={() => fetchData()}
            />
          ) : (
            <Typography sx={{ color: 'white', fontSize: 28 }}>
              No portfolios yet
            </Typography>
          )}
          {filteredPortfolioData.length > 0
            ? tabs.map(
                (el) =>
                  !el.disabled && (
                    <BasicPortfolio
                      key={el.id}
                      id={el.id}
                      title={el.title}
                      expand={el.id === 'social' ? false : true}
                      data={filteredPortfolioData}
                      showLoading={showLoading}
                      handleModalShow={(id, value) =>
                        handleModalShow(id, value)}
                      handleModalClose={() => handleModalClose()}
                      handleFetchPortfolio={() => fetchData()}
                      handleShowLoading={(param) => setShowLoading(param)}
                    />
                  )
              )
            : null}
        </>
      ) : (
        <Box>
          <Typography sx={{ color: 'white', fontSize: 28, mb: 2 }}>
            You need to Log In to see your portfolios
          </Typography>
          <MButton width="160px" onClick={() => navigate('/login')}>
            Login
          </MButton>
        </Box>
      )}
    </Box>
  );
};

export default Overview;
