import React, { useState } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import SearchBar from '../SearchBar';
// import Filter from '../Filter';
import AssetSuggestion from '../AssetSuggestion';
// import FilterButton from '../Filter/FilterButton';
import MButton from '../../Common/MButton';
import { StyledBadge, StyledStack } from './styles';

const Toolbar = ({
  query,
  onSearch,
  onAdd,
  badgeCount,
  sendSuggestion,
  showSuggestion,
  // page,
}) => {
  const isMobile = useMediaQuery((thm) => thm.breakpoints.down('md'));
  // eslint-disable-next-line no-unused-vars
  const [showFilter, setShowFilter] = useState(false);

  // const handleShowFilter = () => {
  //   setShowFilter(!showFilter);
  // };

  return (
    <StyledStack direction="row">
      <Stack
        direction="row"
        alignItems="flex-start"
        gap={3}
        width={showFilter ? '100%' : 'auto'}
      >
        <SearchBar query={query} onChange={onSearch} />
        {/* {showFilter ? (
          <Filter onHideFilter={handleShowFilter} page={page} />
        ) : (
          <FilterButton onClick={handleShowFilter} show={showFilter} />
        )} */}
        {showSuggestion && !isMobile && (
          <AssetSuggestion onClick={sendSuggestion} />
        )}
      </Stack>
      <MButton green="true" onClick={onAdd}>
        Add Portfolio
        {badgeCount > 0 ? (
          <StyledBadge badgeContent={badgeCount} color="primary" />
        ) : null}
      </MButton>
    </StyledStack>
  );
};

export default Toolbar;
