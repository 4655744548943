import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

export const PieChartWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  
  .percentage {
    font-size: 10px;
    font-weight: 600;
  }

  .label {
    font-size: 12px;
    font-weight: 700;
    &__border {
      -webkit-filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.5));
      filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.5));
    }
  }

  svg {
    max-width: 100%;
  }
`;

export const StyledTooltipContainer = MuiStyled(Box)(({ theme }) => ({
  background: theme.palette.white,
  padding: 4,
}));

export const StyledTooltipLabel = MuiStyled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.primary.main,
}));
