// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import { timezoneList } from '../data/timezoneData';

export const parseDateXAxis = (date, duration, timezone) => {
  let userTimezone = moment.tz.guess();
  if (timezone) {
    const timezoneData = timezoneList.find((el) => el.name === timezone);
    userTimezone = timezoneData.timezone;
  }
  const timezoneOffset = moment().tz(userTimezone).utcOffset();
  const browserCurrentTime = moment.utc(date).utcOffset(timezoneOffset);
  let formattedDate = browserCurrentTime.format('MMM D');
  if (duration === '24H') {
    formattedDate = browserCurrentTime.format('h:mm A');
  }
  return formattedDate;
};

export const parseChartDateLocale = (dateTime, timezone, time) => {
  let userTimezone = moment.tz.guess();
  if (timezone) {
    const timezoneData = timezoneList.find((el) => el.name === timezone);
    userTimezone = timezoneData.timezone;
  }
  const timezoneOffset = moment().tz(userTimezone).utcOffset();
  const browserCurrentTime = moment.utc(dateTime).utcOffset(timezoneOffset);
  const timeFormat = time ? 'HH:mm' : 'D.MM.YY - HH:mm';
  const formattedDateTime = browserCurrentTime.format(timeFormat);
  return formattedDateTime;
};

export const getSelectedTimezone = (timezone) => {
  let userTimezone = moment.tz.guess();
  if (timezone) {
    const timezoneData = timezoneList.find((el) => el.name === timezone);
    userTimezone = timezoneData.timezone;
  }
  return userTimezone;
};
