import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AssetSuggestionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.white,
  padding: 10,
  height: 43,
  borderRadius: 14
}));
  
export const AssetSuggestionFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.white,
  borderRadius: 14,
  height: 43,
  width: 150,
  marginRight: '10px',
    
  '& .MuiInputBase-input': {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));
  
