import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { StyledBackdrop } from './styles';

const Loading = ({ open, text }) => {
  return (
    <StyledBackdrop open={open}>
      <Typography>{text}</Typography>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
};

export default Loading;
