import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CDropdown from '../../CDropdown';
import {
  addPortfolioCoin,
  removePortfolioCoins,
} from '../../../../reducers/portfolioReducer';

const PortfolioSelect = ({ data, onSelect }) => {
  const dispatch = useDispatch();
  const [currentPortfolio, setCurrentPortfolio] = useState('');

  const portfolio = useSelector((state) => state.portfolio.list);
  const portfolios = useSelector((state) => state.portfolioExplorer);

  const handleChange = (event) => {
    const timestamp = new Date().getTime();
    const selected = event.target.value;
    setCurrentPortfolio(selected);
    portfolio.map(
      (item) => !item.selected && dispatch(removePortfolioCoins(item.uuid))
    );
    const selectedPortfolio = portfolios.myPortfolio.find(
      (value) => value.id === selected
    );
    if (selectedPortfolio) {
      Promise.all(
        selectedPortfolio.assets.map((el) => {
          const updatedCoin = {
            ...el,
            uuid: el.id + timestamp,
            percentage: el.new_percentage || el.percentage,
            quantity: el.quantity,
            amount: el.new_amount || el.amount,
            oldPercentage: el.new_percentage || el.percentage,
          };
          return dispatch(addPortfolioCoin([updatedCoin]));
        })
      )
        .then(() => {
          onSelect(
            selected,
            selectedPortfolio.tracked,
            selectedPortfolio.live,
            selectedPortfolio.new_capital || selectedPortfolio.capital
          );
        })
        .catch((error) => {
          console.error('Error occurred during dispatch:', error);
        });
    }
  };

  return (
    <CDropdown
      label="Select Portfolio"
      data={data}
      value={currentPortfolio}
      handleChange={handleChange}
    />
  );
};

export default PortfolioSelect;
