import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonWrapper = styled(Button)(({ theme, ...props }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: props.size === 'sm' ? '18px' : '20px',
  background: props.bgcolor ? props.bgcolor : theme.palette.white,
  border: `${props.border ? '3px solid' : ''} ${theme.palette.primary.main}`,
  borderRadius: '14px',
  padding: '8px 16px',
  height: props.size === 'sm' ? '43px' : '59px',
  width: props.width !== 'fit' ? props.width : 'fit-content',
  minWidth: props.width !== 'fit' ? props.width : 0,
  color: props.textcolor ? props.textcolor : theme.palette.primary.main,
  transition: 'all ease-in-out .3s',
  '&:hover': {
    background: props.bgcolor ? props.bgcolor : theme.palette.white,
    opacity: 0.8,
  },
}));

export const ButtonText = styled(Typography)(({ theme, ...props }) => ({
  fontSize: 18,
  fontWeight: 700,
  textTransform: props.transform ? props.transform : 'capitalize',
  background: 'transparent',

  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
}));
