import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import { timezoneList } from '../data/timezoneData';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme } from '@mui/material/styles';
import { Edit } from '@mui/icons-material';
import { Stack, IconButton, CircularProgress } from '@mui/material';
import {
  setNotificationShow,
  setNotification,
} from '../../../../reducers/globalReducer';
import {
  parseChartDateLocale,
  getSelectedTimezone,
} from '../../../../utils/parseDate';
import Paragraph from '../../../../components/Common/Paragraph';
import { updatePortfolio } from '../../../../lib/assetsApi';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomDatePicker = ({ timeZ, portfolioData, handleFetchPortfolio }) => {
  // eslint-disable-next-line camelcase
  const { created_at, updatedStartDate } = portfolioData;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);
  const [pickerDate, setPickerDate] = useState(new Date());
  const [dateOneYearAgo, setDateOneYearAgo] = useState(new Date());
  const [value, setValue] = useState(
    // eslint-disable-next-line camelcase
    updatedStartDate ? updatedStartDate : created_at
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [hoverDate, setHoverDate] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });

  const handleAccept = async (val) => {
    setIsUpdating(true);
    const portfolioToUpdate = portfolioData;
    const newDate = val.$d;
    const unFormatDate = moment.utc(newDate).utcOffset(0);
    const isoString = unFormatDate.toISOString();
    portfolioToUpdate.updatedStartDate = isoString;
    const updatedPortfolio = await updatePortfolio(portfolioToUpdate, token);

    if (updatedPortfolio.status === 200 || updatedPortfolio.status === 201) {
      handleFetchPortfolio();
      setIsUpdating(false);
      setValue(isoString);
    } else {
      setIsUpdating(false);
      dispatch(setNotificationShow(true));
      dispatch(setNotification(updatedPortfolio.error));
    }
  };

  const handleOpenPicker = () => {
    setOpen(true);
  };

  const handleClosePicker = () => {
    setOpen(false);
    setPickerPosition({ top: 0, left: 0 });
  };

  const handleChangeDatePicker = () => {
    // eslint-disable-next-line no-restricted-globals
    setPickerDate(new Date());
    setOpen(true);
  };

  useEffect(() => {
    // Calculate the date one year ago
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    setDateOneYearAgo(currentDate);
  }, []);

  return (
    <Stack
      onMouseEnter={() => setHoverDate(true)}
      onMouseLeave={() => setHoverDate(false)}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',

        '.MuiStack-root': {
          paddingTop: 0,
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isUpdating ? (
              <CircularProgress color="white" size={36} />
            ) : (
              <Paragraph
                fontsize="12px"
                fontweight={500}
                className="text-center"
                style={{
                  lineHeight: '36px',
                }}
              >
                Tracking start date: {parseChartDateLocale(value, timeZ, false)}
              </Paragraph>
            )}
            {hoverDate ? (
              <IconButton
                onClick={(e) => handleOpenPicker(e)}
                style={{
                  marginTop: 0,
                  visibility: hoverDate ? 'visible' : 'hidden',
                }}
              >
                <Edit
                  fontSize="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            ) : null}
            {pickerPosition && (
              <div
                style={{
                  position: 'absolute',
                  visibility: 'hidden',
                  ...pickerPosition,
                }}
              >
                <DateTimePicker
                  label="Tracking start date"
                  value={dayjs(value)}
                  onChange={(e) => handleChangeDatePicker(e)}
                  open={open}
                  onClose={handleClosePicker}
                  renderInput={() => null}
                  maxDateTime={dayjs(pickerDate)}
                  minDateTime={dayjs(dateOneYearAgo)}
                  disableFuture
                  closeOnSelect={false}
                  onAccept={(val) => handleAccept(val)}
                  timezone={getSelectedTimezone(timeZ)}
                  ampm={false}
                />
              </div>
            )}
          </div>
        </DemoContainer>
      </LocalizationProvider>
    </Stack>
  );
};

export default CustomDatePicker;
