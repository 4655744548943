import { styled } from '@mui/material/styles';
import { AppBar, Container, Toolbar } from '@mui/material';

export const StyledAppBar = styled(AppBar)(() => ({
  background: 'white',
}));

export const StyledContainer = styled(Container)(() => ({
  maxWidth: 1440,
}));

export const StyledToolbar = styled(Toolbar)(() => ({
  height: 70,
  margin: '0px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
