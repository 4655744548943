import React from 'react';
import { useTheme } from '@mui/material/styles';

const ColorIndicator = ({ number, dark }) => {
  const theme = useTheme();

  const validatedNumber = number === -1 || Number.isNaN(number) ? 'N/A' : number;
  // Calculate the color based on the number
  const getColor = (num) => {
    const colorScale = [
      '#00FF00', // Green
      '#22FF00',
      '#44FF00',
      '#66FF00',
      '#88FF00',
      '#AAFF00',
      '#CCFF00',
      '#EEFF00',
      '#FFFF00',
      '#FFEE00',
      '#FFCC00',
      '#FFAA00',
      '#FF8800',
      '#FF6600',
      '#FF4400',
      '#FF2200',
      '#FF0000', // Red
    ];

    // Ensure number is within the range 1 to 10
    const clampedNumber = Math.max(1, Math.min(10, num));

    // Calculate the index in the color scale
    const colorIndex = Math.floor(
      ((clampedNumber - 1) / 10) * (colorScale.length - 1)
    );

    // Return the corresponding color
    return colorScale[colorIndex];
  };

  const backgroundColor = getColor(validatedNumber);

  return (
    <span
      style={{
        width: '25px',
        height: '25px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        fontWeight: 'bold',
        background: backgroundColor,
        color:
          number !== -1 || dark
            ? theme.palette.primary.main
            : theme.palette.white,
      }}
    >
      {validatedNumber}
    </span>
  );
};

export default ColorIndicator;
