import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import { Box, Stack, FormControl, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { validateAddress } from '@taquito/utils';
import { TezosToolkit } from '@taquito/taquito';
import Moralis from 'moralis';
import ChainSelect from './ChainSelect';
import SearchBar from '../../components/Base/SearchBar';
import MButton from '../../components/Common/MButton';
import Loading from '../../components/Loading';
import { addPortfolio } from '../../reducers/portfolioExplorerReducer';
import {
  setNotification,
  setNotificationShow,
  setNotificationType,
} from '../../reducers/globalReducer';
import { setActiveChain } from '../../reducers/walletsReducer';
import { createPortfolio } from '../../lib/assetsApi';
import { getAuthToken } from '../../lib/auth';
import { nativeTokens } from '../../data/network';
import { StyledWhiteTypo, StyledFormLabel } from './styles';
import {
  startMoralis,
  getAssets,
  getNativeTokenData,
} from '../../utils/blockchain';

const PortfolioCreation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const token = getAuthToken();

  const [publicAddress, setPublicAddress] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isTezos, setIsTezos] = useState(false);
  const [validAddress, setValidAddress] = useState(false);

  const activeChain = useSelector((state) => state.wallets.activeChain);
  const currency = useSelector((state) => state.currency);
  const myPortfolios = useSelector(
    (state) => state.portfolioExplorer.myPortfolio
  );

  const isValidAddress = (adr) => {
    const isTezosValid = validateAddress(adr);

    if (isTezosValid === 3) {
      setIsTezos(true);
      setValidAddress(true);
    } else {
      setIsTezos(false);
      try {
        const web3 = new Web3();
        web3.utils.toChecksumAddress(adr);
        setValidAddress(true);
      } catch (e) {
        setValidAddress(false);
      }
    }
  };

  const getMetamaskBalances = async () => {
    setShowLoading(true);
    let assetsData = [];
    let allCoins = [];

    await Moralis.EvmApi.balance
      .getNativeBalance({
        address: publicAddress,
        chain: activeChain,
      })
      .then(async (nativeResponse) => {
        const nativeBalance = nativeResponse.toJSON().balance;
        if (nativeBalance > 0) {
          await getNativeTokenData(activeChain)
            .then((res) => {
              if (res.length > 0) {
                const nativeTokenDetails = {
                  balance: nativeBalance,
                  decimals: 18,
                  symbol: nativeTokens[activeChain],
                  name: res[0].displayName,
                };
                assetsData.push(res[0]);
                allCoins.push(nativeTokenDetails);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        await Moralis.EvmApi.token
          .getWalletTokenBalances({
            address: publicAddress,
            chain: activeChain,
          })
          .then(async (response) => {
            allCoins = allCoins.concat(
              response.toJSON().filter((coin) => {
                return !coin.possible_spam;
              })
            );
            if (allCoins.length === 0) {
              setShowLoading(false);
              setIsFailed(true);
            }
            const sumTotalTickers = allCoins
              .map((coin) => coin.symbol)
              .join(',');
            assetsData = await getAssets(sumTotalTickers);
            const sumTotalBalance = allCoins
              .map((coin) => {
                const matchingObject = assetsData.find(
                  (item) => item.ticker === coin.symbol
                );
                const quantity = Number(coin.balance) / 10 ** coin.decimals;
                return quantity * (matchingObject ? matchingObject.price : 0);
              })
              .reduce((total, balanceUSD) => total + balanceUSD, 0);
            const resultArray = allCoins.map((coin) => {
              const matchingObject = assetsData.find(
                (item) => item.ticker === coin.symbol
              );
              const quantity = Number(coin.balance) / 10 ** coin.decimals;
              const amount =
                (Number(coin.balance) / 10 ** coin.decimals) *
                (matchingObject ? matchingObject.price : 0);
              return {
                ticker: coin.symbol,
                displayName: coin.name,
                price: matchingObject ? matchingObject.price : 0,
                price24H: matchingObject ? matchingObject.price24H : 0,
                quantity,
                amount,
                percentage: (amount / sumTotalBalance) * 100,
                tags: matchingObject ? matchingObject.tags : [],
                assetClass: matchingObject ? matchingObject.assetClass : 0,
                logo: matchingObject ? matchingObject.logo : coin.logo,
              };
            });
            saveConnectedAssets(
              publicAddress,
              activeChain,
              sumTotalBalance,
              resultArray,
              myPortfolios
            );
          })
          .catch((err) => {
            console.log(err);
            setIsFailed(true);
            setShowLoading(false);
          });
      })
      .catch((err) => {
        setIsFailed(true);
        setShowLoading(false);
        console.log(err);
      });
  };

  const createConnected = async (portfolio) => {
    if (token) {
      const portfolioCreated = await createPortfolio(portfolio, token);
      if (portfolioCreated.status === 200 || portfolioCreated.status === 201) {
        dispatch(setNotificationShow(true));
        dispatch(setNotificationType('success'));
        dispatch(
          setNotification('Connected portfolio is created successfully')
        );
        dispatch(addPortfolio(portfolioCreated.data));
        setShowLoading(false);

        setTimeout(() => {
          navigate('/overview#connected');
        }, 1000);
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(portfolioCreated.message));
        setShowLoading(false);
      }
    } else {
      setShowLoading(false);
      navigate('/login');
    }
  };

  const saveConnectedAssets = useCallback(
    async (address, chain, capital, coins, portfolios) => {
      if (coins.length > 0) {
        const findConnected = portfolios.find(
          (el) => el.name === `${address}(${chain})`
        );
        if (findConnected) {
          dispatch(setNotificationShow(true));
          dispatch(
            setNotification(
              'You already have same account. Please update it on overview page'
            )
          );
          setShowLoading(false);
        } else {
          const portfolio = {
            title: `${address}(${chain})`,
            address,
            chain,
            category: [0],
            coins,
            capital,
            live: false,
            tracked: true,
            connected: true,
            manual: true,
            currency,
            updated: new Date(),
            count: 0,
          };

          await createConnected(portfolio);
        }
      }
    },
    []
  );

  const handleImportAddress = (event) => {
    const query = event.target.value;
    isValidAddress(query);
    setPublicAddress(query);
  };

  const getTezosBalances = () => {
    let assetsData = [];
    const allCoins = [];
    const Tezos = new TezosToolkit('https://mainnet.ecadinfra.com');
    setShowLoading(true);
    if (activeChain === 'NetXdQprcVkpaWU') {
      Tezos.tz
        .getBalance(publicAddress)
        .then(async (balance) => {
          const tezosDetails = {
            balance: balance.toNumber(),
            decimals: 6,
            symbol: 'XTZ',
            name: 'Tezos',
          };
          allCoins.push(tezosDetails);

          assetsData = await getAssets('XTZ');

          const sumTotalBalance = allCoins
            .map((coin) => {
              const matchingObject = assetsData.find(
                (item) => item.ticker === coin.symbol
              );
              const quantity = Number(coin.balance) / 10 ** coin.decimals;
              return quantity * (matchingObject ? matchingObject.price : 0);
            })
            .reduce((total, balanceUSD) => total + balanceUSD, 0);

          const resultArray = allCoins.map((coin) => {
            const matchingObject = assetsData.find(
              (item) => item.ticker === coin.symbol
            );
            const quantity = Number(coin.balance) / 10 ** coin.decimals;
            const amount =
              (Number(coin.balance) / 10 ** coin.decimals) *
              (matchingObject ? matchingObject.price : 0);
            return {
              ticker: coin.symbol,
              displayName: coin.name,
              price: matchingObject ? matchingObject.price : 0,
              price24H: matchingObject ? matchingObject.price24H : 0,
              quantity,
              amount,
              percentage: (amount / sumTotalBalance) * 100,
              tags: matchingObject ? matchingObject.tags : [],
              assetClass: matchingObject ? matchingObject.assetClass : 0,
              logo: matchingObject ? matchingObject.logo : coin.logo,
            };
          });

          saveConnectedAssets(
            publicAddress,
            activeChain,
            sumTotalBalance,
            resultArray,
            myPortfolios
          );
          setShowLoading(false);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          setShowLoading(false);
        });
    } else {
      setIsFailed(true);
      setShowLoading(false);
    }
  };

  const handleConnectWallet = () => {
    if (isTezos) {
      getTezosBalances();
    } else {
      startMoralis();
      getMetamaskBalances();
    }
  };

  useEffect(() => {
    setIsFailed(false);
  }, [activeChain]);

  useEffect(() => {
    return () => {
      dispatch(setActiveChain(''));
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        maxWidth: 768,
        margin: 'auto',
      }}
    >
      <Stack direction="column" gap={3}>
        <Box>
          <StyledWhiteTypo fontSize={32} fontWeight={700}>
            Public address
          </StyledWhiteTypo>
          <StyledWhiteTypo>
            Your public address allows us to read your wallet activity and
            accurately sync your crypto transactions with our app.
          </StyledWhiteTypo>
        </Box>
        <FormControl>
          <StyledFormLabel>Address</StyledFormLabel>
          <SearchBar
            query={publicAddress}
            placeholder="0xEIJAsdf3..."
            onChange={handleImportAddress}
            width="full"
          />
        </FormControl>
        {publicAddress === '' ? null : !validAddress ? (
          <StyledWhiteTypo>
            We couldn&apos;t verify the validity of this address. Please check
            the value you have entered and try again.
          </StyledWhiteTypo>
        ) : null}
        <ChainSelect
          chain={activeChain}
          error={isFailed}
          disabled={publicAddress !== '' && !validAddress}
        />
        {isFailed ? (
          <Typography
            fontSize={18}
            fontWeight={500}
            sx={{ color: theme.palette.error.main }}
          >
            There is no available tokens on this blockchain
          </Typography>
        ) : null}
        <MButton
          width="100%"
          disabled={publicAddress === '' || !validAddress || activeChain === ''}
          onClick={handleConnectWallet}
        >
          Scan
        </MButton>
      </Stack>
      <Loading open={showLoading} />
    </Box>
  );
};

export default PortfolioCreation;
