import * as yup from 'yup';

const validationsForm = yup.object({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  timezone: yup
    .string()
    .required('Timezone is required'),
});

export default validationsForm;
