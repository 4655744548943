import React, { useState, useRef, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import {
  PieChartWrapp,
  StyledTooltipContainer,
  StyledTooltipLabel,
} from './styles';

// const COLORS = [
//   '#ED9C00',
//   '#6930C2',
//   '#005E73',
//   '#C96603',
//   '#548FD9',
//   '#0A9496',
//   '#47BFE3',
//   '#BA3D03',
//   '#94D1BD',
//   '#AD2112',
//   '#63DEDE',
//   '#94D1BD',
//   '#9C2126',
//   '#80FFDB',
//   '#00121A',
//   '#E8D9A6',
// ];

const CONTRASTING_COLORS = [
  // '#FF6B6B',
  '#FFAA73',
  '#FFD369',
  '#A85CE0',
  '#FFEC7B',
  '#A5D8FF',
  '#E0E0E0',
  '#D1A4E0',
  '#7F7CE3',
  '#B5D8A5',
  '#FF8C75',
  '#FFC542',
  '#FF7245',
  '#FFEE6B',
  '#B9D4DB',
  '#A5D8FF',
];

const RADIAN = Math.PI / 180;
const RenderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  payload,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const ref = useRef(null);
  const [elWidth, setElWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const bbox = ref.current.getBBox();
      const widthEl = bbox.width;
      setElWidth(widthEl + 10);
    }
  }, [payload]);

  return percent > 0.03 ? (
    <g fill="white">
      <line
        x1={cx}
        y1={cy}
        x2={x}
        y2={y}
        stroke="#000"
        strokeWidth={1}
        strokeDasharray="3 3"
      />
      <rect
        x={x - elWidth / 2}
        y={y - 10}
        rx="5"
        width={elWidth}
        height="20"
        fill="#fff"
        style={{
          strokeWidth: 1,
          stroke: CONTRASTING_COLORS[index % CONTRASTING_COLORS.length],
        }}
        className="label__border"
      />
      <text
        x={x}
        y={y}
        fill="#251A60"
        textAnchor="middle"
        dominantBaseline="central"
        className="label"
        id={`label__${payload.id}`}
        ref={ref}
      >
        {payload.ticker?.toUpperCase()}
      </text>
    </g>
  ) : null;
};

const RenderCustomizedLabel2 = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const percentRef = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (percentRef.current) {
      const pbox = percentRef.current.getBBox();
      const widthL = pbox.width;
      setLabelWidth(widthL + 5);
    }
  }, [percent]);

  return percent > 0.03 ? (
    <g fill="white">
      <rect
        x={x - labelWidth / 2}
        y={y - 10}
        rx="5"
        width={labelWidth}
        height="20"
        fill="#fff"
      />
      <text
        x={x}
        y={y}
        fill="#251A60"
        textAnchor="middle"
        dominantBaseline="central"
        className="percentage"
        ref={percentRef}
      >
        {`${Number((percent * 100).toFixed(1))}%`}
      </text>
    </g>
  ) : null;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltipContainer sx={{ background: 'white', padding: 0.5 }}>
        <StyledTooltipLabel sx={{ fontSize: 12, fontWeight: 500 }}>
          {payload[0].payload.displayName}
        </StyledTooltipLabel>
        <StyledTooltipLabel sx={{ fontSize: 12, fontWeight: 500 }}>
          {payload[0].payload.new_percentage.toFixed(2)}%
        </StyledTooltipLabel>
      </StyledTooltipContainer>
    );
  }
  return null;
};

const PieChartEl = ({ data, width = 300, height = 300 }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [pieData, setPieData] = useState(data);

  useEffect(() => {
    const newData = data.map((item) => {
      return {
        ...item,
        new_percentage: item.new_percentage || item.percentage,
      };
    });
    setPieData(newData);
  }, [data]);

  const onPieEnter = (_data, index) => {
    setActiveIndex(index);
  };

  const onPieOut = () => {
    setActiveIndex(null);
  };

  return (
    <PieChartWrapp>
      <PieChart width={width} height={height}>
        <Tooltip content={<CustomTooltip />} />
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={<RenderCustomizedLabel />}
          outerRadius={width / 3.5}
          fill="#8884d8"
          dataKey="new_percentage"
          isAnimationActive={false}
          onMouseEnter={onPieEnter}
          onMouseOut={onPieOut}
        >
          {pieData.map((entry, index) => (
            <Cell
              key={entry.ticker}
              fill={CONTRASTING_COLORS[index % CONTRASTING_COLORS.length]}
              stroke={
                index === activeIndex
                  ? CONTRASTING_COLORS[activeIndex % CONTRASTING_COLORS.length]
                  : 'none'
              }
              strokeWidth={index === activeIndex ? 3 : 0}
            />
          ))}
        </Pie>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={<RenderCustomizedLabel2 />}
          outerRadius={width / 3.5}
          fill="#8884d8"
          dataKey="new_percentage"
          isAnimationActive={false}
          onMouseEnter={onPieEnter}
          onMouseOut={onPieOut}
        >
          {pieData.map((entry, index) => (
            <Cell
              key={entry.ticker}
              fill={CONTRASTING_COLORS[index % CONTRASTING_COLORS.length]}
              stroke={
                index === activeIndex
                  ? CONTRASTING_COLORS[activeIndex % CONTRASTING_COLORS.length]
                  : 'none'
              }
              strokeWidth={index === activeIndex ? 3 : 0}
            />
          ))}
        </Pie>
      </PieChart>
    </PieChartWrapp>
  );
};

export default PieChartEl;
