import { Badge, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBadge = styled(Badge)(() => ({
  width: 16,
  '& .MuiBadge-badge': {
    top: -8,
    right: 4,
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: 70,
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: 12,
  marginBottom: 20,
  paddingTop: 12,
  paddingBottom: 12,
  zIndex: 10,
  backgroundColor: theme.palette.primary.main,
}));
