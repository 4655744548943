import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from '@mui/icons-material';
import { StyledIconButton } from './styles';

const TablePagination = ({
  onPageExpand,
  isExpanded,
  expandable = true,
  ...props
}) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handlePageExpand = () => {
    onPageExpand();
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <StyledIconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </StyledIconButton>
      <StyledIconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </StyledIconButton>
      {expandable && (
        <StyledIconButton onClick={handlePageExpand} aria-label="expand table">
          {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </StyledIconButton>
      )}
    </Box>
  );
};

export default TablePagination;
