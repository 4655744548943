import Moralis from 'moralis';
import { getAssetsByTicker } from '../lib/assetsApi';
import { nativeTokens } from '../data/network';

export const getCedeBalance = async (provider, id, names) => {
  const balance = await provider.request({
    method: 'balances',
    params: {
      vaultId: id,
      accountNames: names,
    },
  });
  return balance;
};

export const getCedeTransaction = async (provider, id, names) => {
  const transaction = await provider.request({
    method: 'transactions',
    params: {
      vaultId: id,
      accountNames: names,
    },
  });
  return transaction;
};

export const getPrices = async (provider) => {
  const prices = await provider.request({
    method: 'getPrices',
  });
  return prices;
};

export const startMoralis = async () => {
  const isStarted = Moralis.Core?.isStarted;

  if (!isStarted) {
    await Moralis.start({
      apiKey: process.env.REACT_APP_MORALIS_API_KEY,
    }).catch((err) => {
      console.log(err);
    });
  }
};

export const getAssets = async (ticker) => {
  if (ticker !== '') {
    try {
      const data = await getAssetsByTicker(ticker);
      if (data.status === 200) {
        return data.data;
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const getNativeTokenData = async (chain) => {
  const activeNativeTokenTicker = nativeTokens[chain];
  const result = await getAssets(activeNativeTokenTicker);
  return result;
};

// const getEvmPrice = async (symbol, tokenAddress) => {
//   try {
//     const response = await Moralis.EvmApi.token.getTokenPrice({
//       chain: activeChain,
//       include: 'percent_change',
//       address: tokenAddress,
//     });

//     return response.toJSON();
//   } catch (err) {
//     // console.log(err);
//     const tokenData = await getAssets(symbol);
//     return {
//       tokenSymbol: symbol,
//       usdPrice: tokenData[0].price,
//       '24hrPercentChange': tokenData[0].price24H,
//       tokenLogo: tokenData[0].logo,
//     };
//   }
// };
