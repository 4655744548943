import { TableRow, Checkbox, Input } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.existing': {
    backgroundColor: theme.palette.primary.main,
    '& .MuiInputBase-root': {
      '&:before': {
        borderColor: 'rgba(255, 255, 255, 0.42)'
      },
      '& .MuiInputBase-input': {
        color: 'rgba(255, 255, 255, 0.87)'
      },
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'rgba(255, 255, 255, 0.38)'
      },
      '& .MuiInputAdornment-root .MuiTypography-root': {
        color: 'rgba(255, 255, 255, 0.38)',
      }
    },
    '& .MuiTableCell-root, .MuiSvgIcon-root': {
      color: theme.palette.white,
    },
  },
  '&.new': {
    backgroundColor: theme.palette.white,
    '& .MuiInputBase-root, .MuiTableCell-root, .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputAdornment-root .MuiTypography-root': {
      color: theme.palette.primary.main,
    }
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  borderRadius: 6,
  width: 32,
  height: 32,
  background: theme.palette.white,
  
  '&:hover': {
    background: theme.palette.white,
  }
}));
  
export const StyledNumberField = styled(Input)(() => ({
  // fontSize: 18,
  // fontWeight: 500,
  
  // '& .MuiInputAdornment-root .MuiTypography-root': {
  //   fontSize: 18,
  //   fontWeight: 500,
  // }
}));
  
