import { Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputLabel-root, .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.white,
  },
  '& .MuiInputBase-root': {
    color: theme.palette.white,
  },
  '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
    color: theme.palette.white,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.white,
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.white,
  },
  '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.white,
  },
}));
