import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem, Grid } from '@mui/material';
import { setCurrency } from '../../../reducers/currencyReducer';
import {
  setNotificationShow,
  setNotification,
} from '../../../reducers/globalReducer';

const CurrencySelect = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);

  const currencyList = ['USD', 'EUR', 'CHF', 'GBP', 'JPY', 'CNY', 'INR', 'AED'];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue) => {
    if (newValue === 'USD') dispatch(setCurrency(newValue));
    else {
      dispatch(setNotificationShow(true));
      dispatch(setNotification(`${newValue} is not available now`));
    }
  };

  return (
    <>
      <Button
        id="currency-button"
        aria-controls={open ? 'currency-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ fontSize: 18, fontWeight: 700 }}
        onClick={handleClick}
      >
        {currency}
      </Button>
      <Menu
        id="currency-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'currency-button',
        }}
      >
        <Grid container sx={{ width: 150, px: 1 }}>
          {currencyList.map((item) => (
            <Grid item xs={12} sm={6} key={item}>
              <MenuItem value={item} onClick={() => handleChange(item)}>
                {item}
              </MenuItem>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </>
  );
};

export default CurrencySelect;
