export const checkTimeAllowance = (date, hours) => {
  const providedTimestamp = new Date(date);
  const currentTime = new Date();
  const timestampPlusOneHour = new Date(
    providedTimestamp.getTime() + hours * 3600 * 1000
  );
  if (currentTime > timestampPlusOneHour) {
    return true;
  }
  return false;
};
