import { createTheme } from '@mui/material';

const getTheme = () => {
  return createTheme({
    direction: 'ltr',
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
    palette: {
      white: '#FFFFFF',
      primary: {
        main: '#251A60',
        opacity: '#251A60CC',
      },
      secondary: {
        main: '#00F0AC',
      },
      success: {
        main: '#67EBAF',
      },
      warning: {
        main: '#EB67A3',
      },
      error: {
        main: '#fc6868',
      },
      info: {
        main: '#D9D9D9',
      },
      blue: '#166FFA',
      darkBlue: '#0B4EB5',
      lightBlue: '#F6F7FE',
      darkGrey: '#8492A8',
      lightGrey: '#D1DAE9',
      lightGrey2: '#D1D9E8',
      lightGrey3: '#CCCCCC',
      lightGrey4: '#D9D9D9',
      lightGrey5: '#E7E7E7',
      lightGrey6: '#F7F7F7',
      black: '#41424B',
      black2: '#1D1D1B',
      lightPurple: '#CDC6E0',
      darkPurple: '#8A81AC',
      secondPurple: '#584E86',
    },
    components: {},
  });
};

export default getTheme;
