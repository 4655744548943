import React from 'react';
import { styled } from '@mui/material/styles';
import getTheme from '../../../theme';

const theme = getTheme();

const Wrapper = styled('h1')`
  font-weight: 700;
  font-size: ${(props) => (props.small ? '18px' : '24px')};
  text-align: left;
  color: ${(props) => (props.color ? props.color : theme.palette.primary.main)};
  position: relative;
  margin: 0;

  &:after {
    content: '';
    display: ${(props) => (props.noborder ? 'none' : 'block')};
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 5px;
    width: 50px;
    background-color: #00f0a9;
  }

  @media (min-width: 992px) {
  }
`;

const H1 = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

export default H1;
