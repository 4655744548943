import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Box,
  Container,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  useTheme,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import H1 from '../../../components/Common/Titles/h1';
import H3 from '../../../components/Common/Titles/h3';
import Input from '../../../components/Common/Form/Input';
import MButton from '../../../components/Common/MButton';
import Paragraph from '../../../components/Common/Paragraph';
import Loading from '../../../components/Loading';
// import Form from './Form';

import {
  createUser,
  // submitFormHubspot,
  addNewEmailHubspot,
} from '../../../lib/userApi';
import {
  setNotification,
  setNotificationShow,
  setNotificationType,
} from '../../../reducers/globalReducer';
import validationSchema from './validationSchema';

const Signup = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [success, setSuccess] = useState(false);
  // const [firstForm, setFirstForm] = useState(true);
  // const [currentHubspotData, setCurrentHubspotData] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: '',
      firstname: '',
      surname: '',
      username: '',
      password: '',
      confirmPassword: '',
      privacy: false,
      newsletter: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const user = await createUser(values);

      if (user.status === 201 || user.status === 204) {
        // if (currentHubspotData) {
        //   await submitFormHubspot(values.email, currentHubspotData);
        // }
        if (values.newsletter) {
          await addNewEmailHubspot(values.email);
        }
        dispatch(setNotificationShow(true));
        dispatch(setNotificationType('success'));
        dispatch(setNotification('Success!'));
        setSuccess(true);
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(user.error));
      }

      setSubmitting(false);
    },
  });

  // const handleCurrentData = async (data) => {
  //   if (Object.keys(data).length) {
  //     setCurrentHubspotData(data);
  //     setFirstForm(false);
  //   }
  // };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', pt: '40px', pb: '40px' }}
        columnSpacing={2}
      >
        <Grid item sm={7}>
          {success ? (
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex-column"
              sx={{ mb: 4 }}
              textAlign="center"
            >
              <Box justifyContent="center" display="flex" sx={{ mb: 4 }}>
                <H1 color="#fff" noborder>
                  Register
                </H1>
              </Box>
              <H3 color="#388e3c" noborder>
                <CheckCircleOutlineIcon />
                You have successfully created a new account!
              </H3>
              <Paragraph color="#fff">
                Please confirm your E-Mail address.
              </Paragraph>
            </Box>
          ) : (
            // : firstForm ? (
            //   <Form handleCurrentData={handleCurrentData} />
            // )
            <form onSubmit={formik.handleSubmit}>
              <Box justifyContent="center" display="flex" sx={{ mb: 4 }}>
                <H1 color="#fff" noborder>
                  Register
                </H1>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Input
                  name="username"
                  type="text"
                  id="username"
                  label="Username"
                  placeholder="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Input
                  name="firstname"
                  type="text"
                  id="firstname"
                  label="First Name"
                  placeholder="First Name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstname && Boolean(formik.errors.firstname)
                  }
                  helperText={
                    formik.touched.firstname && formik.errors.firstname
                  }
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Input
                  name="surname"
                  type="text"
                  id="surname"
                  label="Surname"
                  placeholder="Surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.surname && Boolean(formik.errors.surname)
                  }
                  helperText={formik.touched.surname && formik.errors.surname}
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Input
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  label="Create password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Input
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  label="Repeat password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  fullWidth
                />
              </Box>

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="privacy"
                      id="privacy"
                      value={formik.values.privacy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        color: '#fff',
                        '&.Mui-checked': {
                          color: '#fff',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography>
                      I confirm have read and agree about{' '}
                      <Link
                        to="https://divercefi.com/security"
                        target="_blank"
                        style={{
                          color:
                            formik.touched.privacy &&
                            Boolean(formik.errors.privacy)
                              ? '#d32f2f'
                              : theme.palette.white,
                          textDecoration: 'underline',
                          '&:hover': {
                            textDecoration: 'none',
                          },
                        }}
                      >
                        Divercefi Privacy Policy
                      </Link>
                    </Typography>
                  }
                  sx={{
                    color:
                      formik.touched.privacy && Boolean(formik.errors.privacy)
                        ? '#d32f2f'
                        : theme.palette.white,
                  }}
                />
                {formik.touched.privacy && Boolean(formik.errors.privacy) && (
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {formik.errors.privacy}
                  </FormHelperText>
                )}
              </Box>

              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="newsletter"
                      id="newsletter"
                      value={formik.values.newsletter}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        color: '#fff',
                        '&.Mui-checked': {
                          color: '#fff',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography>
                      Subscribe to newsletter, new releases and promotional
                      offers
                    </Typography>
                  }
                  sx={{
                    color:
                      formik.touched.newsletter &&
                      Boolean(formik.errors.newsletter)
                        ? '#d32f2f'
                        : theme.palette.white,
                  }}
                />
                {formik.touched.newsletter &&
                  Boolean(formik.errors.newsletter) && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {formik.errors.newsletter}
                    </FormHelperText>
                  )}
              </Box>

              <Box sx={{ mb: 2 }} display="flex" justifyContent="center">
                <MButton
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Signing up...' : 'Sign up'}
                </MButton>
              </Box>
            </form>
          )}
          <Paragraph color="#fff" className="text-center">
            {success ? '' : 'Already have an account?'}{' '}
            <Link to="/login">Sign in</Link>
          </Paragraph>
        </Grid>
      </Grid>
      <Loading text="Please wait..." open={formik.isSubmitting} />
    </Container>
  );
};

export default Signup;
