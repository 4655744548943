import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Grid, Box, Container } from '@mui/material';

import H1 from '../../../components/Common/Titles/h1';
import Input from '../../../components/Common/Form/Input';
import MButton from '../../../components/Common/MButton';
import Paragraph from '../../../components/Common/Paragraph';
import Loading from '../../../components/Loading';

import { setUserToken, setExpirationTime } from '../../../reducers/userReducer';
import {
  setNotification,
  setNotificationShow,
  setNotificationType,
} from '../../../reducers/globalReducer';
import { signInUser } from '../../../lib/userApi';
import validationSchema from './validationSchema';
import BetaBadge from '../../../components/BetaBadge';

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const user = await signInUser(values);
      const token = user.accessToken;

      if (token) {
        dispatch(setNotificationShow(true));
        dispatch(setNotificationType('success'));
        dispatch(setNotification('You have successfully logged in!'));
        dispatch(setUserToken(token));
        localStorage.setItem('token', token);
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);
        localStorage.setItem('expiration', expiration.toISOString());
        dispatch(setExpirationTime(expiration.toISOString()));
        setTimeout(() => {
          navigate('/');
        }, 500);
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(user.error));
      }
      setSubmitting(false);
    },
  });

  return (
    <Container maxWidth="lg">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
        columnSpacing={2}
      >
        <Grid item sm={6}>
          <Box
            justifyContent="center"
            display="flex"
            alignItems="center"
            sx={{ mb: 4 }}
          >
            <H1 color="#fff" noborder>
              Log In
            </H1>
            <BetaBadge />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <Input
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Input
                name="password"
                type="password"
                id="password"
                label="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
              <Box display="flex" justifyContent="end" sx={{ mt: 1 }}>
                <Paragraph color="#fff">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </Paragraph>
              </Box>
            </Box>
            <Box sx={{ mb: 2 }} display="flex" justifyContent="center">
              <MButton
                variant="outlined"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? 'Signing in...' : 'Sign in'}
              </MButton>
            </Box>
          </form>
          <Paragraph color="#fff" className="text-center">
            Do not have yet an account? <Link to="/auth">Sign up</Link>
          </Paragraph>
        </Grid>
      </Grid>
      <Loading open={formik.isSubmitting} />
    </Container>
  );
};

export default SignIn;
