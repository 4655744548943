import React from 'react';
import { DialogTitle, DialogContent } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DialogContainer, CloseButton } from './styles';

const CDialog = ({ open, title, onClose, children, ...props }) => {
  return (
    <DialogContainer open={open} onClose={onClose} {...props}>
      {title ? (
        <DialogTitle>
          {title}
          <CloseButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </DialogTitle>
      ) : null}
      <DialogContent>{children}</DialogContent>
    </DialogContainer>
  );
};

export default CDialog;
