import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  Box,
  Skeleton,
  useTheme,
  Avatar,
  Stack,
  TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import CButton from '../../../components/Base/CButton';
import H1 from '../../../components/Common/Titles/h1';
import H3 from '../../../components/Common/Titles/h3';
import Input from '../../../components/Common/Form/Input';
import Paragraph from '../../../components/Common/Paragraph';
import Loading from '../../../components/Loading';
import { updateUserInfo, forgotPassword } from '../../../lib/userApi';
import { getAuthToken, removeStorageToken } from '../../../lib/auth';
import { setUser, logout } from '../../../reducers/userReducer';
import {
  setNotification,
  setNotificationShow,
  setNotificationType,
} from '../../../reducers/globalReducer';
import validationSchema from './validationSchema';
import { StyledAutocomplete } from './styles';
import { timezoneList } from '../../../data/timezoneData';

const MyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const token = getAuthToken();
  const user = useSelector((state) => state.user.userInfo);
  const [showEdit, setShowEdit] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [timezone, setTimezone] = useState('');
  const [inputTimezone, setInputTimezone] = useState('');

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
    if (user) {
      setName(user.displayName);
      setEmail(user.email);
      setTimezone(user.timezone || moment.tz.guess());
    }
  }, [user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name,
      email,
      timezone,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        displayName: values.name,
        email: values.email,
        timezone,
        id: user.id,
      };

      const updatedUser = await updateUserInfo(payload, token, user.email);

      if (!updatedUser.error) {
        dispatch(setNotificationShow(true));
        dispatch(setNotificationType('success'));
        setShowEdit(false);

        if (updatedUser.verifyEmail) {
          logout();
          removeStorageToken();
          dispatch(setNotification(updatedUser.verifyEmail));
          setTimeout(() => {
            navigate('/login');
          }, 5000);
        } else {
          dispatch(
            setNotification('You have successfully updated your informations!')
          );
          setName(updatedUser.data.displayName);
          dispatch(setUser(updatedUser.data));
        }
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(updatedUser.error));
        if (updatedUser.errorCode === 'auth/requires-recent-login') {
          navigate('/login');
        }
      }
      setSubmitting(false);
    },
  });

  const forgotPasswordHandler = async () => {
    const forgotPass = await forgotPassword(user);

    dispatch(setNotificationShow(true));

    if (forgotPass.error) {
      dispatch(setNotification(forgotPass.error));
    } else {
      dispatch(setNotificationType('success'));
      dispatch(
        setNotification('An E-Mail with reset Password instructions sent')
      );
      setShowResetPassword(false);
    }
  };

  return (
    <Box sx={{ width: 'fit-content', mx: 'auto' }}>
      <Stack alignItems="center" sx={{ mb: 4, width: '100%' }}>
        <H1 color={theme.palette.white} noborder>
          My Profile
        </H1>
      </Stack>
      <Box sx={{ width: 'fit-content' }}>
        {showEdit ? (
          <form onSubmit={formik.handleSubmit}>
            <Stack rowGap={2}>
              <Input
                name="name"
                type="text"
                id="name"
                label="Name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
              <Input
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <StyledAutocomplete
                value={timezone}
                onChange={(event, newValue) => {
                  setTimezone(newValue);
                }}
                inputValue={inputTimezone}
                onInputChange={(event, newInputValue) => {
                  setInputTimezone(newInputValue);
                }}
                id="controllable-states-demo"
                options={timezoneList.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField {...params} label="Timezone" />
                )}
              />
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-around"
                columnGap={2}
              >
                <CButton
                  size="sm"
                  width={170}
                  bg={theme.palette.white}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => setShowEdit(false)}
                >
                  Back
                </CButton>
                <CButton
                  size="sm"
                  width={170}
                  bg={theme.palette.secondary.main}
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Updating...' : 'Update'}
                </CButton>
              </Stack>
            </Stack>
          </form>
        ) : user ? (
          <Stack alignItems="center" rowGap={3}>
            <Avatar
              sx={{
                width: 60,
                height: 60,
                textTransform: 'uppercase',
              }}
            >
              {user.displayName.split(' ')[0].substring(0, 1)}
            </Avatar>
            <H3
              color={theme.palette.white}
              sx={{ textTransform: 'capitalize' }}
            >
              Name: {user.displayName}
            </H3>
            <Paragraph color={theme.palette.white}>
              Username: {user?.username}
            </Paragraph>
            <Paragraph color={theme.palette.white}>
              Timezone: {user?.timezone || moment.tz.guess()}
            </Paragraph>
            <Paragraph color={theme.palette.white}>
              Email: {user.email}
            </Paragraph>
            <Stack flexDirection="column" rowGap={2}>
              <CButton
                size="sm"
                width={270}
                bg={theme.palette.white}
                onClick={() => setShowEdit(true)}
              >
                Edit
              </CButton>
              <CButton
                size="sm"
                width={270}
                bg={theme.palette.white}
                onClick={() => setShowResetPassword(true)}
              >
                Reset Password
              </CButton>
            </Stack>
            {showResetPassword && (
              <>
                <CButton
                  size="sm"
                  width={270}
                  bg={theme.palette.secondary.main}
                  onClick={forgotPasswordHandler}
                >
                  Send reset Password
                </CButton>
              </>
            )}
          </Stack>
        ) : (
          <Stack alignItems="center" rowGap={3}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={60}
              height={60}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={200}
              height={30}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={200}
              height={30}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={80}
              height={45}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={200}
              height={45}
            />
          </Stack>
        )}
      </Box>
      <Loading open={formik.isSubmitting} />
    </Box>
  );
};

export default MyAccount;
