import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Stack, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CurrencySelect from './CurrencySelect';
import AuthElement from './Auth';
import Logo from '../../assets/img/Common/logo.svg';
import LogoFullImg from '../../assets/img/Common/logo-full.svg';
import { StyledAppBar, StyledContainer, StyledToolbar } from './styles';
import { setAnchorSidebar } from '../../reducers/globalReducer';
import BetaBadge from '../../components/BetaBadge';

const Header = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleToggleSidebar = () => {
    dispatch(setAnchorSidebar(true));
  };

  return (
    <StyledAppBar position="fixed">
      <StyledContainer maxWidth={false}>
        <StyledToolbar disableGutters>
          <Stack direction="row" gap={1}>
            {isMobile && (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleToggleSidebar}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Link
              href="/"
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <img
                src={!isMobile ? LogoFullImg : Logo}
                height={60}
                alt="logo-full"
              />
              <BetaBadge />
            </Link>
          </Stack>
          <Stack direction="row" gap={isMobile ? 1 : 4}>
            {!isMobile && <CurrencySelect />}
            <AuthElement />
          </Stack>
        </StyledToolbar>
      </StyledContainer>
    </StyledAppBar>
  );
};

export default Header;
