import React from 'react';
import { Menu } from '@mui/material';

const CMenu = ({ open, anchorEl, handleClose, children }) => {
  return (
    <Menu
      id="user-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'user-button',
        elevation: 0,
        sx: {
          borderRadius: 6,
          overflow: 'visible',
          minWidth: 250,
          padding: '0',
          '& .MuiAvatar-root': {
            width: 30,
            height: 30,
            ml: -0.5,
            mr: 1,
          },
          '& .MuiMenuItem-root': {
            padding: '15px 30px',
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  );
};

export default CMenu;
