import React from 'react';
import { styled } from '@mui/material/styles';
import getTheme from '../../../theme';

const theme = getTheme();

const Wrapper = styled('h3')`
  font-weight: 700;
  font-size: 18px;
  color: ${(props) => (props.color ? props.color : theme.palette.primary.main)};
  position: relative;
  margin: 0

  &:after {
    content: '';
    position: absolute;
    display: ${(props) => (props.noborder ? 'none' : 'block')};
    bottom: -3px;
    left: 0;
    height: 3px;
    width: 30px;
    background-color: #00f0a9;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
  }
`;

const H3 = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

export default H3;
