import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.mb3 ? '30px' : '0')};
  background: transparent;

  .btn {
    border: 2px solid transparent;
    background-color: initial;
    font-weight: bold;
    font-size: ${(props) => (props.sm ? '12px' : '14px')};
    color: ${(props) => (props.purple ? '#fff' : '#251A5F')};
    border-radius: ${(props) => (props.rounded ? props.rounded : '25px')};
    line-height: ${(props) => (props.sm ? '15px' : '24px')};
    padding: 8px 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;
    width: ${(props) => (props.width ? props.width : 'initial')};
    position: relative;
    white-space: nowrap;
    min-width: fit-content;

    @media (min-width: 1920px) {
      font-size: 14px;
      padding: 10px 15px !important;
    }

    span {
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: ${(props) =>
        (props.purple ? '#251A5F' : props.green ? '#00F0A9' : '#fff')};
      border-radius: ${(props) => (props.rounded ? props.rounded : '20px')};
      transition: all 0.3s;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      transition: all 0.3s;
      border: 2px solid
        ${(props) =>
          (props.purple ? '#251A5F' : props.green ? '#00F0A9' : '#fff')};
      border-radius: ${(props) => (props.rounded ? props.rounded : '20px')};
      transform: scale(1.2, 1.2);
    }

    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(20deg)
        brightness(103%) contrast(101%);
    }

    &:hover {
      cursor: pointer;
      color: ${(props) =>
        (props.hoverColor
          ? props.hoverColor
          : props.purple
          ? '#251A5F'
          : '#fff')};

      &:before {
        opacity: 0;
        transform: scale(0.5, 0.5);
      }

      &:after {
        opacity: 1;
        transform: scale(1, 1);
      }

      img {
        filter: invert(15%) sepia(26%) saturate(3736%) hue-rotate(229deg)
          brightness(87%) contrast(108%);
      }
    }
    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }

    @media (min-width: 992px) {
      padding: 10px 50px;
    }
  }
`;

const MButton = ({
  iconBefore,
  iconAfter,
  children,
  linkTo,
  handleClick,
  ...props
}) => {
  const history = useNavigate();

  const handleHref = () => {
    history.push(linkTo);
  };

  return (
    <Wrapper
      {...props}
      type="false"
      onClick={linkTo ? handleHref : handleClick}
    >
      <Button {...props}>
        <span>{children}</span>
      </Button>
    </Wrapper>
  );
};

export default MButton;
