import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableFooter,
  TableSortLabel,
  Paper,
  Box,
  IconButton,
} from '@mui/material';
import {
  Check as CheckIcon,
  Remove as RemoveIcon,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material';
import AssetsTableItem from './AssetsTableItem';
import TablePagination from '../../../components/Base/Table/TablePagination';
import { loadAssets, resetToggle } from '../../../reducers/assetsReducer';
import {
  setNotificationShow,
  setNotification,
  setExpandTable,
} from '../../../reducers/globalReducer';
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTablePagination,
  StyledCheckbox,
} from '../../../components/Base/Table/styles';
import { assetClasses } from '../../../data/assetClassesData';
import { getAssetsList } from '../../../lib/assetsApi';

const AssetsTable = ({ id, assetClass, disabled, selectedCount }) => {
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.global.searchQuery);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isExpanded, setIsExpanded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [orderBy, setOrderBy] = useState('desc');
  const [order, setOrder] = useState('marketCap');
  const [hideTable, setHideTable] = useState(disabled ? true : false);
  const [showLoading, setShowLoading] = useState(true);
  // const filter = useSelector((state) => state.filter.filters);
  const assetsData = useSelector((state) => state.assets.assets);
  const expandedTable = useSelector((state) => state.global.expandedTable);
  const pageRef = useRef(0);
  const rowsPerPageRef = useRef(10);
  const orderRef = useRef('marketCap');
  const orderByRef = useRef('desc');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCheckAssets = () => {
    dispatch(resetToggle(assetClass));
  };

  const handleExpandPage = () => {
    if (isExpanded) {
      setRowsPerPage(10);
      dispatch(setExpandTable(5));
    } else {
      setRowsPerPage(30);
      dispatch(setExpandTable(assetClass));
    }
    setPage(0);
    setIsExpanded(!isExpanded);
  };

  // const filterData = (filterOption) => {
  //   const filtered = tableData.filter(
  //     (data) => !data.selected &&
  //       data.price >= parseNumber(filterOption.minPrice) &&
  //       data.price <= parseNumber(filterOption.maxPrice) &&
  //       data.marketCap >= parseNumber(filterOption.minMarketCap) &&
  //       data.marketCap <= parseNumber(filterOption.maxMarketCap) &&
  //       data.price24H >= parseNumber(filterOption.minChange) &&
  //       data.price24H <= parseNumber(filterOption.maxChange) &&
  //       (data.tags.some((tag) => filterOption.tags.some(
  //           (filterTag) => tag.tagName.toLowerCase() === filterTag.toLowerCase()
  //         )
  //       ) ||
  //         filterOption.tags.length === 0)
  //   );
  //   const selectedData = tableData.filter((data) => data.selected);
  //   setFilteredData(selectedData.concat(filtered));
  // };

  const fetchData = useCallback(
    async (
      currentPage,
      currentRowsPerPage,
      currentSearch,
      currentOrder,
      currentOrderBy
    ) => {
      setShowLoading(true);
      const params = `start=${
        currentRowsPerPage * currentPage
      }&limit=${currentRowsPerPage}&assetClass=${assetClass}&search=${encodeURIComponent(
        currentSearch
      )}&order=${currentOrder}&orderBy=${currentOrderBy}`;
      const data = await getAssetsList(params);

      if (data.status === 200) {
        setTotalRows(data.data.totalCount);
        dispatch(loadAssets(assetClass, data.data.assets));
        setTimeout(() => {
          setShowLoading(false);
        }, 100);
      } else {
        dispatch(setNotificationShow(true));
        dispatch(setNotification(data.error));
        setShowLoading(false);
      }
    },
    []
  );

  const createSortHandler = (property) => {
    const isAsc = order === property && orderBy === 'asc';
    setOrderBy(isAsc ? 'desc' : 'asc');
    setOrder(property);
  };

  const handleHideTable = () => {
    setHideTable(!hideTable);
  };

  useEffect(() => {
    setTableData(assetsData.filter((asset) => asset.assetClass === assetClass));
  }, [assetsData]);

  useEffect(() => {
    pageRef.current = page;
    rowsPerPageRef.current = rowsPerPage;
    orderRef.current = order;
    orderByRef.current = orderBy;
    fetchData(page, rowsPerPage, searchQuery, order, orderBy);
  }, [page, rowsPerPage, searchQuery, order, orderBy]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, searchQuery, order, orderBy]);

  useEffect(() => {
    if (expandedTable === 5) {
      setPage(0);
      setIsExpanded(false);
      setRowsPerPage(10);
      window.scrollTo(0, 0);
    }
  }, [expandedTable]);

  useEffect(() => {
    const timerId = setInterval(() => {
      fetchData(
        pageRef.current,
        rowsPerPageRef.current,
        searchQuery,
        orderRef.current,
        orderByRef.current
      );
    }, 60000);

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, []);

  const headCells = [
    {
      id: 'displayName',
      name: assetClasses[assetClass],
      isCapitalize: true,
      align: 'left',
      sortable: assetClass === 2 ? false : true,
      span: 2,
    },
    // {
    //   id: 'tag',
    //   name: 'Tags',
    //   width: '10%',
    //   align: 'right',
    //   sortable: false,
    // },
    {
      id: 'price',
      name: 'Price',
      align: 'right',
      sortable: true,
      span: 1,
    },
    {
      id: 'marketCap',
      name:
        assetClass === 4
          ? 'Assets Under Management'
          : assetClass === 5
          ? 'Monetary Supply (M2)'
          : 'Mkt. Cap(M)',
      align: 'right',
      sortable: true,
      span: 2,
    },
    {
      id: 'price24H',
      name: 'Price(24H)',
      align: 'right',
      sortable: true,
      span: 1,
    },
    {
      id: 'price1M',
      name: 'Price(1M)',
      align: 'right',
      sortable: true,
      span: 2,
    },
    {
      id: 'price1Y',
      name: 'Price(1Y)',
      align: 'right',
      sortable: true,
      span: 2,
    },
  ];

  return (
    <Box>
      <StyledTableContainer id={id} component={Paper}>
        <Table aria-label="assets table">
          <TableHead>
            <TableRow>
              {assetClass !== 2 ? (
                <StyledTableCell>
                  {selectedCount > 0 ? (
                    <StyledCheckbox
                      icon={<></>}
                      checkedIcon={<CheckIcon />}
                      indeterminateIcon={<RemoveIcon />}
                      aria-label="checkbox"
                      color="secondary"
                      border="true"
                      checked={tableData.length === selectedCount}
                      indeterminate={tableData.length > selectedCount}
                      onChange={handleCheckAssets}
                    />
                  ) : null}
                </StyledTableCell>
              ) : null}
              {headCells
                .filter(
                  (cell) =>
                    !(
                      (assetClass === 2 && cell.id !== 'displayName')
                      // ||
                      // (assetClass === 4 && cell.id === 'marketCap')
                    )
                )
                .filter((cell) => !(assetClass === 5 && cell.id === 'price24H'))
                .map((cell) => (
                  <StyledTableCell
                    key={cell.id}
                    colSpan={cell.span}
                    sx={{
                      textTransform: cell.isCapitalize
                        ? 'capitalize'
                        : 'normal',
                    }}
                    sortDirection="asc"
                    align={cell.align}
                  >
                    {cell.sortable ? (
                      <TableSortLabel
                        active={cell.id === order}
                        direction={order === cell.id ? orderBy : 'asc'}
                        onClick={() => createSortHandler(cell.id)}
                        sx={{ textAlign: 'center' }}
                      >
                        {cell.name}
                      </TableSortLabel>
                    ) : (
                      <>{cell.name}</>
                    )}
                  </StyledTableCell>
                ))}
              <StyledTableCell align="right">
                <IconButton
                  aria-label="hide table"
                  size="small"
                  onClick={handleHideTable}
                >
                  {hideTable ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {!hideTable ? (
            disabled ? (
              <caption style={{ fontWeight: 500 }}>Coming Soon</caption>
            ) : tableData.length > 0 ? (
              <TableBody>
                {showLoading
                  ? tableData
                      .slice(0, 10)
                      .map((data) => (
                        <AssetsTableItem
                          key={data.id}
                          data={data}
                          assetClass={assetClass}
                          isLoading
                        />
                      ))
                  : tableData.map((data) => (
                    <AssetsTableItem
                      key={data.id}
                      data={data}
                      assetClass={assetClass}
                    />
                    ))}
              </TableBody>
            ) : (
              <caption style={{ textAlign: 'center', fontWeight: 500 }}>
                No data found
              </caption>
            )
          ) : null}
          {showLoading || disabled ? null : (
            <TableFooter>
              <TableRow>
                {tableData.length > 0 ? (
                  <StyledTablePagination
                    labelRowsPerPage=""
                    rowsPerPageOptions={[]}
                    colSpan={12}
                    count={totalRows}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    ActionsComponent={() => (
                      <TablePagination
                        count={totalRows}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onPageExpand={handleExpandPage}
                        isExpanded={isExpanded}
                      />
                    )}
                  />
                ) : null}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </StyledTableContainer>
    </Box>
  );
};

export default AssetsTable;
