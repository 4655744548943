import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toggleWalletModal } from '../../../../reducers/walletsReducer';
import MetamaskIcon from '../../../../assets/img/Wallet/metamask.svg';
import { StyledListItemButton } from '../Cede/styles';

const WalletMetamask = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [count, setCount] = useState(0);
  const myPortfolios = useSelector(
    (state) => state.portfolioExplorer.myPortfolio
  );

  const handleConnect = async () => {
    dispatch(toggleWalletModal(false));
    navigate('/portfolio-import/create');
  };

  useEffect(() => {
    const manualPortfolios = myPortfolios.filter(
      (portfolio) => portfolio.connected && portfolio.manual
    );
    if (manualPortfolios.length > 0) setCount(manualPortfolios.length);
  }, [myPortfolios]);

  return (
    <StyledListItemButton onClick={handleConnect}>
      <ListItemAvatar>
        <img src={MetamaskIcon} width={40} height={40} alt="metamask" />
      </ListItemAvatar>
      <ListItemText
        primary="DeFi - Public address and network"
        primaryTypographyProps={{ color: 'white' }}
      />
      {count > 0 ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            background: theme.palette.success.main,
          }}
        >
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ color: theme.palette.primary.main }}
          >
            {count}
          </Typography>
        </Stack>
      ) : null}
    </StyledListItemButton>
  );
};

export default WalletMetamask;
