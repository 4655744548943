import { Drawer, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  position: 'sticky',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 0,
  },
});

const closedMixin = (theme) => ({
  position: 'sticky',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  top: '102px', // 70 + 32
  height: 'calc(100vh - 134px)', // 70 + 32 + 32
  flexShrink: 0,
  whiteSpace: 'nowrap',
  borderRadius: 14,
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'block',
  '&:hover, &.active': {
    background: theme.palette.secondary.main,
  },
}));

export const StyledListItemText = styled(ListItemText)(
  ({ theme, open, size }) => ({
    color: theme.palette.primary.main,
    opacity: open ? 1 : 0,

    '& .MuiListItemText-primary': {
      fontSize: size === 'small' ? 14 : 18,
      fontWeight: 600,
      whiteSpace: 'break-spaces',
    },
  })
);
