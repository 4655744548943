const initialState = 'USD';

export const SET_CURRENCY = 'SET_CURRENCY';

export const setCurrency = currency => {
  return {
    type: SET_CURRENCY,
    currency,
  };
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCY:
      return action.currency;
    default:
      return state;
  }
};

export default currencyReducer;
