import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: 14,
  marginBottom: 16,
  '&:last-of-type, &:first-of-type': {
    borderRadius: 14,
  },
  '&.Mui-expanded:last-of-type, &.Mui-expanded:first-of-type': {
    marginBottom: 16
  }
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  height: 40,
  minHeight: 40,
  '&.Mui-expanded': {
    minHeight: 40
  },
  '& .MuiAccordionSummary-content': {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  }
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 16,
  background: theme.palette.darkPurple,
  borderRadius: '0 0 14px 14px'
}));
  
