import { IconButton, TableCell, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: 440,
  borderRadius: 10,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 700,
    padding: 10,

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: 500,
    border: 'none',
    padding: 10,

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
}));

export const StyledResetButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
