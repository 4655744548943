import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CAccordion from '../../../../components/Base/CAccordion';
import PortofolioElement from '../PortofolioElement';
import MButton from '../../../../components/Common/MButton';
import { submitPortfolioInterestHubspot } from '../../../../lib/userApi';
import { skeletonPortfolioData } from '../../../../data/skeletonData';

const BasicPortfolio = ({
  id,
  title,
  handleModalShow,
  data,
  expand,
  showLoading,
  handleModalClose,
  handleFetchPortfolio,
  handleShowLoading,
}) => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userInfo);
  const [expanded, setExpanded] = useState(expand);
  const [filteredData, setFilteredData] = useState([]);

  const handleConnectAccount = () => {
    navigate('/portfolio-import');
  };

  const handleCreatePortfolio = () => {
    navigate('/assets');
  };

  const handleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleSocialInterest = async () => {
    handleShowLoading(true);
    const dataForHubspot = {
      social_public_portfolios: 'Yes',
    };
    await submitPortfolioInterestHubspot(user.email, dataForHubspot);
    localStorage.setItem('showPublicPortfolios', false);
    handleShowLoading(false);
  };

  useEffect(() => {
    const slug = hash.replace('#', '');
    if (slug === id) setExpanded(true);
  }, [hash]);

  useEffect(() => {
    const filtered = data.filter((el) =>
      (id === 'connected'
        ? el.connected
        : id === 'tracked'
        ? el.tracked && !el.connected
        : !el.tracked)
    );
    setFilteredData(filtered);
  }, [data, id]);

  useEffect(() => {
    if (showLoading) setExpanded(true);
    else if (filteredData.length > 0) setExpanded(true);
    else setExpanded(false);
  }, [showLoading]);

  return (
    <CAccordion
      id={id}
      title={title}
      expand={expanded}
      handleAccordion={handleAccordion}
    >
      {id === 'social' ? (
        <>
          <Typography color="white" sx={{ mb: 2 }}>
            Social portfolios will let you publish successful investment
            strategies to the commuity. Coming soon, click below to be the first
            one to be notified
          </Typography>
          <MButton green="true" width="160px" onClick={handleSocialInterest}>
            Interested
          </MButton>
        </>
      ) : showLoading ? (
        <Grid container spacing={2}>
          {skeletonPortfolioData.map((el) => (
            <Grid item xl={4} sm={6} key={el.id} sx={{ width: '100%' }}>
              <PortofolioElement data={el} type={id} showLoading />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {filteredData.length > 0 ? (
            <Grid container spacing={2}>
              {filteredData.map((el) => (
                <Grid item xl={4} sm={6} key={el.id} sx={{ width: '100%' }}>
                  <PortofolioElement
                    data={el}
                    type={id}
                    showLoading={showLoading}
                    handleShowLoading={(param) => handleShowLoading(param)}
                    handleModalShow={(portId, value) =>
                      handleModalShow(portId, value)}
                    handleModalClose={() => handleModalClose()}
                    handleFetchPortfolio={handleFetchPortfolio}
                  />
                </Grid>
              ))}
            </Grid>
          ) : id === 'connected' ? (
            <MButton purple="true" onClick={handleConnectAccount}>
              Connect account first
            </MButton>
          ) : (
            <MButton purple="true" onClick={handleCreatePortfolio}>
              {id === 'tracked' ? 'Track' : 'Build'} portfolio first
            </MButton>
          )}
        </>
      )}
    </CAccordion>
  );
};

export default BasicPortfolio;
