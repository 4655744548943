import React from 'react';
import { styled } from '@mui/material/styles';
import getTheme from '../../../theme';

const theme = getTheme();

const Wrapper = styled('p')`
  color: ${(props) => (props.color ? props.color : theme.palette.primary.main)};
  font-size: ${(props) => (props.fontsize ? props.fontsize : '14px')};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : 400)};
  margin-bottom: 0;

  a {
    color: ${(props) => (props.color ? props.color : theme.palette.primary.main)};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @media (min-width: 1920px) {
    font-size: ${(props) => (props.fontsize ? props.fontsize : '16px')};
  }
`;

const Paragraph = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

export default Paragraph;
