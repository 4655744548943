const initialState = {
  myPortfolio: [],
  community: [],
  managed: [],
  nft: [],
  security: [],
  inBasket: [],
  list: [],
};

export const TRACK_PORTFOLIO = 'TRACK_PORTFOLIO';
export const STAKE_PORTFOLIO = 'STAKE_PORTFOLIO';
export const SWAP_PORTFOLIO = 'SWAP_PORTFOLIO';
export const ADD_PORTFOLIO = 'ADD_PORTFOLIO';
export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO';
export const PORTFOLIOS_LOAD = 'PORTFOLIOS_LOAD';

export const loadPortfolios = (data) => ({
  type: PORTFOLIOS_LOAD,
  payload: data,
});

export const trackPortfolio = (id, entryPrice) => {
  return {
    type: TRACK_PORTFOLIO,
    id,
    entryPrice,
  };
};

export const stakePortfolio = (id, entryPrice) => {
  return {
    type: STAKE_PORTFOLIO,
    id,
    entryPrice,
  };
};

export const swapPortfolio = (id, entryPrice) => {
  return {
    type: SWAP_PORTFOLIO,
    id,
    entryPrice,
  };
};

export const addPortfolio = (portfolio) => {
  return {
    type: ADD_PORTFOLIO,
    portfolio,
  };
};

export const updatePortfolio = (portfolio) => {
  return {
    type: UPDATE_PORTFOLIO,
    portfolio,
  };
};

/* eslint-disable no-confusing-arrow */

const portfolioExplorerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PORTFOLIOS_LOAD:
      return { ...state, myPortfolio: action.payload };
    case TRACK_PORTFOLIO:
      return {
        ...state,
        inBasket: [
          ...state.inBasket.filter((value) => value.id !== action.id),
          ...state.list
            .filter((value) => value.id === action.id)
            .map((value) => {
              return {
                ...value,
                tracking: true,
                entryPrice: action.entryPrice,
              };
            }),
        ],
      };
    case STAKE_PORTFOLIO:
      return {
        ...state,
        inBasket: [
          ...state.inBasket.filter((value) => value.id !== action.id),
          ...state.list
            .filter((value) => value.id === action.id)
            .map((value) => {
              return { ...value, staking: true, entryPrice: action.entryPrice };
            }),
        ],
      };
    case SWAP_PORTFOLIO:
      return {
        ...state,
        inBasket: [
          ...state.inBasket.filter((value) => value.id !== action.id),
          ...state.list
            .filter((value) => value.id === action.id)
            .map((value) => {
              return {
                ...value,
                swapping: true,
                entryPrice: action.entryPrice,
              };
            }),
        ],
      };
    case ADD_PORTFOLIO:
      return {
        ...state,
        myPortfolio: [...state.myPortfolio, action.portfolio],
        list: [...state.list, action.portfolio],
      };
    case UPDATE_PORTFOLIO:
      return {
        ...state,
        list: state.list.map((value) =>
          value.id === action.portfolio.id
            ? {
                ...value,
                assets: action.portfolio.assets,
                count: action.portfolio.count,
                updated: action.portfolio.updated,
              }
            : value
        ),
        myPortfolio: state.myPortfolio.map((value) =>
          value.id === action.portfolio.id
            ? {
                ...value,
                assets: action.portfolio.assets,
                count: action.portfolio.count,
                updated: action.portfolio.updated,
              }
            : value
        ),
      };
    default:
      return state;
  }
};

export default portfolioExplorerReducer;
