import React from 'react';
import { Box, Stack } from '@mui/material';
import { StyledWhiteTypo } from '../PortfolioCreation/styles';
import WalletCede from '../../layout/Header/Wallet/Cede';
import WalletMetamask from '../../layout/Header/Wallet/Metamask';

const PortfolioImport = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        maxWidth: 768,
        margin: 'auto',
      }}
    >
      <Stack direction="column" gap={3}>
        <Box>
          <StyledWhiteTypo fontSize={32} fontWeight={700}>
            Automated tracking
          </StyledWhiteTypo>
          <StyledWhiteTypo>
            Connect to your DeFI and CeFI accounts via one of the following
            methods to automatically track your portfolio positions
          </StyledWhiteTypo>
        </Box>
        <Stack rowGap={0.5}>
          <WalletCede />
          <WalletMetamask />
        </Stack>
      </Stack>
    </Box>
  );
};

export default PortfolioImport;
