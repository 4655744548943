import * as yup from 'yup';

const validationsForm = yup.object({
  firstname: yup.string().required('First Name is required'),
  surname: yup.string().required('Surname is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  username: yup
    .string()
    .required('Username is required'),
  password: yup
    .string()
    .min(8, 'Password must contain at least 8 characters')
    .required('Enter your password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Password does not match')
    .required('Confirm your password'),
  privacy: yup
    .bool()
    .oneOf([true], 'You need to accept the terms and conditions'),
});

export default validationsForm;
