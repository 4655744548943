import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { tokenLoader, checkAuthLoader } from '../lib/auth';
import Layout from '../layout';
import Signup from '../pages/User/Signup';
import Login from '../pages/User/Login';
import ForgotPassword from '../pages/User/ForgotPassword';
import MyAccount from '../pages/User/MyAccount';
import Assets from '../pages/Assets';
import Overview from '../pages/Overview';
import PortfolioCreation from '../pages/PortfolioCreation';
import PortfolioImport from '../pages/PortfolioImport';

const router = createBrowserRouter([
  {
    path: '/',
    // errorElement: <ErrorPage />,
    id: 'root',
    loader: tokenLoader,
    children: [
      { index: true, element: <Navigate replace to="/assets" /> },
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            path: '/assets',
            element: <Assets />,
            loader: checkAuthLoader,
          },
          {
            path: '/overview',
            element: <Overview />,
            loader: checkAuthLoader,
          },
          {
            path: '/portfolio-import',
            element: <PortfolioImport />,
            loader: checkAuthLoader
          },
          {
            path: '/portfolio-import/create',
            element: <PortfolioCreation />,
            loader: checkAuthLoader
          },
          {
            path: '/my-account',
            element: <MyAccount />,
            loader: checkAuthLoader,
          },
        ],
      },
      {
        path: 'auth',
        element: <Signup />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
    ],
  },
]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
