import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ListItemAvatar, ListItemText, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Check } from '@mui/icons-material';
import { detectCedeProvider } from '@cedelabs/providers';
import { CedeOnboardingModal } from '@cedelabs/widgets';
import {
  setWalletConnected,
  toggleWalletModal,
  setActiveAccount,
} from '../../../../reducers/walletsReducer';
import {
  setNotificationShow,
  setNotification,
} from '../../../../reducers/globalReducer';
import CedeIcon from '../../../../assets/img/Wallet/cede.svg';
import { StyledListItemButton } from './styles';

const WalletCede = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const connectedRedux = useSelector((state) => state.wallets.connected);
  const connected = localStorage.getItem('WalletConnected')
    ? JSON.parse(localStorage.getItem('WalletConnected'))
    : connectedRedux;

  const [openModal, setOpenModal] = useState(false);

  const handleConnect = async () => {
    const onDisconnect = () => {
      dispatch(setWalletConnected(false));
      dispatch(toggleWalletModal(false));
      localStorage.removeItem('WalletConnected');
    };

    const onUnlock = () => {
      dispatch(setWalletConnected(true));
      dispatch(toggleWalletModal(false));
      localStorage.setItem('WalletConnected', true);
      navigate('/overview#connected');
    };

    const onAccountsChanged = (accounts) => {
      if (!accounts.length) {
        onDisconnect();
        return;
      }
      dispatch(setActiveAccount(accounts[0]));
      localStorage.setItem('ActiveAccount', accounts[0]);
    };

    const provider = await detectCedeProvider();

    if (provider) {
      provider.on('lock', onDisconnect);
      provider.on('unlock', onUnlock);
      provider.on('accountsChanged', onAccountsChanged);
      await provider
        .request({ method: 'connect', params: { onboard: true } })
        .catch((err) => {
          dispatch(setNotificationShow(true));
          dispatch(setNotification(err.message));
        });
    } else {
      setOpenModal(true);
    }
  };

  return (
    <>
      <CedeOnboardingModal open={openModal} exit={() => setOpenModal(false)} />
      <StyledListItemButton onClick={handleConnect}>
        <ListItemAvatar>
          <img src={CedeIcon} width={40} height={40} alt="cede" />
        </ListItemAvatar>
        <ListItemText
          primary="CeFi - Cede.Store"
          primaryTypographyProps={{ color: 'white' }}
        />
        {connected ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: 24,
              height: 24,
              borderRadius: '50%',
              background: theme.palette.success.main,
            }}
          >
            <Check color="primary" fontSize="small" />
          </Stack>
        ) : null}
      </StyledListItemButton>
    </>
  );
};

export default WalletCede;
