// import { coins } from '../data/coinsData';

const initialState = {
  name: '',
  description: '',
  current: {
    id: '',
    value: 0,
    name: '',
  },
  list: [],
  listBackup: [],
};

export const ADD_PORTFOLIO_COINS = 'ADD_PORTFOLIO_COINS';
export const ADD_PORTFOLIO_COINS_BACKUP = 'ADD_PORTFOLIO_COINS_BACKUP';
export const ADD_PORTFOLIO_COIN = 'ADD_PORTFOLIO_COIN';
export const REMOVE_PORTFOLIO_COINS = 'REMOVE_PORTFOLIO_COINS';
export const UPDATE_ALLOCATION = 'UPDATE_ALLOCATION';
export const RESET_ALLOCATION = 'RESET_ALLOCATION';
export const SET_CURRENT_PORTFOLIO = 'SET_CURRENT_PORTFOLIO';
// no need
export const REDISTRIBUTE_PERCENTAGE = 'REDISTRIBUTE_PERCENTAGE';
export const UPDATE_PERCENTAGE = 'UPDATE_PERCENTAGE';
export const UPDATE_PUBLICATION = 'UPDATE_PUBLICATION';
export const UPDATE_LOCKED = 'UPDATE_LOCKED';
export const REALOCATE_UNLOCKED = 'REALOCATE_UNLOCKED';

export const addPortfolioCoins = (data) => {
  return {
    type: ADD_PORTFOLIO_COINS,
    data,
  };
};

export const addPortfolioCoinsBackup = (data) => {
  return {
    type: ADD_PORTFOLIO_COINS_BACKUP,
    data,
  };
};

export const addPortfolioCoin = (data) => {
  return {
    type: ADD_PORTFOLIO_COIN,
    data,
  };
};

export const removePortfolioCoins = (uuid) => {
  return {
    type: REMOVE_PORTFOLIO_COINS,
    uuid,
  };
};

export const updateAllocation = (uuid, percentage, quantity, amount) => {
  return {
    type: UPDATE_ALLOCATION,
    uuid,
    percentage,
    quantity,
    amount,
  };
};

export const resetAllocation = () => {
  return {
    type: RESET_ALLOCATION,
  };
};

export const setCurrentPortfolio = (id, name, capital) => {
  return {
    type: SET_CURRENT_PORTFOLIO,
    id,
    name,
    capital,
  };
};

export const redistributePercentage = () => {
  // no need
  return {
    type: REDISTRIBUTE_PERCENTAGE,
  };
};

export const updatePercentage = (uuid, value) => {
  // no need
  return {
    type: UPDATE_PERCENTAGE,
    uuid,
    value,
  };
};

export const updateLocked = (id, locked) => {
  // no need
  return {
    type: UPDATE_LOCKED,
    id,
    locked,
  };
};

export const realocateUnlocked = (id, percentage) => {
  // no need
  return {
    type: REALOCATE_UNLOCKED,
    id,
    percentage,
  };
};

export const updatePublication = (title, description) => {
  // no need
  return {
    type: UPDATE_PUBLICATION,
    title,
    description,
  };
};

/* eslint-disable no-confusing-arrow */

const portfoliosReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PORTFOLIO_COINS:
      return { ...state, list: [...action.data] };
    case ADD_PORTFOLIO_COINS_BACKUP:
      return { ...state, listBackup: [...action.data] };
    case ADD_PORTFOLIO_COIN:
      return { ...state, list: [...state.list, ...action.data] };
    case REMOVE_PORTFOLIO_COINS:
      return {
        ...state,
        list: [...state.list.filter((value) => value.uuid !== action.uuid)],
      };
    case UPDATE_ALLOCATION:
      return {
        ...state,
        list: state.list.map((value) =>
          value.uuid === action.uuid
            ? {
                ...value,
                percentage: action.percentage,
                new_percentage: action.percentage,
                quantity: action.quantity,
                amount: action.amount,
              }
            : value
        ),
      };
    case RESET_ALLOCATION:
      return {
        ...state,
        list: state.list.map((value) => {
          return {
            ...value,
            percentage: 0,
            quantity: 0,
            amount: 0,
          };
        }),
      };
    case SET_CURRENT_PORTFOLIO:
      return {
        ...state,
        current: {
          id: action.id,
          name: action.name,
          capital: action.capital,
        },
      };
    case REDISTRIBUTE_PERCENTAGE:
      return {
        ...state,
        list: [
          ...state.list.map((value) => {
            return {
              ...value,
              percentage: Math.round(1000 / state.list.length) / 10,
            };
          }),
        ],
      };
    case UPDATE_PERCENTAGE:
      return {
        ...state,
        list: state.list.map((value) =>
          value.uuid === action.uuid
            ? { ...value, percentage: action.value }
            : value
        ),
      };
    case UPDATE_PUBLICATION:
      return { ...state, name: action.title, description: action.description };
    case UPDATE_LOCKED:
      return {
        ...state,
        list: state.list.map((value) =>
          value.id === action.id ? { ...value, locked: action.locked } : value
        ),
      };
    case REALOCATE_UNLOCKED:
      return {
        ...state,
        list: [
          ...state.list.map((value) => {
            return !value.locked && value.id !== action.id
              ? {
                  ...value,
                  percentage:
                    Math.round(10 * (value.percentage + action.percentage)) /
                    10,
                }
              : value;
          }),
        ],
      };
    default:
      return state;
  }
};

export default portfoliosReducer;
