import { FormControl, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormControl = styled(FormControl)(({ theme, type }) => ({
  width: '100%',
  borderRadius: 14,
  '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused': {
    color:
      type === 'primary' ? theme.palette.white : theme.palette.primary.main,
  },
  '& .MuiFormLabel-root:not(.Mui-focused, .MuiInputLabel-shrink)': {
    top: '50%',
    transform: 'translate(14px, -50%)',
  },
}));

export const StyledSelect = styled(Select)(({ theme, type }) => ({
  borderRadius: 14,
  color: type === 'primary' ? theme.palette.white : theme.palette.primary.main,
  fontSize: 18,
  fontWeight: 500,

  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
    height: 43,
    padding: '0 14px',
  },
  '& .MuiOutlinedInput-notchedOutline, &:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
    {
      borderColor:
        type === 'primary' ? theme.palette.white : theme.palette.primary.main,
    },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline, &.Mui-disabled:hover .MuiOutlinedInput-notchedOutline':
    {
      borderColor:
        type === 'primary'
          ? 'rgba(255, 255, 255, 0.26)'
          : 'rgba(37, 26, 96, 0.26)',
    },
  '&.Mui-error': {
    color: theme.palette.error.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
  '& .MuiSvgIcon-root': {
    color:
      type === 'primary' ? theme.palette.white : theme.palette.primary.main,
    transform: 'rotate(90deg)',

    '&.MuiSelect-iconOpen': {
      transform: 'rotate(270deg)',
    },
  },
  '& .MuiSvgIcon-root.Mui-disabled': {
    color:
      type === 'primary' ? theme.palette.white : theme.palette.primary.main,
    opacity: 0.25,
  },
}));
