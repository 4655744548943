export const skeletonAggregateTableData = [
  {
    displayName: '',
    price: 0,
    price24H: 0,
    percentage: 0,
    new_percentage: 0,
    quantity: 0,
    amount: 0,
    new_amount: 0,
    cumulation: 0,
    ticker: 'btc',
  },
  {
    displayName: '',
    price: 0,
    price24H: 0,
    percentage: 0,
    new_percentage: 0,
    quantity: 0,
    amount: 0,
    new_amount: 0,
    cumulation: 0,
    ticker: 'eth',
  },
  {
    displayName: '',
    price: 0,
    price24H: 0,
    percentage: 0,
    new_percentage: 0,
    quantity: 0,
    amount: 0,
    new_amount: 0,
    cumulation: 0,
    ticker: 'sol',
  },
];

const skeletonPortfolioItem = {
  id: 'skeleton_live1',
  archived: false,
  capital: 1700,
  name: 'live_tracked1',
  description: 'test',
  isPublic: false,
  tracked: true,
  currency: 'USD',
  category: [0],
  userId: 'CajZ9WNM5QMBGLTACNVjWKBD2QN2',
  live: true,
  created_at: '2024-03-11T09:27:49.510Z',
  risk_score: 0,
  variance: 0,
  new_capital: 1715.9380566907423,
  change24H: 3.653105333682011,
  change1M: 47.13930157805545,
  change1Y: 96.59105852926233,
  points24H: [
    {
      price: 1655.4622759894771,
      change: 0,
      time: '2024-03-10 21:00:00',
    },
    {
      price: 1636.4623419765937,
      change: -1.1477116868475328,
      time: '2024-03-10 22:00:00',
    },
    {
      price: 1654.0488575764446,
      change: -0.0853790771032673,
      time: '2024-03-10 23:00:00',
    },
    {
      price: 1635.288150564512,
      change: -1.2186399966684156,
      time: '2024-03-11 00:00:00',
    },
    {
      price: 1634.4879153597178,
      change: -1.266979074906605,
      time: '2024-03-11 01:00:00',
    },
    {
      price: 1638.7882521252754,
      change: -1.0072125536195342,
      time: '2024-03-11 02:00:00',
    },
    {
      price: 1648.7930340495818,
      change: -0.40286281582037653,
      time: '2024-03-11 03:00:00',
    },
    {
      price: 1645.7567017987112,
      change: -0.5862757691029133,
      time: '2024-03-11 04:00:00',
    },
    {
      price: 1643.6572559406359,
      change: -0.713095080453303,
      time: '2024-03-11 05:00:00',
    },
    {
      price: 1658.3576851288533,
      change: 0.17490033940190944,
      time: '2024-03-11 06:00:00',
    },
    {
      price: 1699.407611631763,
      change: 2.6545658140122605,
      time: '2024-03-11 07:00:00',
    },
    {
      price: 1704.219545910184,
      change: 2.9452359397053893,
      time: '2024-03-11 08:00:00',
    },
    {
      price: 1713.6018554995933,
      change: 3.5119845588366476,
      time: '2024-03-11 09:00:00',
    },
    {
      price: 1709.0895670235104,
      change: 3.2394148638621214,
      time: '2024-03-11 10:00:00',
    },
    {
      price: 1710.8431567274245,
      change: 3.3453423579130446,
      time: '2024-03-11 11:00:00',
    },
    {
      price: 1719.449731191064,
      change: 3.8652318527368057,
      time: '2024-03-11 12:00:00',
    },
    {
      price: 1708.104607028492,
      change: 3.1799172836813985,
      time: '2024-03-11 13:00:00',
    },
    {
      price: 1715.6925853543432,
      change: 3.6382773705227556,
      time: '2024-03-11 14:00:00',
    },
    {
      price: 1710.4389315657945,
      change: 3.32092469720928,
      time: '2024-03-11 15:00:00',
    },
    {
      price: 1719.4209204410606,
      change: 3.86349150803543,
      time: '2024-03-11 16:00:00',
    },
    {
      price: 1718.8354640834546,
      change: 3.828126379750876,
      time: '2024-03-11 17:00:00',
    },
    {
      price: 1723.868440507534,
      change: 4.132148796756492,
      time: '2024-03-11 18:00:00',
    },
    {
      price: 1713.6039200656019,
      change: 3.5121092711927386,
      time: '2024-03-11 19:00:00',
    },
    {
      price: 1715.9380566907423,
      change: 3.653105333682011,
      time: '2024-03-11 20:00:00',
    },
  ],
  points1M: [
    {
      price: 1166.1996271269818,
      change: 0,
      time: '2024-02-11 00:00:00',
    },
    {
      price: 1215.775723893328,
      change: 4.251081514104108,
      time: '2024-02-12 00:00:00',
    },
    {
      price: 1209.206512682202,
      change: 3.687780767103379,
      time: '2024-02-13 00:00:00',
    },
    {
      price: 1257.5780633373818,
      change: 7.835574123404363,
      time: '2024-02-14 00:00:00',
    },
    {
      price: 1270.0755251007624,
      change: 8.907214130198835,
      time: '2024-02-15 00:00:00',
    },
    {
      price: 1266.6566614688738,
      change: 8.61405131721533,
      time: '2024-02-16 00:00:00',
    },
    {
      price: 1259.3843508623772,
      change: 7.990460772566258,
      time: '2024-02-17 00:00:00',
    },
    {
      price: 1286.6951337608098,
      change: 10.332322514171736,
      time: '2024-02-18 00:00:00',
    },
    {
      price: 1300.037045627908,
      change: 11.476372945740378,
      time: '2024-02-19 00:00:00',
    },
    {
      price: 1320.9580809719512,
      change: 13.270322699916152,
      time: '2024-02-20 00:00:00',
    },
    {
      price: 1306.3751248934132,
      change: 12.019854449084672,
      time: '2024-02-21 00:00:00',
    },
    {
      price: 1303.444506898065,
      change: 11.768558022025433,
      time: '2024-02-22 00:00:00',
    },
    {
      price: 1287.2109136090985,
      change: 10.376549920551499,
      time: '2024-02-23 00:00:00',
    },
    {
      price: 1310.5955034505027,
      change: 12.381746054854329,
      time: '2024-02-24 00:00:00',
    },
    {
      price: 1341.7072989115493,
      change: 15.049539350046226,
      time: '2024-02-25 00:00:00',
    },
    {
      price: 1376.6833676673377,
      change: 18.04868871883436,
      time: '2024-02-26 00:00:00',
    },
    {
      price: 1411.3732530551797,
      change: 21.023298260881894,
      time: '2024-02-27 00:00:00',
    },
    {
      price: 1484.327493270944,
      change: 27.279023140119968,
      time: '2024-02-28 00:00:00',
    },
    {
      price: 1464.5479236077394,
      change: 25.58295248436672,
      time: '2024-02-29 00:00:00',
    },
    {
      price: 1496.6747628233331,
      change: 28.337784373203846,
      time: '2024-03-01 00:00:00',
    },
    {
      price: 1490.8507385163302,
      change: 27.838382369333303,
      time: '2024-03-02 00:00:00',
    },
    {
      price: 1515.4315060805372,
      change: 29.946149083747674,
      time: '2024-03-03 00:00:00',
    },
    {
      price: 1586.3384896039631,
      change: 36.02632454205325,
      time: '2024-03-04 00:00:00',
    },
    {
      price: 1536.0162126181406,
      change: 31.711259109405564,
      time: '2024-03-05 00:00:00',
    },
    {
      price: 1618.8560153311435,
      change: 38.8146572572068,
      time: '2024-03-06 00:00:00',
    },
    {
      price: 1636.502805679505,
      change: 40.32784504580485,
      time: '2024-03-07 00:00:00',
    },
    {
      price: 1649.1616393019417,
      change: 41.41332246562042,
      time: '2024-03-08 00:00:00',
    },
    {
      price: 1655.972045436899,
      change: 41.9973053426974,
      time: '2024-03-09 00:00:00',
    },
    {
      price: 1654.0488575764446,
      change: 41.83239465196152,
      time: '2024-03-10 00:00:00',
    },
    {
      price: 1715.937986360528,
      change: 47.13930157805545,
      time: '2024-03-11 00:00:00',
    },
  ],
  points1Y: [
    {
      price: 872.8578851244528,
      change: 0,
      time: '2023-04-01 00:00:00',
    },
    {
      price: 859.595759939063,
      change: -1.51939111869269,
      time: '2023-05-01 00:00:00',
    },
    {
      price: 897.6297897418048,
      change: 2.8380226654903846,
      time: '2023-06-01 00:00:00',
    },
    {
      price: 869.484844909127,
      change: -0.3864363572593312,
      time: '2023-07-01 00:00:00',
    },
    {
      price: 793.7791362360508,
      change: -9.059750760815643,
      time: '2023-08-01 00:00:00',
    },
    {
      price: 807.1656544749771,
      change: -7.526108404246032,
      time: '2023-09-01 00:00:00',
    },
    {
      price: 897.0521504734068,
      change: 2.771844736844458,
      time: '2023-10-01 00:00:00',
    },
    {
      price: 978.1052174677834,
      change: 12.057785595684273,
      time: '2023-11-01 00:00:00',
    },
    {
      price: 1067.710656203519,
      change: 22.323539077759943,
      time: '2023-12-01 00:00:00',
    },
    {
      price: 1070.1727741265152,
      change: 22.605614541011917,
      time: '2024-01-01 00:00:00',
    },
    {
      price: 1464.5479236077394,
      change: 67.78767180397564,
      time: '2024-02-01 00:00:00',
    },
    {
      price: 1715.9605558222943,
      change: 96.59105852926233,
      time: '2024-03-01 00:00:00',
    },
  ],
  assets: [
    {
      amount: 500,
      ticker: 'BTC',
      quantity: 0.007033021442275774,
      new_amount: 507.6434877034653,
      price: 71093.2,
      displayName: 'Bitcoin',
      percentage: 29.411764705882355,
      new_percentage: 29.581565153287215,
      logo: 'https://raw.githubusercontent.com/tradehubpro/icons/main/crypto_icons/BTC.png',
      id: 'qxf3chcNttp56AxSCvsm',
      assetClass: 0,
      tags: [
        {
          id: '0',
          tagName: 'Store of Value',
        },
        {
          id: '1',
          tagName: 'Peer-to-Peer Money',
        },
      ],
    },
    {
      amount: 1000,
      ticker: 'ETH',
      quantity: 0.24991315517857546,
      new_amount: 1008.4370681188287,
      price: 4001.39,
      displayName: 'Ethereum',
      percentage: 58.82352941176471,
      new_percentage: 58.76397029832997,
      logo: 'https://raw.githubusercontent.com/tradehubpro/icons/main/crypto_icons/ETH.png',
      id: '4I0PBiKntQJ38F1LmZNp',
      assetClass: 0,
      tags: [
        {
          id: '0',
          tagName: 'Smart Contract Platform',
        },
        {
          id: '1',
          tagName: 'Decentralized Applications',
        },
      ],
    },
    {
      amount: 200,
      ticker: 'USDT',
      quantity: 200,
      new_amount: 200,
      price: 1,
      displayName: 'Tether',
      percentage: 11.76470588235294,
      new_percentage: 11.654464548382814,
      logo: 'https://raw.githubusercontent.com/tradehubpro/icons/main/crypto_icons/USDT.png',
      id: 'yB3Pay8g1uVivcxaUMul',
      assetClass: 0,
      tags: [
        {
          id: '0',
          tagName: 'Stablecoin',
        },
        {
          id: '1',
          tagName: 'Pegged to USD',
        },
      ],
    },
  ],
};

export const skeletonPortfolioData = [
  skeletonPortfolioItem,
  { ...skeletonPortfolioItem, id: 'skeleton_live2' },
  { ...skeletonPortfolioItem, id: 'skeleton_live3' },
];
