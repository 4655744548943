import React from 'react';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import H3 from '../../../../components/Common/Titles/h3';

const Success = ({ message }) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex-column"
      sx={{ mb: 4 }}
      textAlign="center"
    >
      <H3 color="#388e3c" noborder>
        <CheckCircleOutlineIcon />
        {' '}
        {message}
      </H3>
    </Box>
  );
};

export default Success;
