import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Checkbox, FormControlLabel } from '@mui/material';
import CDropdown from '../../../components/Base/CDropdown';
import { setActiveChain } from '../../../reducers/walletsReducer';
import { chainData } from '../../../data/network';

const ChainSelect = ({ chain, disabled, error }) => {
  const [checked, setChecked] = useState(false);
  const filteredChainData = useMemo(
    () =>
      (checked
        ? chainData.filter((data) => data)
        : chainData.filter((data) => !data.testnet)),
    [checked]
  );

  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setActiveChain(event.target.value));
  };

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {}, [checked]);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      columnGap={2}
    >
      <CDropdown
        label="Blockchain"
        data={filteredChainData}
        value={chain}
        variant="primary"
        error={error}
        disabled={disabled}
        handleChange={handleChange}
      />
      <FormControlLabel
        value="Testnet"
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckbox}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
          />
        }
        label="Testnet"
        labelPlacement="right"
        sx={{ whiteSpace: 'nowrap', color: 'white' }}
      />
    </Stack>
  );
};

export default ChainSelect;
