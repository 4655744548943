import React from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '../../../assets/img/Common/search-icon2.svg';
import { SearchBarWrapper, SearchFormControl } from './styles';

const SearchBar = ({ query, placeholder = 'Search', onChange, width }) => {
  return (
    <SearchBarWrapper width={width}>
      <SearchFormControl variant="standard" width={width}>
        <img src={SearchIcon} alt="search" />
        <TextField
          id="filled-hidden-label-normal"
          value={query}
          variant="outlined"
          placeholder={placeholder}
          onChange={onChange}
          sx={{ width: '100%' }}
        />
      </SearchFormControl>
    </SearchBarWrapper>
  );
};

export default SearchBar;
