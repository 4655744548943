import { coins } from '../data/coinsData';

const initialState = [...coins];

export const TOGGLE_COIN = 'TOGGLE_COIN';
export const RESET_TOGGLE = 'RESET_TOGGLE';

export const toggleCoinD = id => {
  return {
    type: TOGGLE_COIN,
    id,
  };
};

export const resetToggle = () => {
  return {
    type: RESET_TOGGLE,
  };
};

/* eslint-disable no-confusing-arrow */

const coinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COIN:
      return state.map(coin => (coin.id === action.id) ? { ...coin, selected: !coin.selected } : coin);
    case RESET_TOGGLE:
      return state.map(coin => { return { ...coin, selected: false }; });
    default:
      return state;
  }
};

export default coinsReducer;
