export const coins = [
  {
    id: 'btc',
    title: 'Bitcoin',
    category: 'Scalable, Flexible',
    description:
      'Bitcoin is the largest decentralized cryptocurrency. Bitcoin is currently secured by the Proof-of-Work consensus. There can be no more than 21 million units in circulation.',
    logo: '/img/coins/btc.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'eth',
    title: 'Ethereum',
    category: 'dApps',
    description:
      'Ethereum is a blockchain-based platform that allows developers to build and deploy decentralized applications (DApps).',
    logo: '/img/coins/eth.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'xtz',
    title: 'Tezos',
    category: 'dApps',
    description:
      'Tezos is a blockchain network that’s based on smart contracts, in a way that’s not too dissimilar to Ethereum.',
    logo: '/img/coins/tezos.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'stkr',
    title: 'StakerDAO',
    category: '',
    description:
      'Staker is a governance protocol run by a community of blockchain and finance enthusiasts who make governance decisions for building cross-chain, decentralized financial assets in a compliant manner.',
    logo: '/img/coins/stkr.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'insta',
    title: 'Instaraise',
    category: '',
    description:
      'Tezos\'s first ever completely decentralised fundraising and Incubation platform.',
    logo: '/img/coins/insta.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'you',
    title: 'YOUChain',
    category: '',
    description:
      'YOUChain is focused on carrying out large-scale commercial applications through its YPoS consensus mechanism and investment model. YOUChain aims to create a public blockchain with high-performance and scalability to achieve its vision of “One Mobile Device One Node, Everyone Can Participate".',
    logo: '/img/coins/you.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'quipu',
    title: 'QuipuSwap',
    category: '',
    description:
      'QuipuSwap is an open-source #AMM protocol that provides an interface for the seamless exchange of #Tezos tokens (#DEX).',
    logo: '/img/coins/quipu.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'avax',
    title: 'Avalanche',
    category: 'dApps',
    description:
      'Avalanche is a high-performance, scalable, customizable and secure open source platform dedicated to the deployment of smart contracts.',
    logo: '/img/coins/avax.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'uni',
    title: 'Uniswap',
    category: 'dApps',
    description:
      'Uniswap is a popular decentralized trading protocol, known for its role in facilitating automated trading of decentralized finance (DeFi) tokens.',
    logo: '/img/coins/uni.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'link',
    title: 'Chainlink',
    category: 'dApps',
    description:
      'Chainlink’s main goal is to connect the on-chain world (on the blockchain) and the off-chain world (information that is not on the blockchain).',
    logo: '/img/coins/link.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'aave',
    title: 'AAVE',
    category: 'dApps',
    description:
      'Aave is a decentralized finance protocol that allows people to lend and borrow crypto. Lenders earn interest by depositing digital assets into specially created liquidity pools. Borrowers can then use their crypto as collateral to take out a flash loan using this liquidity.',
    logo: '/img/coins/aave.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'mana',
    title: 'Decentraland',
    category: '',
    description:
      'MANA token is the in-game currency of Decentraland and also serves as the platform\'s cryptocurrency. MANA is an ERC-20 token that lets users purchase or trade LAND and pay for goods and services in the virtual world.',
    logo: '/img/coins/mana.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'sand',
    title: 'The Sandbox',
    category: '',
    description:
      'SAND is a utility token that can be used to play games, purchase equipment or customize avatars. Users can earn the token while playing the game. SAND is also a governance token, meaning that users who hold the token can vote on decisions affecting the future of the game.',
    logo: '/img/coins/sand.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'theta',
    title: 'Theta Network',
    category: '',
    description:
      'Theta tokens are the governance token of the Theta network. They provide users with a host of functionalities. This token is what you use to stake as a Validator or Guardian node, to produce blocks, and to participate in the protocol\'s governance. There are 1,000,000,000 Theta tokens in circulation at this time.',
    logo: '/img/coins/theta.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'stx',
    title: 'Stacks',
    category: '',
    description:
      'Stacks token (STX) is the cryptocurrency of the decentralized computing platform Blockstack. Blockstack is a project that utilizes blockchain technology to expand the functionalities of bitcoin by serving as a second layer protocol.',
    logo: '/img/coins/stx.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'enj',
    title: 'Enjin',
    category: '',
    description: 'Enjin Coin (ENJ) is an Ethereum token that aims to “make it easy for individuals, businesses, and brands to use non-fungible tokens (NFTs).” ENJ is used to directly back the value of NFTs minted within the Enjin ecosystem.',
    logo: '/img/coins/enj.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'bnb',
    title: 'Binance',
    category: 'dApps',
    description:
      'The Binance Coin is a utility token specifically created for use within the Binance ecosystem. BNB was a token of the ERC-20 standard of the blockchain Ethereum (ETH).',
    logo: '/img/coins/busd.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'ada',
    title: 'Cardano',
    category: 'dApps',
    description:
      'Cardano is a proof-of-stake blockchain platform that says its goal is to allow “changemakers, innovators and visionaries” to bring about positive global change.',
    logo: '/img/coins/ada.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'sol',
    title: 'Solana',
    category: 'dApps',
    description:
      'Solana stands out for its atypical transaction validation architecture and system. The developers have designed a consensus model called Proof of History (PoH).',
    logo: '/img/coins/sol.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'dot',
    title: 'Polkadot',
    category: 'dApps',
    description:
      'Polkadot is a next-generation protocol that unites a network of several interconnected blockchains.',
    logo: '/img/coins/dot.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'matic',
    title: 'Polygon',
    category: 'dApps',
    description:
      'Polygon (previously Matic Network) is the first well-structured, easy-to-use platform for Ethereum scaling and infrastructure development.',
    logo: '/img/coins/matic.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'trx',
    title: 'TRON',
    category: '',
    description:
      'On the TRON blockchain, TRX tokens serve as the fundamental unit of accounts, and the value of all other coins is derived from that of TRON. // The TRX token itself is used to gain access to certain features of the operating software on the Tron Protocol. Therefore the token\'s main purpose is for use on the Tron network.',
    logo: '/img/coins/trx.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'leo',
    title: 'UNUS SED LEO',
    category: '',
    description:
      'LEO Token (LEO) is the utility token intended for the use on the Bitfinex exchange and other trading platforms managed by its parent company iFinex.',
    logo: '/img/coins/leo.jpeg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'ltc',
    title: 'Litecoin',
    category: 'Quick Transactions',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    logo: '/img/coins/ltc.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'ftt',
    title: 'FTX Token',
    category: '',
    description:
      'FTT is a token that serves as the backbone of the FTX ecosystem. The token is intricately designed to increase network effects and demands for the token, while also helping to decrease its circulating supply.',
    logo: '/img/coins/ftt.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'atom',
    title: 'Cosmos',
    category: '',
    description:
      'The ATOM token plays a key role in maintaining interoperability between all zones in the wider Cosmos network, and is the staking coin that is mainly used for governance.',
    logo: '/img/coins/atom.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'xmr',
    title: 'Monero',
    category: 'Privacy',
    description: 'Monero (XMR) is an open-source, privacy-oriented cryptocurrency that was launched in 2014. XMR is an inflationary asset and issues tokens based on two schedules. The first is called the “main emission curve” and the second is called the “Tail emission curve.”',
    logo: '/img/coins/xmr.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },

  // to_add
  {
    id: 'algo',
    title: 'Algorand',
    category: 'dApps',
    description:
      'Algorand is a self-sustaining, decentralized, blockchain-based network that supports a wide range of applications.',
    logo: '/img/coins/algo.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  // {
  //   id: 'zec',
  //   title: 'ZCash',
  //   category: 'Privacy',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  //   logo: '/img/coins/tezos.svg',
  //   trend: '+32%',
  //   rating: '4/5',
  //   tooltip: 'Put a sweet tooltip here',
  //   tags: [
  //     { title: 'Web3' },
  //     { title: 'Scaling' },
  //     { title: 'Platform' },
  //     { title: 'State Channel' },
  //   ],
  //   selected: false,
  //   locked: true,
  // },
  // {
  //   id: 'dsh',
  //   title: 'Dash',
  //   category: 'Digital Cash',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  //   logo: '/img/coins/tezos.svg',
  //   trend: '+32%',
  //   rating: '4/5',
  //   tooltip: 'Put a sweet tooltip here',
  //   tags: [
  //     { title: 'Web3' },
  //     { title: 'Scaling' },
  //     { title: 'Platform' },
  //     { title: 'State Channel' },
  //   ],
  //   selected: false,
  //   locked: true,
  // },
  {
    id: 'dai',
    title: 'Dai',
    category: 'Quick Transactions',
    description:
      'Dai is a stablecoin version 2.0, which means it is multi-collateralized (pegged by many Ethereum cryptocurrencies) thanks to its own Decentralized Autonomous Organization.',
    logo: '/img/coins/dai.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'usdt',
    title: 'Tether',
    category: 'Quick Transactions',
    description:
      'The basic principle is simple: Tether USD is collateralized 100% by dollar reserves in Tether Ltd.’s bank account, i.e. each USDT corresponds (in theory) to an existing US dollar.',
    logo: '/img/coins/usdt.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'easy',
    title: 'Cryptoeasy',
    category: 'Quick Transactions',
    description:
      'EASY tokens can be categorized as a DeFi utility token that enables you to bet or follow popular/ personalized portfolios without investing in the underlying digital assets.',
    logo: '/img/coins/easy.svg',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
  },
  {
    id: 'bat',
    title: 'BAT',
    category: '',
    description: '',
    logo: '/img/coins/bat.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'xlm',
    title: 'Stellar',
    category: '',
    description: '',
    logo: '/img/coins/xlm.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'fil',
    title: 'Filecoin',
    category: '',
    description: '',
    logo: '/img/coins/fil.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'eos',
    title: 'EOS',
    category: '',
    description: '',
    logo: '/img/coins/eos.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'yfi',
    title: 'YFI',
    category: '',
    description: '',
    logo: '/img/coins/yfi.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'bch',
    title: 'Bitcoin cash',
    category: '',
    description: '',
    logo: '/img/coins/bch.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'zil',
    title: 'Zilliqa',
    category: '',
    description: '',
    logo: '/img/coins/zil.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'zrx',
    title: '0x',
    category: '',
    description: '',
    logo: '/img/coins/zrx.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'zec',
    title: 'Zcash',
    category: '',
    description: '',
    logo: '/img/coins/zec.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'xrp',
    title: 'XRP',
    category: '',
    description: '',
    logo: '/img/coins/xrp.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'usd',
    title: 'USD',
    category: '',
    description: '',
    logo: '/img/coins/usd.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
  {
    id: 'usdc',
    title: 'USDC',
    category: '',
    description: '',
    logo: '/img/coins/usdc.png',
    trend: '+32%',
    rating: '4/5',
    tooltip: 'Put a sweet tooltip here',
    tags: [
      { title: 'Web3' },
      { title: 'Scaling' },
      { title: 'Platform' },
      { title: 'State Channel' },
    ],
    selected: false,
    locked: true,
    unlist: true,
  },
];
